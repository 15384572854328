import { GridColumns, MapsCircleView, unitize } from "@abs-safety/lock-book-web-ui";
import { Form, Formik, FormikProps } from "formik";
import { isEmpty } from "lodash";
import { observer } from "mobx-react";
import React, { FunctionComponent, useEffect, useState } from "react";
import styled from "styled-components";
import * as Yup from "yup";
import FieldLoading from "../../../components/FieldLoading";
import FormAutoSubmit from "../../../components/FormAutoSubmit";
import FormGtmIntegration from "../../../components/FormGtmIntegration";
import FormInput from "../../../components/FormInput";
import FormSelectCountry from "../../../components/FormSelectCountry";
import { getConstraints } from "../../../constraints/constraints";
import { IBuildingUpdate } from "../../../entities/Building";
import { buildingService } from "../../../services/BuildingService";
import { session } from "../../../session/Session";
import { getController } from "../../../stores/controller/ControllerFactory";
import { gtmAutoSubmit } from "../../../utils/gtmEventCollection";
import { BuildingController } from "../BuildingController";
type FormValues = Pick<
    IBuildingUpdate,
    "name" | "buildingNumber" | "address1" | "address2" | "postcode" | "city" | "country" | "comment"
>;

const sessionLanguage = session.locale.toUpperCase();

/**
 * Component
 */
export interface EditDetailsModalProps {
    selectedBuildingId: number;
}

const EditDetails: FunctionComponent<EditDetailsModalProps> = (props: EditDetailsModalProps) => {
    const { controller } = getController(BuildingController);
    const [initialValues, setInitialValues] = useState<FormValues | undefined>();
    const [validationSchema, setValidationSchema] = useState(Yup.object().shape<Partial<FormValues>>({}));

    useEffect(() => {
        if (initialValues !== undefined) {
            // if initialValues were already initialized, then don't re-asign form values, otherwise form would re-render,
            // which would lead the FormAutoSubmit to send another request, and it could potentially override the user's input.
            return;
        }

        const building = buildingService.get(props.selectedBuildingId);
        if (building === undefined) {
            return;
        }

        setInitialValues({
            name: building.name,
            buildingNumber: building.buildingNumber,
            address1: building.address1,
            address2: building.address2,
            postcode: building.postcode,
            city: building.city,
            country: building.country,
            comment: building.comment,
        });
    }, [props.selectedBuildingId]);

    useEffect(() => {
        setValidationSchema(
            Yup.object().shape<Partial<FormValues>>({
                name: getConstraints("Building", "name"),
                buildingNumber: getConstraints("Building", "buildingNumber"),
                address1: getConstraints("Building", "address1"),
                address2: getConstraints("Building", "address2"),
                postcode: getConstraints("Building", "postcode"),
                city: getConstraints("Building", "city"),
                country: getConstraints("Building", "country"),
            })
        );
    }, [session.constraints]);

    //#region render
    return controller.waitingFor.loadBuilding === true || isEmpty(session.constraints) ? (
        <FieldLoading text="Objekt wird geladen..." />
    ) : initialValues === undefined ? (
        <S.NotFoundText>Kein Objekt zu der URL gefunden</S.NotFoundText>
    ) : (
        <Formik
            initialValues={initialValues}
            enableReinitialize={true}
            validationSchema={validationSchema}
            onSubmit={(values) => {
                buildingService.update(props.selectedBuildingId, values);
            }}
        >
            {(formikBag) => <ObservedForm {...formikBag} />}
        </Formik>
    );
    //#endregion render
};

/**
 * Sub Component
 */
const FormComponent: FunctionComponent<FormikProps<FormValues>> = (formikBag: FormikProps<FormValues>) => (
    <Form>
        <FormInput type="text" isHeading={true} name="name" label="Objektname" />
        <FormInput type="text" name="buildingNumber" label="Objektnummer" size="lg" />
        <S.AddressWrapper>
            <S.Address>
                <h4 style={{ marginBottom: unitize(20) }}>Anschrift</h4>
                <FormInput type="text" name="address1" label="Straße und Hausnummer" size="sm" />
            </S.Address>
            <MapsCircleView
                address={[
                    formikBag.values.postcode,
                    formikBag.values.city,
                    formikBag.values.address1,
                    formikBag.values.address2,
                ]
                    // filter out undefined values
                    .filter((d) => d !== undefined)
                    .join(" ")}
            />
        </S.AddressWrapper>

        <FormInput type="text" name="address2" label="Adresszusatz" size="sm" isOptional={true} />
        <GridColumns columnSizes={{ xs: [1], sm: [1, 1] }} style={{ columnGap: unitize(30) }}>
            <FormInput type="text" name="postcode" label="PLZ" size="sm" />
            <FormInput type="text" name="city" label="Ort" size="sm" />
        </GridColumns>

        <FormSelectCountry name="country" label="Land" locale={sessionLanguage} size="lg" />
        <FormInput type="textarea" name="comment" label="Bemerkung" />
        <FormAutoSubmit onSuccess={gtmAutoSubmit("building")} />
        <FormGtmIntegration />
    </Form>
);

const ObservedForm = observer(FormComponent);

export default observer(EditDetails);

//#region styles
const S = {
    NotFoundText: styled.h4`
        margin-top: ${unitize(20)};
    `,
    AddressWrapper: styled.div`
        display: flex;
        column-gap: ${unitize(30)};
    `,
    Address: styled.div`
        flex: 1 0 auto;
    `,
};
//#endregion
