import { designTheme, IconMinus, IconPlus, unitize } from "@abs-safety/lock-book-web-ui";
import React, { FunctionComponent } from "react";
import styled, { css } from "styled-components";
import { session } from "../session/Session";

interface CounterProps {
    count: number;
    onCountChange: (count: number) => void;
    maxCount?: number;
    onlyDisplayValue?: boolean;
}

/**
 * User can select a number (Integer) by
 * - changing the input value itself (keyboard input)
 * - pressing increase or decrease buttons
 *
 * This component doesn't have min and max value, but the parent component using this
 * can implement it in props.onCountChange(), as it's a controlled component
 */
const Counter: FunctionComponent<CounterProps> = (props: CounterProps) => {
    const onlyDisplayValue = props.onlyDisplayValue ?? false;
    const maxCount = props.maxCount ?? 0;

    const decrease = () => {
        props.onCountChange(props.count - 1);
    };
    const increase = () => {
        if (maxCount > 0 && props.count + 1 > maxCount) {
            session.addNotification({
                title: "Zu hoher Wert",
                description: "Es sind keine Werte über " + maxCount + " zulässig.",
                key: new Date().getTime().toString(),
                type: "error",
            });
        } else {
            props.onCountChange(props.count + 1);
        }
    };
    const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = parseInt(event.target.value, 10);
        if (maxCount > 0) {
            if (value <= maxCount) {
                props.onCountChange(value);
            } else if (value > maxCount) {
                props.onCountChange(maxCount);
                session.addNotification({
                    title: "Zu hoher Wert",
                    description: "Es sind keine Werte über " + maxCount + " zulässig.",
                    key: new Date().getTime().toString(),
                    type: "error",
                });
            }
        } else {
            props.onCountChange(value);
        }
        if (Number.isNaN(value)) {
            props.onCountChange(1);
        }
    };

    return (
        <S.Wrapper>
            {!onlyDisplayValue ? (
                <>
                    <S.Text>Anzahl</S.Text>
                    <S.Counter>
                        <S.IconWrapper onClick={decrease}>
                            <IconMinus color={designTheme.color.primary} />
                        </S.IconWrapper>
                        <S.CountWrapper onClick={(event) => event.stopPropagation()}>
                            <S.Count onChange={onInputChange} value={props.count.toString()} />
                        </S.CountWrapper>
                        <S.IconWrapper onClick={increase}>
                            <IconPlus color={designTheme.color.primary} />
                        </S.IconWrapper>
                    </S.Counter>
                </>
            ) : (
                <S.CountWrapper
                    onClick={(event) => event.stopPropagation()}
                    onlyDisplayValue={onlyDisplayValue}
                    className="counter"
                >
                    <S.Count
                        onChange={onInputChange}
                        value={props.count.toString()}
                        className="counter"
                        readOnly={onlyDisplayValue}
                        onlyDisplayValue={onlyDisplayValue}
                    />
                </S.CountWrapper>
            )}
        </S.Wrapper>
    );
};

export default Counter;

//#region styles
const S = {
    Wrapper: styled.div`
        height: fit-content;
        display: flex;
        align-items: center;
        user-select: none;
        flex-direction: column;
        margin-left: ${unitize(10)};
        margin-bottom: ${unitize(10)};
    `,
    Image: styled.img`
        width: ${unitize(92)};
        height: ${unitize(92)};
    `,
    Text: styled.p`
        line-height: 1.5;
        font-size: ${unitize(16)};
        margin-bottom: ${unitize(6)};
    `,
    CountWrapper: styled.div<{ onlyDisplayValue?: boolean }>`
        height: ${unitize(48)};
        width: ${unitize(90)};
        padding: 0 ${unitize(20)};
        border-radius: ${unitize(500)};
        background-color: ${designTheme.color.white};
        display: flex;
        align-items: center;
        box-sizing: border-box;
        margin: 0 ${unitize(10)};
        ${(props) => {
            const onlyDisplayValue = props.onlyDisplayValue ?? false;

            if (onlyDisplayValue) {
                return css`
                    background-color: ${designTheme.color.lightgrey};
                    margin-right: ${unitize(26)};
                `;
            }

            return "";
        }}
        > input {
            background-color: ${(props) => {
                const onlyDisplayValue = props.onlyDisplayValue ?? false;
                return onlyDisplayValue ? designTheme.color.lightgrey : designTheme.color.white;
            }};
        }
    `,
    Counter: styled.div`
        display: flex;
    `,
    Count: styled.input<{ onlyDisplayValue?: boolean }>`
        font-weight: 600;
        font-size: ${unitize(19)};
        margin: 0 auto;
        text-align: center;
        width: 100%;
        border: 0;

        &:focus {
            outline: none;
        }

        cursor: ${(props) => {
            const onlyDisplayValue = props.onlyDisplayValue ?? false;

            return onlyDisplayValue ? "pointer" : "text";
        }};
    `,
    IconWrapper: styled.div`
        cursor: pointer;
        display: flex;
        align-items: center;
    `,
};
//#endregion styles
