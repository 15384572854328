import { GridColumns, unitize } from "@abs-safety/lock-book-web-ui";
import { Form, Formik } from "formik";
import { isEmpty } from "lodash";
import { observer } from "mobx-react";
import React, { FunctionComponent, useEffect, useState } from "react";
import styled from "styled-components";
import * as Yup from "yup";
import FieldLoading from "../../../components/FieldLoading";
import FormAutoSubmit from "../../../components/FormAutoSubmit";
import FormGtmIntegration from "../../../components/FormGtmIntegration";
import FormInput from "../../../components/FormInput";
import { getConstraints } from "../../../constraints/constraints";
import { IBuildingUpdate } from "../../../entities/Building";
import { buildingService } from "../../../services/BuildingService";
import { session } from "../../../session/Session";
import { getController } from "../../../stores/controller/ControllerFactory";
import { gtmAutoSubmit } from "../../../utils/gtmEventCollection";
import { BuildingController } from "../BuildingController";

type FormValues = Pick<
    IBuildingUpdate,
    | "managerSalutation"
    | "managerFirstName"
    | "managerLastName"
    | "managerRole"
    | "managerPhone1"
    | "managerPhone2"
    | "managerFax"
    | "managerEmail"
>;

export interface EditContactPersonModalProps {
    selectedBuildingId: number;
}

const EditContactPerson: FunctionComponent<EditContactPersonModalProps> = (props: EditContactPersonModalProps) => {
    const { controller } = getController(BuildingController);

    const [initialValues, setInitialValues] = useState<FormValues | undefined>();
    const [validationSchema, setValidationSchema] = useState(Yup.object().shape<Partial<FormValues>>({}));

    useEffect(() => {
        if (initialValues !== undefined) {
            // if initialValues were already initialized, then don't re-asign form values, otherwise form would re-render,
            // which would lead the FormAutoSubmit to send another request, and it could potentially override the user's input.
            return;
        }
        const building = buildingService.get(props.selectedBuildingId);
        if (building === undefined) {
            return;
        }

        setInitialValues({
            managerSalutation: building.managerSalutation,
            managerFirstName: building.managerFirstName,
            managerLastName: building.managerLastName,
            managerRole: building.managerRole,
            managerPhone1: building.managerPhone1,
            managerPhone2: building.managerPhone2,
            managerFax: building.managerFax,
            managerEmail: building.managerEmail,
        });
    }, [props.selectedBuildingId]);

    useEffect(() => {
        setValidationSchema(
            Yup.object().shape<Partial<FormValues>>({
                managerSalutation: getConstraints("Building", "managerSalutation"),
                managerFirstName: getConstraints("Building", "managerFirstName"),
                managerLastName: getConstraints("Building", "managerLastName"),
                managerRole: getConstraints("Building", "managerRole"),
                managerPhone1: getConstraints("Building", "managerPhone1"),
                managerPhone2: getConstraints("Building", "managerPhone2"),
                managerFax: getConstraints("Building", "managerFax"),
                managerEmail: getConstraints("Building", "managerEmail"),
            })
        );
    }, [session.constraints]);

    //#region render
    return controller.waitingFor.loadBuilding === true || isEmpty(session.constraints) ? (
        <FieldLoading text="Objekt wird geladen..." />
    ) : initialValues === undefined ? (
        <S.NotFoundText>Kein Objekt zu der URL gefunden</S.NotFoundText>
    ) : (
        <Formik
            initialValues={initialValues}
            enableReinitialize={true}
            validationSchema={validationSchema}
            onSubmit={(values) => {
                buildingService.update(props.selectedBuildingId, values);
            }}
        >
            {() => <ObservedForm />}
        </Formik>
    );
    //#endregion render
};

/**
 * Sub Component
 */
const FormComponent: FunctionComponent = () => (
    <Form>
        <h4 style={{ marginBottom: unitize(20) }}>Ansprechpartner</h4>

        <GridColumns columnSizes={{ xs: [1], sm: [1, 1] }}>
            <FormInput type="text" name="managerSalutation" label="Anrede" />
        </GridColumns>
        <GridColumns columnSizes={{ xs: [1], sm: [1, 1] }}>
            <FormInput type="text" name="managerFirstName" label="Vorname" />
            <FormInput type="text" name="managerLastName" label="Nachname" />
        </GridColumns>
        <FormInput type="text" name="managerRole" label="Funktion" />
        <GridColumns columnSizes={{ xs: [1], sm: [1, 1] }}>
            <FormInput type="text" name="managerPhone1" label="Telefon" />
            <FormInput type="text" name="managerFax" label="Fax" />
        </GridColumns>
        <GridColumns columnSizes={{ xs: [1], sm: [1, 1] }}>
            <FormInput type="text" name="managerPhone2" label="Handy" />
        </GridColumns>
        <FormInput type="email" name="managerEmail" label="E-Mail-Adresse" />
        <FormAutoSubmit onSuccess={gtmAutoSubmit("contact_person")} />
        <FormGtmIntegration />
    </Form>
);
const ObservedForm = observer(FormComponent);

export default observer(EditContactPerson);

//#region styles
const S = {
    NotFoundText: styled.h4`
        margin-top: ${unitize(20)};
    `,
};
//#endregion styles
