import { ConstraintViolationError, ForbiddenError, NotFoundError, UnauthorizedError } from "../entities/ErrorResponse";

const apiEndpoint = process.env.REACT_APP_API_URL;
const hubEndpoint = process.env.REACT_APP_HUB_URL;
// const apiEndpoint = `https://api.docu.dev.lock-book.com`;
// const apiEndpoint = `http://0.0.0.0:8000`;

const headers = {
    accept: "application/json",
    "Content-Type": "application/json",
};
const headersUpload = {
    accept: "application/json",
    // 'Content-Type': 'multipart/form-data' will be automatically generated by the browser
    // including the Boundary parameter
};
const headersString = {
    accept: "*",
};
const headersPagination = {
    accept: "application/ld+json",
    "Content-Type": "application/json",
};

/**
 * fetch util for normal requests
 *
 * @template T the response type of this request
 * @param method 'GET'|'POST'|....
 * @param route without apiEndpoint starting from '/'
 * @param body optional; a simple object; don't define if method: 'GET'
 */
export const fetchNormal = <T>(method: string, route: string, body?: Record<string, unknown>): Promise<T> => {
    const init: RequestInit = {
        method,
        headers,
        credentials: "include",
        mode: "cors",
    };

    if (body) {
        init.body = JSON.stringify(body);
    }
    return fetch(`${apiEndpoint}${route}`, init).then((response) => {
        if (response.status === 401) {
            window.location.assign(`${hubEndpoint}/logout?redirect=${window.location.href}`);
            throw new UnauthorizedError("Not logged in.");
        }
        if (response.status === 204) {
            // no content, so response.json() would fail
            return response.text();
        }

        return response.json().then((body) => {
            if (!response.ok) {
                switch (response.status) {
                    case 400:
                        // body contains information about violated fields
                        throw new ConstraintViolationError(response.statusText, body);
                    case 401:
                        // body contains code and message on 401 error
                        window.location.assign(`${hubEndpoint}/logout?redirect=${window.location.href}`);
                        throw new UnauthorizedError(body.message);
                    case 403:
                        // body contains code and message on 403 error
                        throw new ForbiddenError(body.message);
                    case 404:
                        // body contains code and message on 404 error
                        throw new NotFoundError(response.statusText, body);
                    default:
                        throw new Error(response.statusText);
                }
            }
            return body;
        });
    });
};

/**
 * fetch util for Upload requests (with formData)
 *
 * @template T the response type of this request
 * @param method 'POST'|'PUT'|....
 * @param route without apiEndpoint starting from '/'
 * @param formData a formData with all the data to be send to API
 */
export const fetchUpload = <T>(method: string, route: string, formData: FormData): Promise<T> => {
    return fetch(`${apiEndpoint}${route}`, {
        method,
        headers: headersUpload,
        body: formData,
        credentials: "include",
        mode: "cors",
    }).then((response) => {
        if (!response.ok) {
            throw new Error(response.statusText);
        }
        return response.json() as Promise<T>;
    });
};

/**
 * @param route fetch util for requests which response should be read as text (not json)
 */
export const fetchString = (route: string): Promise<string> => {
    return fetch(`${apiEndpoint}${route}`, {
        method: "GET",
        headers: headersString,
        credentials: "include",
        mode: "cors",
    }).then((response) => {
        if (!response.ok) {
            throw new Error(response.statusText);
        }
        return response.text();
    });
};

interface HydraResponse<T> {
    "@context": string;
    "@id": string;
    "@type": string;
    "hydra:member": T;
    "hydra:totalItems": number;
    "hydra:search": Record<string, unknown>;
}

/**
 * fetch util for pagination request
 *
 * @template T the response type of this request
 * @param method 'GET'|'POST'|....
 * @param route without apiEndpoint starting from '/'
 * @param body optional; a simple object; don't define if method: 'GET'
 */
export const fetchPagination = <T>(
    method: string,
    route: string,
    body?: Record<string, unknown>
): Promise<HydraResponse<T>> => {
    const init: RequestInit = {
        method,
        headers: headersPagination,
        credentials: "include",
        mode: "cors",
    };
    if (body) {
        init.body = JSON.stringify(body);
    }
    return fetch(`${apiEndpoint}${route}`, init).then((response) => {
        if (!response.ok) {
            throw new Error(response.statusText);
        }
        if (response.status === 204) {
            // no content, so response.json() would fail
            return response.text();
        }
        return response.json();
    });
};

/**
 * USE ONLY FOR TESTING (while developing)
 *
 * use this in any `*.api.ts` file to test how the UI looks like, when server responds with an empty array.
 */
export function fakeFetchEmptyResult(delayInMs = 200): Promise<[]> {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve([]);
        }, delayInMs);
    });
}

/**
 * USE ONLY FOR TESTING (while developing)
 *
 * same as `fakeFetchEmptyResult()`, but with pagination result
 * Usefull for example, to see how BuildingList look like for users, who don't have buildings yet.
 */
export function fakeFetchPaginationEmptyResult(delayInMs = 200): Promise<HydraResponse<[]>> {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve({
                "@context": "",
                "@id": "",
                "@type": "",
                "hydra:member": [],
                "hydra:search": {},
                "hydra:totalItems": 0,
            });
        }, delayInMs);
    });
}
