import { Button, IconLock, Input, Popup, unitize } from "@abs-safety/lock-book-web-ui";
import { Form, Formik } from "formik";
import { observer } from "mobx-react";
import React, { FunctionComponent, useEffect, useState } from "react";
import styled from "styled-components";
import * as Yup from "yup";
import FieldLoading from "../../../components/FieldLoading";
import FormAutoSubmit from "../../../components/FormAutoSubmit";
import FormGtmIntegration from "../../../components/FormGtmIntegration";
import FormInput from "../../../components/FormInput";
import { getConstraints } from "../../../constraints/constraints";
import { IDocumentationUpdate } from "../../../entities/Documentation";
import { buildingService } from "../../../services/BuildingService";
import { documentationService } from "../../../services/DocumentationService";
import { session } from "../../../session/Session";
import { getController } from "../../../stores/controller/ControllerFactory";
import { formatDate } from "../../../utils/formatDate";
import { gtmAutoSubmit } from "../../../utils/gtmEventCollection";
import { isNullish } from "../../../utils/isNullish";
import { BuildingController } from "../BuildingController";

interface DetailsDocumentationProps {
    activeId: number;
}

type FormValues = IDocumentationUpdate;

const DetailsDocumentation: FunctionComponent<DetailsDocumentationProps> = (props: DetailsDocumentationProps) => {
    const { controller } = getController(BuildingController);
    const [initialValues, setInitialValues] = useState<FormValues | undefined>();
    const [validationSchema, setValidationSchema] = useState(Yup.object().shape<Partial<FormValues>>({}));
    const { activeId } = props;

    const documentation = documentationService.get(activeId);
    const isLocked = documentation !== undefined && documentation.isCompleted;
    const building =
        documentation !== undefined && !isNullish(documentation.buildingId)
            ? buildingService.get(documentation.buildingId)
            : undefined;
    const buildingName = building?.name;
    const documentationDate =
        documentation !== undefined
            ? documentation.date !== undefined && documentation.date !== null
                ? formatDate(new Date(documentation.date))
                : ""
            : undefined;
    const documentationType =
        documentation !== undefined ? (documentation.type === "assembly" ? "Montage" : "Wartung") : undefined;

    useEffect(() => {
        if (initialValues !== undefined) {
            return;
        }

        if (activeId === 0) {
            return;
        }

        if (documentation !== undefined) {
            setInitialValues({
                ...documentation,
                name: documentation.name,
            });
        }
    }, [documentation, building]);

    useEffect(() => {
        setValidationSchema(
            Yup.object().shape<Partial<FormValues>>({
                name: getConstraints("Documentation", "name"),
            })
        );
    }, [session.constraints]);

    const unlockDocumentation = () => {
        history.back();
        documentationService.reopenDocumentation(activeId);
        session.addNotification({
            title: "Dokumentation entsperrt",
            description: `Die Dokumentation ${documentation?.name} wurde erfolgreich entsperrt und kann nun wieder bearbeitet werden.`,
            key: new Date().getTime().toString(),
            type: "success",
        });
    };

    //#region render
    return controller.waitingFor.loadDocumentationSharingHashes === true ? (
        <FieldLoading text="Dokumentation wird geladen..." />
    ) : initialValues === undefined ? (
        <S.NotFoundText>Keine Dokumentation zu der URL gefunden</S.NotFoundText>
    ) : (
        <Formik
            initialValues={initialValues}
            enableReinitialize={true}
            validationSchema={validationSchema}
            onSubmit={(values) => {
                controller.updateDocumentation(values);
            }}
        >
            {() => (
                <ObservedForm
                    documentationDate={documentationDate}
                    documentationType={documentationType}
                    objectName={buildingName}
                    isLocked={isLocked}
                    activeId={activeId}
                    unlockDocumentation={() => unlockDocumentation()}
                />
            )}
        </Formik>
    );
};

/**
 * Form Component
 */

interface FormComponentProps {
    documentationDate: string | undefined;
    documentationType: string | undefined;
    objectName: string | null | undefined;
    isLocked: boolean;
    activeId: number;
    unlockDocumentation: () => void;
}

const FormComponent: FunctionComponent<FormComponentProps> = (props: FormComponentProps) => (
    <>
        {props.isLocked && (
            <S.UnlockButtonRow>
                <Popup
                    position={{
                        top: unitize(24),
                        left: unitize(24),
                    }}
                    toggleNode={
                        <Button noPadding variant="outline" color="black">
                            <button>
                                <IconLock />
                            </button>
                        </Button>
                    }
                    popupContent={
                        <>
                            <h4>Dokumentation gesperrt</h4>
                            <p>Diese Dokumentation ist abgeschlossen und damit für Änderungen gesperrt.</p>
                        </>
                    }
                    popupButton={
                        session.hasCompanyAdminRights
                            ? {
                                  children: "Entsperren",
                                  onClick: () => props.unlockDocumentation(),
                                  color: "white",
                              }
                            : undefined
                    }
                />
            </S.UnlockButtonRow>
        )}
        <Form>
            <S.EditDetails>
                <FormInput type="text" name="name" label="Name der Dokumentation" size="sm" isHeading />
                <Input type="text" label="Angelegt am" value={props.documentationDate} readOnly={true} />
                <Input
                    type="text"
                    label="Objektname"
                    value={props.objectName !== null ? props.objectName : undefined}
                    readOnly={true}
                />
                <Input type="text" label="Typ" value={props.documentationType} readOnly={true} />
            </S.EditDetails>
            <FormAutoSubmit onSuccess={gtmAutoSubmit("documentation")} />
            <FormGtmIntegration />
        </Form>
    </>
);

const ObservedForm = observer(FormComponent);
export default observer(DetailsDocumentation);

//#region styles
const S = {
    EditDetails: styled.div`
        margin-top: ${unitize(10)};
        input {
            margin-bottom: ${unitize(20)};
        }
    `,
    NotFoundText: styled.h4`
        margin-top: ${unitize(20)};
    `,
    UnlockButtonRow: styled.div`
        margin-top: ${unitize(16)};
        margin-bottom: ${unitize(15)};
    `,
};
//#endregion styles
