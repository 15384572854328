export interface IConstraintViolationResponse {
    title: string;
    detail: string;
    violations: Array<Violation>;
}

export interface INotFoundResponse {
    title: string;
    detail: string;
}

type Violation = {
    propertyPath: string;
    message: string;
};

export class HttpError extends Error {
    statusCode: number;
    constructor(message: string, code: number) {
        super(message);
        this.statusCode = code;
    }
}

export class UnauthorizedError extends HttpError {
    constructor(message: string) {
        super(message, 401);
        this.name = "Unauthorized";
    }
}

export class ForbiddenError extends HttpError {
    constructor(message: string) {
        super(message, 403);
        this.name = "Forbidden";
    }
}

export class FileTooLargeError extends Error {
    constructor(props: { name: string; message: string }) {
        super(props.message);
        this.name = props.name;
    }
}

export class NotFoundError extends HttpError {
    constructor(message: string, public payload: INotFoundResponse) {
        super(message, 404);
        this.name = "NotFound";
    }
}

export class ConstraintViolationError extends HttpError {
    constructor(message: string, public payload: IConstraintViolationResponse) {
        super(message, 400);
        this.name = "ConstraintViolation";
    }
}

export class CallStackViolation extends Error {
    constructor(message: string) {
        super(message);
        this.name = "CallStackViolation";
    }
}
