import { FieldEmpty, IconProduct, unitize } from "@abs-safety/lock-book-web-ui";
import { observer } from "mobx-react";
import React, { FunctionComponent, useCallback } from "react";
import styled from "styled-components";
import { IArticleOverviewItem } from "../../../api/ArticleApi";
import { DocumentationController } from "../../../pages/Documentation/DocumentationController";
import { getController } from "../../../stores/controller/ControllerFactory";
import { AddArticleController } from "../AddArticleController";
import { getAmountAvailableInstalledArticles } from "../utils/getAmountAvailableInstalledArticles";
import CardArticle from "./CardArticle";
import { Step1AvailableArticlesProps } from "./Step1AvailableArticles";

interface Step1AvailableArticlesListProps extends Step1AvailableArticlesProps {
    listType: "enabled" | "disabled";
    visibleArticles: IArticleOverviewItem[];
    expandedArticleId?: number;
    isSearchOrFilterActive?: boolean;
    whenExpandedChanges?: (articleId: number) => void;
}

const Step1AvailableArticlesList: FunctionComponent<Step1AvailableArticlesListProps> = (
    props: Step1AvailableArticlesListProps
) => {
    const { controller: modalController } = getController(AddArticleController);
    const { controller } = getController(DocumentationController);
    const getSubheadlineText = useCallback(() => {
        if (modalController.documentationType === undefined) return "";
        type NameLookup = {
            [outerkey in typeof props.listType]: {
                [innerkey in Exclude<typeof modalController.documentationType, undefined>]: string;
            };
        };
        const nameLookup: NameLookup = {
            enabled: {
                assembly: "Nicht montiert",
                maintenance: "Nicht in Dokumentation",
            },
            disabled: {
                assembly: "Montiert",
                maintenance: "Bereits in Dokumentation",
            },
        };
        return nameLookup[props.listType][modalController.documentationType];
    }, [props.listType, modalController.documentationType]);

    const onCardSelectedChange = (selected: boolean, articleId: number) => {
        if (selected) {
            modalController.selectAvailableArticle(articleId, controller.documentation?.type);
        } else {
            modalController.deselectAvailableArticle(articleId);
        }
    };

    return (
        <>
            <S.Headline>
                Produktverwaltung - <S.Subheadline>{getSubheadlineText()}</S.Subheadline>
            </S.Headline>

            {props.visibleArticles.length === 0 ? (
                props.isSearchOrFilterActive === true ? (
                    <FieldEmpty icon={<IconProduct height={48} width={48} />} text="Kein Produkt gefunden." />
                ) : (
                    <FieldEmpty icon={<IconProduct height={48} width={48} />} text="Kein Produkt" />
                )
            ) : (
                props.visibleArticles.map((article) => (
                    <CardArticle
                        key={article.id}
                        article={article}
                        amount={
                            props.listType === "enabled"
                                ? getAmountAvailableInstalledArticles(article)
                                : article.installedArticles.length - getAmountAvailableInstalledArticles(article)
                        }
                        amountTotal={article.installedArticles.length}
                        disabled={props.listType === "disabled"}
                        showArticleCounter={true}
                        expanded={props.expandedArticleId === article.id}
                        whenExpandedChanges={() =>
                            props.whenExpandedChanges !== undefined && props.whenExpandedChanges(article.id)
                        }
                        firstItem={article.id === props.visibleArticles[0].id}
                        reloadUrl={() => modalController.loadBuildingAreaArticleOverview(true)}
                        waitingForReloadUrl={modalController.waitingFor.reloadUrl}
                        displayCheckbox={props.listType === "enabled" ? true : false}
                        articleCount={
                            props.listType === "enabled"
                                ? getAmountAvailableInstalledArticles(article)
                                : article.installedArticles.length - getAmountAvailableInstalledArticles(article)
                        }
                        maxCount={article.installedArticles.length}
                        installedArticles={article.installedArticles}
                        displayInstalledArticles={props.expandedArticleId === article.id}
                        onlyDisplayValueofCounter={true}
                        whenSelectChanges={(selected) => onCardSelectedChange(selected, article.id)}
                    />
                ))
            )}
            <div style={{ marginTop: unitize(60) }} />
        </>
    );
};

export default observer(Step1AvailableArticlesList);

//#region styles
const S = {
    Headline: styled.h4`
        margin-bottom: ${unitize(20)};
    `,
    Subheadline: styled.span`
        font-weight: normal;
    `,
};
//#endregion styles
