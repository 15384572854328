import { Checkbox, designTheme, IconProduct, media, unitize } from "@abs-safety/lock-book-web-ui";
import { observer } from "mobx-react";
import React, { FunctionComponent, useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";
import { IDeliveryNoteRead } from "../../entities/DeliveryNote";
import { WaitingForValue } from "../../stores/SuperStore";
import Counter from "../Counter";
import PresignedImage from "../PresignedImage";

interface DeliveryNoteCardArticleProps {
    id: number;
    article: IDeliveryNoteRead;
    articleCount: number;
    selected: boolean;
    description?: string;
    onCheckboxChange: (id: number, amount: number, articleId: number) => void;
    amount: number;
    articleNumber: string;
    whenArticleCountChanges: (articleCount: number, id: number) => void;
    onSelectedEditableChange: (id: number) => void;
    disabled?: boolean;
    imageUrl?: string | null;
    reloadUrl: () => void;
    waitingForReloadUrl?: WaitingForValue;
    firstItem?: boolean;
    currentEditableArticle?: boolean;
}

const DeliveryNoteCardArticle: FunctionComponent<DeliveryNoteCardArticleProps> = (
    props: DeliveryNoteCardArticleProps
) => {
    const counterRef = useRef<HTMLDivElement>(null);
    const [waitingForReloadUrl, setWaitingForReloadUrl] = useState(props.waitingForReloadUrl);

    useEffect(() => {
        setWaitingForReloadUrl(props.waitingForReloadUrl);
    }, [props.waitingForReloadUrl]);

    //#region event listeners

    const onCardClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (e.target === counterRef.current || counterRef.current?.contains(e.target as HTMLElement)) {
            // don't call onSelectedEditableChange, when user clicked on Counter
            return;
        }
        props.onSelectedEditableChange(props.id);
    };

    const onCountChange = (count: number, id: number) => {
        if (props.whenArticleCountChanges) {
            if (count >= 1) {
                props.whenArticleCountChanges(count, id);
            } else {
                props.whenArticleCountChanges(1, id);
            }
        }
    };

    //#endregion event listeners

    return (
        <S.Component
            selected={props.selected}
            onClick={onCardClick}
            disabled={props.disabled}
            currentEditableArticle={props.currentEditableArticle}
        >
            <S.Content>
                <span onClick={(e) => e.stopPropagation()}>
                    <Checkbox
                        onChange={() =>
                            props.onCheckboxChange(props.id, props.article.amount, props.article.article.id)
                        }
                        checked={props.selected}
                    />
                </span>
                <S.ImageWrapper>
                    {props.article?.article.imageUrl ? (
                        <PresignedImage
                            url={props.article.article.imageUrl}
                            reloadUrl={() => props.reloadUrl()}
                            waitingForValue={waitingForReloadUrl}
                            style={{ width: 92, height: 92 }}
                            disableRefresh={!props.firstItem}
                        ></PresignedImage>
                    ) : (
                        <S.NoImage>
                            <IconProduct color={designTheme.color.darkgrey} />
                        </S.NoImage>
                    )}
                </S.ImageWrapper>
                <S.TextColumn>
                    <h4>{props.article.article.name}</h4>
                    <p>{props.article.article.number}</p>
                    <p>Position {props.article.positionText}</p>
                    <p>
                        Max. Anzahl Lieferschein:
                        <S.AmountInstalled>{props.article.amount}</S.AmountInstalled>
                    </p>
                </S.TextColumn>
            </S.Content>
            {props.selected && props.articleCount && (
                <div ref={counterRef}>
                    <Counter
                        count={props.articleCount}
                        onCountChange={(count) => onCountChange(count, props.id)}
                        onlyDisplayValue={!props.currentEditableArticle || false}
                        maxCount={props.article.amount}
                    />
                </div>
            )}
        </S.Component>
    );
};

export default observer(DeliveryNoteCardArticle);

//#region styles
const S = {
    Component: styled.div<{ selected?: boolean; disabled?: boolean; currentEditableArticle?: boolean }>`
        background-color: ${(props) =>
            props.selected && props.currentEditableArticle ? designTheme.color.lightgrey : designTheme.color.white};

        @media (hover: hover) {
            &:hover {
                background-color: ${designTheme.color.lightgrey};
                .counter {
                    background-color: ${designTheme.color.white};
                }
            }
            flex: 0 0 auto;
            overflow-y: auto;
            margin-top: 0;
        }

        ${(props) =>
            props.disabled &&
            css`
                cursor: default;
                opacity: 0.25;

                @media (hover: hover) {
                    &:hover {
                        background-color: unset;
                    }
                }
            `}

        ${media("sm")} {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: ${unitize(10)};
            cursor: pointer;
        }
    `,
    ButtonClose: styled.div`
        position: absolute;
        right: ${30 / 16}rem;
        top: ${30 / 16}rem;
    `,
    HeadlineTitle: styled.div`
        display: flex;
        text-align: center;
        justify-content: center;
        width: 90%;
    `,
    HeadlineText: styled.h2`
        display: inline-block;
        text-align: center;
        margin-left: 8%;
    `,
    TextWrapper: styled.div`
        height: 100%;
        margin-top: ${unitize(20)}; ;
    `,
    Description: styled.p`
        text-align: center;
        font-size: ${unitize(18)}; ;
    `,
    Content: styled.div`
        display: flex;
        padding: ${unitize(16)};
        min-width: ${unitize(260)};
        ${media("sm")} {
            height: fit-content;
            display: flex;
            align-items: center;
        }
    `,
    ImageWrapper: styled.div`
        width: ${unitize(64)};
        height: ${unitize(64)};
        overflow: hidden;
        /* make sure nothing overflows. Especially when image is not found, the alt text ignores width */
        ${media("sm")} {
            width: ${unitize(92)};
            height: ${unitize(92)};
        }
    `,
    Image: styled.img`
        width: 100%;
        height: 100%;
    `,
    NoImage: styled.div`
        width: 100%;
        height: 100%;
        svg {
            width: 100%;
            height: 100%;
        }
    `,
    AmountInstalled: styled.span`
        font-weight: 600;
        margin-left: ${unitize(5)}; ;
    `,
    TextColumn: styled.div`
        flex: 1;
        ${media("sm")} {
            margin-left: ${unitize(10)};
        }
        p {
            font-size: ${unitize(14)};
            line-height: 1.5;
        }
        h4 {
            font-size: ${unitize(19)};
        }
    `,

    ButtonGroup: styled.div`
        display: flex;
        justify-content: flex-end;
        margin-top: auto; // set to bottom of container
    `,
    Button: styled.div`
        margin: ${unitize(20)};

        @media (hover: hover) {
            a:hover {
                text-decoration: none;
            }
        }
    `,
};
//#endregion styles
