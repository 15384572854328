import { action, computed, makeObservable, observable, reaction, runInAction } from "mobx";
import { ApiResponse } from "../../api/ApiService";
import { IArticleOverviewItem } from "../../api/ArticleApi";
import { miscApi } from "../../api/MiscApi";
import { ArticleType, IArticleRead } from "../../entities/Article";
import { IArticleCategoryRead } from "../../entities/ArticleCategory";
import { ArticlesVisibility } from "../../entities/ArticleVisibility";
import { DocumentationType } from "../../entities/Documentation";
import { IInstalledArticleInstall, IInstalledArticleRead } from "../../entities/InstalledArticle";
import { articleService } from "../../services/ArticleService";
import { dataLayerService, ModalPages } from "../../services/DataLayerService";
import { documentationService } from "../../services/DocumentationService";
import { installedArticleService } from "../../services/InstalledArticleService";
import { session } from "../../session/Session";
import { SuperControllerStore } from "../../stores/controller/SuperControllerStore";
import { hashCode } from "../../utils/generateHash";
import { gtmAssignArticle } from "../../utils/gtmEventCollection";
import { isDefined } from "../../utils/isDefined";
import { isNullish } from "../../utils/isNullish";
import { Opt } from "../../utils/typeUtils";
import { filterArticlesByArticleVisibility } from "./utils/filterArticlesByArticleVisibility";

type WaitingForKey =
    | keyof typeof subStores
    | "loadBuildingAreaArticleOverview"
    | "loadArticleCategories"
    | "addArticles"
    | "reloadUrl"
    | "fetchDeliveryNote"
    | "documentationService";

interface ArticleChosen {
    amount: number;
    article?: IArticleRead;
}

export enum Step {
    Choose,
    ArticleChosen,
    /** user picked in Step.Choose an item from `this.availableArticles` * */
    AvailableArticleChosen,
}

// "articleID" is the actual ABS product ID, needed to identify the correct product and it's e.g. image. Whereas "id" is simply the enumeration or counting of the products (hence starting with 1) on the delivery note
export type deliveryNoteArticles = {
    amount: number;
    id: number;
    articleId: number;
};

/** values are used as IDs for HTML Nodes */
export enum SubViewKey {
    Custom = "tab__custom",
    Available = "tab__available",
    Misc = "tab__misc",
    FallProtection = "tab__fall_protection",
    Climb = "tab__climb",
    Drainage = "tab_drainage",
    Ventilation = "tab_ventilation",
}

const subStores = {
    articleService,
    installedArticleService,
    documentationService,
};

type Page = "documentation" | "buildingArea";
export class AddArticleController extends SuperControllerStore<WaitingForKey> {
    static controllerName = "AddArticleController";

    buildingAreaId?: number;
    isInPage: Page = "buildingArea";
    documentationType?: DocumentationType;
    articleCategories: IArticleCategoryRead[] = [];

    /** all installedArticles (assigned to BuildingArea) of availableArticlesSelected that are available to add to this docu and have been selected. */
    availableInstalledArticlesSelected: Set<number> = new Set();

    /** all installedArticles of BuildingArea grouped by article */
    buildingAreaArticleOverview: IArticleOverviewItem[] = [];

    /**
     * User picks article(s) with installedArticles (assigned to BuildingArea) which is available to add to this docu.
     * If user chooses this kind of, the existing installedArticles are used AND only new documentationItems are created.
     */
    availableArticlesSelected: Set<number> = new Set();

    /** articles with which categoryIds should be shown? Set of categoryIds. If empty -> show all */
    categoryFilter = new Set<number>();

    /** modal consists of Steps (views). Which is active? */
    activeStep: Step = Step.Choose;

    /** modal consists of Tabs (views). Which is active? */
    subView?: SubViewKey;

    navigationKey?: string;

    get navigationVisible(): boolean {
        return (
            !this.isWaitingFor("loadBuildingAreaArticleOverview") &&
            !articleService.isWaitingFor("loadArticles") &&
            isDefined(this.subView) &&
            Object.values(this.navigationItemVisibility).includes(true)
        );
    }

    /**
     * defines which nav item should be hidden
     */
    navigationItemVisibility: Record<ArticleType | "available", boolean> = {
        abs: true,
        available: false,
        climb: false,
        custom: false,
        drainage: false,
        misc: false,
        thirdparty: false,
        ventilation: false,
    };

    get articleChosen(): ArticleChosen {
        return {
            amount: this.currentArticleAmount,
            article: articleService.list.find((article) => article.id === this.currentArticle),
        };
    }

    isVisibleNavItem(item: keyof AddArticleController["navigationItemVisibility"]): boolean {
        return this.navigationItemVisibility[item];
    }

    updateNavigationAvailableVisibility(available: IArticleOverviewItem[], page: Page): void {
        if (available.length < 1) {
            this.setSubView(SubViewKey.FallProtection);
        }
        this.navigationItemVisibility = {
            ...this.navigationItemVisibility,
            available: (available.length > 0 || this.isVisibleNavItem("available")) && page === "documentation",
        };
        this.navigationKey = hashCode(this.navigationItemVisibility);
    }

    /**
     * sets which nav item should be hidden
     */
    updateNavigationVisibility(
        data: IArticleRead[],
        page: Page,
        type?: DocumentationType,
        articlesVisibility?: ArticlesVisibility
    ): void {
        const articles =
            page === "buildingArea" ? data : filterArticlesByArticleVisibility(data, articlesVisibility, type);

        const typeIsAvailable = articles.reduce((stack, current) => {
            stack[current.type] = true;
            return stack;
        }, {} as Record<ArticleType, boolean>);

        this.navigationItemVisibility = {
            ...this.navigationItemVisibility,
            climb: typeIsAvailable.climb,
            custom: typeIsAvailable.custom || session.hasCompanyAdminRights || session.hasAbsAdminRights,
            drainage: typeIsAvailable.drainage,
            ventilation: typeIsAvailable.ventilation,
            misc: typeIsAvailable.misc,
            available: page === "documentation" && this.navigationItemVisibility.available,
        };
        this.navigationKey = hashCode(this.navigationItemVisibility);
    }

    get installedArticleIdsOfThisDocumentation(): (number | undefined)[] {
        return articleService
            .getSortedListDocu(this.currentId)
            .flatMap((a) => a.documentationItems.flatMap((dis) => dis.installedArticleId));
    }

    private currentArticle?: IArticleRead["id"];

    /**
     * User picks article to create new installedArticle AND documentationItem
     * (not like `availableArticleChosen` where user re-uses existing installedArticles)
     * */
    private currentArticleAmount = 0;

    constructor() {
        super(session, {
            all: false,
            documentationService: false,
            installedArticleService: false,
            addArticles: false,
            articleService: false,
            loadArticleCategories: false,
            loadBuildingAreaArticleOverview: false,
            reloadUrl: false,
            fetchDeliveryNote: false,
        });

        makeObservable<AddArticleController, "currentArticle" | "currentArticleAmount">(this, {
            buildingAreaId: observable,
            currentArticleAmount: observable,
            isInPage: observable,
            documentationType: observable,
            buildingAreaArticleOverview: observable,
            articleCategories: observable,
            currentArticle: observable,
            articleChosen: computed,
            availableArticlesSelected: observable,
            categoryFilter: observable,
            activeStep: observable,
            subView: observable,
            availableInstalledArticlesSelected: observable,
            setSubView: action,
            updateCategoryIdInFilter: action,
            backToStep1: action,
            installedArticleIdsOfThisDocumentation: computed,
            addArticlesByDeliveryNote: action,
            updateNavigationVisibility: action,
            updateNavigationAvailableVisibility: action,
            navigationItemVisibility: observable,
            navigationKey: observable,
            navigationVisible: computed,
        });
    }

    updateNavigationKey(key: string): void {
        this.navigationKey = key;
    }

    async init(docuId: number, areaId: number, page: Page, type?: DocumentationType): Promise<void> {
        this.currentId = docuId;
        this.isInPage = page;
        this.documentationType = type;
        this.buildingAreaId = areaId;

        if (isNullish(articleService.articlesVisibility)) {
            await articleService.loadArticlesVisibility();
        }
        if (
            (articleService.length === 0 || articleService.length < articleService.total) &&
            articleService.waitingFor.loadArticles === false &&
            articleService.waitingFor.reloadUrl === false
        ) {
            await articleService.fetchArticles();
        }
        this.updateNavigationVisibility(
            articleService.nonDeletedArticles,
            page,
            type,
            articleService.articlesVisibility
        );

        this.setInitSubview(page, type);

        this.register(
            reaction(
                () => articleService.nonDeletedArticles,
                (data) => {
                    this.updateNavigationVisibility(data, page, type, articleService.articlesVisibility);
                }
            ),
            reaction(
                () => this.buildingAreaArticleOverview,
                (data) => {
                    this.updateNavigationAvailableVisibility(data, page);
                }
            )
        );

        this.usePageData(
            () => {
                if (this.articleCategories.length === 0) {
                    this.loadArticleCategories();
                }
            },
            () => {
                if (this.buildingAreaArticleOverview.length === 0) {
                    this.loadBuildingAreaArticleOverview();
                }
            }
        );

        this.track(page, type);
    }

    setSubView(value?: SubViewKey): void {
        this.subView = value;
    }

    setAvailableInstalledArticlesSelected(value: Set<number>): void {
        runInAction(() => {
            this.availableInstalledArticlesSelected = value;
        });
    }

    /** user chooses in Step 1 an Article from `this.articles` */

    chooseArticle(article: Opt<IArticleRead["id"]>, amount: number): void {
        if (article === undefined) return;
        this.currentArticle = article;
        this.currentArticleAmount = amount;
        this.activeStep = Step.ArticleChosen;
    }

    selectAvailableArticle(articleId: number, type?: DocumentationType, installedArticleId?: number): void {
        const allInstalledArticles = this.buildingAreaArticleOverview.filter((a) => a.id === articleId)[0]
            .installedArticles;
        const availableInstalledArticleIds = allInstalledArticles
            .filter((ia) => this.isInstalledArticleAvailableToAdd(ia, type))
            .map((ia) => ia.id);
        this.availableArticlesSelected.add(articleId);
        if (installedArticleId === undefined) {
            //select all installedArticles of this article
            this.selectAvailableInstalledArticles(availableInstalledArticleIds);
        } else {
            //select only particular installedArticle of this article
            this.selectAvailableInstalledArticles([installedArticleId]);
        }
    }

    deselectAvailableArticle(articleId: number): void {
        this.availableArticlesSelected.delete(articleId);
        const allInstalledArticles = this.buildingAreaArticleOverview.filter((a) => a.id === articleId)[0]
            .installedArticles;
        //deselect all installedArticles of this article
        this.deselectAvailableInstalledArticles(allInstalledArticles.map((ia) => ia.id));
    }

    selectAvailableInstalledArticles(installedArticlesIds: number[]): void {
        installedArticlesIds.forEach((ia) => this.availableInstalledArticlesSelected.add(ia));
    }

    deselectAvailableInstalledArticles(installedArticlesIds: number[]): void {
        installedArticlesIds.forEach((ia) => this.availableInstalledArticlesSelected.delete(ia));
    }

    /** check if installedArticle is available to add  */
    isInstalledArticleAvailableToAdd = (
        installedArticle: IArticleOverviewItem["installedArticles"][0],
        type?: DocumentationType
    ) => {
        switch (type) {
            case "maintenance":
                // only if not yet in this documentation
                return !this.isPartOfThisDocu(installedArticle);
            case "assembly":
                // not part of any assembly documentation
                return installedArticle.amountAssemblies === 0;
            default:
                // should never happen, unless controller.documentation is not yet loaded
                return false;
        }
    };

    isPartOfThisDocu = (installedArticle: IArticleOverviewItem["installedArticles"][0]) => {
        return this.installedArticleIdsOfThisDocumentation.indexOf(installedArticle.id) !== -1;
    };

    loadArticleCategories(): void {
        this.resolveAsAction({
            promise: () => miscApi.getArticleCategories(),
            waitingForKey: "loadArticleCategories",
            action: (result) => {
                if (result.result !== null) {
                    this.articleCategories = result.result;
                }

                return result;
            },
        });
    }

    loadBuildingAreaArticleOverview(reloadUrl?: boolean): void {
        const reload = reloadUrl ?? false;
        if (this.buildingAreaId === 0 || this.buildingAreaId === undefined) {
            throw Error("cannot loadBuildingAreaArticleOverview() when buildingAreaId is undefined");
        }

        if (this.waitingFor.reloadUrl !== false || this.waitingFor.loadBuildingAreaArticleOverview !== false) {
            return;
        }

        this.resolveAsAction({
            promise: () => articleService.fetchArticlesByArea(this.buildingAreaId as number),
            waitingForKey: reload ? "reloadUrl" : "loadBuildingAreaArticleOverview",
            setWaitingForValueTo: this.buildingAreaId,
            action: (response) => {
                if (response.result !== null) {
                    this.buildingAreaArticleOverview = response.result;
                }

                return response;
            },
        });
    }

    async addArticlesByDeliveryNote(selectedFromNote: deliveryNoteArticles[]): Promise<void> {
        for (const dn of selectedFromNote) {
            if (dn.id > 0) {
                await this.addArticleToDocumentation(
                    this.currentId,
                    {
                        amount: dn.amount,
                        articleId: dn.articleId,
                    },
                    false
                );

                const article = articleService.get(dn.articleId);
                if (isDefined(article)) {
                    gtmAssignArticle(article.type, article.name, dn.amount, "maintenance | add-deliveryNote");
                }
            }
        }
        articleService.loadArticlesByDocu(this.currentId);
        this.loadBuildingAreaArticleOverview();
    }

    /**
     * Create InstalledArticles AND DocumentationItems, from a certain Article (from catalog).
     * So this request does actually two things.
     */

    addArticleToDocumentation(
        documentationId: number,
        data: IInstalledArticleInstall,
        reloadOverview = true
    ): Promise<ApiResponse<IInstalledArticleRead>> {
        this.trackAssignArticle();
        return this.resolveAsAction({
            promise: () => installedArticleService.addToDocumentation(documentationId, data),
            waitingForKey: "documentationService",
            setWaitingForValueTo: documentationId,
            action: (response) => {
                if (response.result !== null && reloadOverview) {
                    articleService.loadArticlesByDocu(documentationId);
                    this.loadBuildingAreaArticleOverview();
                }

                return response;
            },
        });
    }

    trackAssignArticle(): void {
        if (isNullish(this.articleChosen.article)) {
            return;
        }

        gtmAssignArticle(this.articleChosen.article.type, this.articleChosen.article.name, this.articleChosen.amount);
    }

    trackAssignAvailableArticle(installedArticleIds: Set<number>): void {
        const articles = installedArticleService
            .getMultiple([...installedArticleIds])
            .map((ia) => articleService.get(ia.articleId));
        const reduced = articles.reduce(
            (red: Record<number, { amount: number; name: string; type: ArticleType }>, article) => {
                if (isNullish(article)) return red;
                if (isNullish(red[article.id])) {
                    red[article.id] = {
                        name: article.name,
                        amount: 0,
                        type: article.type,
                    };
                }
                red[article.id].amount++;
                return red;
            },
            {}
        );

        Object.values(reduced).forEach((obj) => {
            gtmAssignArticle(
                obj.type,
                obj.name,
                obj.amount,
                `${this.documentationType ?? "documentation"} | add-available-article` as ModalPages
            );
        });
    }

    /**
     * add installedArticle (article which is already added to buildingArea,
     * but not to a documentation yet) to documentation.
     */
    addInstalledArticlesToDocumentation(documentationId: Opt<number>, installedArticleIds: Set<number>): void {
        const docId = documentationId ?? 0;
        const installedArticleIdsAsArray = [...installedArticleIds];

        if (docId === 0) {
            return;
        }

        this.trackAssignAvailableArticle(installedArticleIds);

        this.resolveAsAction({
            promise: () =>
                installedArticleService.addInstalledArticleToDocumentation(docId, {
                    installedArticleIds: installedArticleIdsAsArray,
                }),
            waitingForKey: "addArticles",
            action: (response) => {
                if (response.result !== null) {
                    articleService.loadArticlesByDocu(docId);
                    this.activeStep = Step.Choose;
                    this.loadBuildingAreaArticleOverview();
                }

                return response;
            },
        });
    }

    createInstalledArticles(
        buildingAreaId: number,
        data: IInstalledArticleInstall
    ): Promise<ApiResponse<{ [p: string]: IInstalledArticleRead }>> {
        this.trackAssignArticle();
        return this.resolveAsAction({
            promise: () => installedArticleService.createInstalledArticle(buildingAreaId, data),
            waitingForKey: "addArticles",
            action: (response) => {
                if (response.result !== null) {
                    if (this.isInPage !== "buildingArea") {
                        throw Error(
                            'createInstalledArticles() should never be called in page "Documentation", only in "BuildingArea"'
                        );
                    }
                    this.activeStep = Step.Choose;
                    this.loadBuildingAreaArticleOverview();
                }

                return response;
            },
        });
    }

    updateCategoryIdInFilter(categoryId: number, enabled: boolean): Set<number> {
        if (enabled) {
            this.categoryFilter.add(categoryId);
            return new Set(this.categoryFilter);
        } else {
            this.categoryFilter.delete(categoryId);
            return new Set(this.categoryFilter);
        }
    }

    backToStep1(): void {
        this.currentArticle = undefined;
        this.currentArticleAmount = 0;
        this.activeStep = Step.Choose;
    }

    private track(page: Page, type?: DocumentationType) {
        const currentPage =
            page === "buildingArea"
                ? "area | add-article"
                : type === "assembly"
                ? "assembly | add-article"
                : "maintenance | add-article";
        const previousPage = page === "buildingArea" ? "area" : type;

        this.register(() => {
            dataLayerService.emitHistory(location, dataLayerService.dataLayer, previousPage ?? "documentation");
        });

        dataLayerService.emitHistory(location, dataLayerService.dataLayer, currentPage);
    }

    private setInitSubview(page: Page, type?: DocumentationType) {
        if (page === "buildingArea") {
            this.subView = this.isVisibleNavItem("abs") ? SubViewKey.FallProtection : undefined;
        }

        if (page === "buildingArea") {
            this.subView = SubViewKey.FallProtection;
        } else {
            //check documentationType to display the right subview
            type === "assembly" ? (this.subView = SubViewKey.FallProtection) : (this.subView = SubViewKey.Available);
        }
    }
}
