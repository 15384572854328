/**
 * Remember that configs, which have to be different on dev and prod,
 * should be saved in /.env file.
 * Configs in this file, on the other hand are just shared constants.
 */
export const config = {
    /**
     * For `accept` attribute of `<input>`.
     * Defined also in API project (services/BuildingAttachment.php)
     * */
    UPLOAD_ACCEPT_BUILDING_ATTACHMENT: [
        "image/jpeg",
        "image/png",
        "application/pdf",
        "application/vnd.ms-excel",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "application/vnd.oasis.opendocument.text",
    ].join(", "),

    UPLOAD_ACCEPT_ARTICLE_IMAGE: ["image/jpeg", "image/png"].join(", "),

    UPLOAD_ACCEPT_ARTICLE_ATTACHMENT: ["image/jpeg", "image/png", "application/pdf"].join(", "),

    /** used for infinite scrolling: how many items should be fetched per API Requests (per scroll to bottom trigger) */
    PAGINATION_ITEMS_PER_PAGE: 30,

    /** ms to wait for user not to edit answers in document questions, till submit */
    DEBOUNCE_WAIT_DOCUMENT_QUESTIONS: 1000,

    /**
     * used for switching special temporary notifications on and off in the browser
     * used in modal Step2CompleteDocumentationAssembly and ModalNextMaintenance starting on 21 February 2022
     * */
    QUICK_SWITCHES: {
        maintenance_voucher: false,
    },
};
