import { FormControl, Input } from "@abs-safety/lock-book-web-ui";
import { useField } from "formik";
import React, { FunctionComponent } from "react";

interface FormInputProps {
    label: string;
    name: string;
    type: "text" | "password" | "email" | "number" | "textarea";
    size?: "sm" | "md" | "lg";
    placeholder?: string;
    id?: string;
    isHeading?: boolean;
    isOptional?: boolean;
    disableAutoComplete?: boolean;
    disabled?: boolean;
}

/**
 * A Wrapper Component around LBWEBUI's `Input` to work with Formik
 *
 * - converts undefined & null to ""
 * - shows error only when input is touched
 * - when no placeholder: create placeholder out of props.label
 */
const FormInput: FunctionComponent<FormInputProps> = (props: FormInputProps) => {
    const [field, meta] = useField({
        ...props,
        autoComplete: props.disableAutoComplete === true ? autoCompleteOffString : undefined,
    });

    const isOptional = props.isOptional ?? false;
    const error = meta.error ?? "";
    return (
        <FormControl size={props.size}>
            <Input
                {...field}
                label={props.label}
                type={props.type}
                value={field.value ?? ""}
                isHeading={props.isHeading ?? false}
                error={meta.touched && error !== ""}
                errorMessage={error}
                placeholder={props.placeholder !== undefined ? props.placeholder : `${props.label} eingeben`}
                labelSuffix={isOptional ? "(optional)" : undefined}
                autoComplete={props.disableAutoComplete === true ? autoCompleteOffString : undefined}
                disabled={props.disabled}
            />
        </FormControl>
    );
};

const autoCompleteOffString = "new-password";

export default FormInput;
