import { computed, makeObservable, observable } from "mobx";
import { ApiResponse } from "../api/ApiService";
import { AreaApi } from "../api/AreaApi";
import { ArticleApi, IArticleOverviewItem } from "../api/ArticleApi";
import { fetchTypes, HTTPMethod } from "../api/Connection";
import { DocumentationApi, IArticleOverviewItemDocu } from "../api/DocumentationApi";
import { InstalledArticleApi } from "../api/InstalledArticleApi";
import { MiscApi } from "../api/MiscApi";
import { articleRepo, IArticleRead, IArticleWrite } from "../entities/Article";
import { ArticlesVisibility, parseArticlesVisibilityApiResponse } from "../entities/ArticleVisibility";
import { documentationRepo } from "../entities/Documentation";
import { documentationItemRepo } from "../entities/DocumentationItem";
import { installedArticleRepo } from "../entities/InstalledArticle";
import { isDefined } from "../utils/isDefined";
import { fetchUpload } from "../utils/_depre.fetch";
import { DataService } from "./abstract/DataService";

type WaitingForKey = "loadArticles" | "reloadUrl" | "loadArticlesVisibility";

export const getLastUpdate = (items: { createdAt?: null | string | number | Date }[]): number => {
    const times = items.map((item) => (item.createdAt ? new Date(item.createdAt).getTime() : 0));
    return Math.max(...times);
};

class ArticleService extends DataService<WaitingForKey, IArticleRead, IArticleWrite> {
    apiService = new ArticleApi();
    areaApi = new AreaApi();
    miscApi = new MiscApi();
    installedArticleApi = new InstalledArticleApi();
    documentationApi = new DocumentationApi();
    /** key:  */
    docuItemToArticle: Record<number, number> = {};
    installedArticleToArticle: Record<number, number> = {};
    repo = articleRepo;
    articlesVisibility?: ArticlesVisibility;

    get docuItemAmounts(): { [p: number]: number } {
        return this._docuItemAmounts;
    }

    get nonDeletedArticles(): IArticleRead[] {
        return this.list.filter((article) => article.isActive);
    }

    private _installedArticleAmountMaintenance: { [key: number]: number } = {};
    private _docuItemAmounts: { [key: number]: number } = {};

    constructor() {
        super({
            loadArticles: false,
            reloadUrl: false,
            loadArticlesVisibility: false,
        });

        makeObservable<ArticleService, "_docuItemAmounts">(this, {
            _docuItemAmounts: observable,
            docuItemAmounts: computed,
            nonDeletedArticles: computed,
        });
    }

    getSortedListDocu(documentationId: number): IArticleOverviewItemDocu[] {
        return this.getCombinedListDocu(documentationId).sort((a, b) => {
            return getLastUpdate(b.documentationItems) - getLastUpdate(a.documentationItems);
        });
    }

    getCombinedListDocu(documentationId: number): IArticleOverviewItemDocu[] {
        const docuItems = documentationItemRepo.list.filter((item) => item.documentationId === documentationId);
        const mergedArticles: Record<number, IArticleOverviewItemDocu> = {};

        for (const docuItem of docuItems) {
            const articleId = this.docuItemToArticle[docuItem.id];

            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            if (mergedArticles[articleId] !== undefined) {
                mergedArticles[articleId].documentationItems.push(docuItem);
                continue;
            }

            const match: undefined | IArticleRead = this.get(articleId);
            if (match === undefined) {
                continue;
            }

            mergedArticles[articleId] = { ...match, documentationItems: [docuItem] };
        }

        return Object.values(mergedArticles);
    }

    getCombinedListArea(areaId: number): IArticleOverviewItem[] {
        const installedArticles = installedArticleRepo.list
            .filter((iA) => iA.buildingAreaId === areaId)
            .map((iA) => {
                return { ...iA, amountMaintenances: this._installedArticleAmountMaintenance[iA.id] };
            });
        const mergedArticles: Record<IArticleRead["id"], IArticleOverviewItem> = {};

        for (const installedArticle of installedArticles) {
            const articleId = this.installedArticleToArticle[installedArticle.id];
            if (mergedArticles[articleId] !== undefined) {
                mergedArticles[articleId].installedArticles.push(installedArticle);
                continue;
            }

            const match: undefined | IArticleRead = this.repo.get(articleId);
            if (match === undefined) {
                continue;
            }

            mergedArticles[articleId] = {
                ...match,
                installedArticles: [installedArticle],
            };
        }

        return Object.values(mergedArticles).sort((a, b) => {
            return (
                Math.max(...b.installedArticles.map((item) => item.id)) -
                Math.max(...a.installedArticles.map((item) => item.id))
            );
        });
    }

    fetchArticlesIfNotYetFetched(): void {
        if (this.list.length === 0 && this.waitingFor.loadArticles === false && this.waitingFor.reloadUrl === false) {
            this.fetchArticles();
        }
    }

    fetchArticles(reloadUrl?: boolean): Promise<ApiResponse<Record<number, IArticleRead>>> {
        return this.resolveAsAction({
            promise: () => this.apiService.get<Record<number, IArticleRead>>(""),
            waitingForKey: reloadUrl === true ? "reloadUrl" : "loadArticles",
            action: (result) => {
                if (isDefined(result.result)) {
                    this.mergeList(Object.values(result.result).filter((article) => article.isActive));
                }

                return result;
            },
        });
    }

    loadArticlesVisibility(): void {
        this.resolveAsAction({
            promise: () => this.miscApi.getArticlesVisibility(),
            waitingForKey: "loadArticlesVisibility",
            action: (result) => {
                if (isDefined(result.result)) {
                    this.articlesVisibility = parseArticlesVisibilityApiResponse(result.result);
                }
                return result;
            },
        });
    }

    loadArticlesByDocu(docuId: number): Promise<ApiResponse<IArticleOverviewItemDocu[]>> {
        return this.resolveAsAction({
            promise: () => this.documentationApi.getArticleOverview(docuId),
            waitingForKey: "fetchList",
            setWaitingForValueTo: docuId,
            action: (response) => {
                if (isDefined(response.result)) {
                    this.mergeArticlesDocu(response.result, docuId);
                }

                return response;
            },
        });
    }

    fetchArticlesByArea(areaId: number): Promise<ApiResponse<IArticleOverviewItem[]>> {
        return this.resolveAsAction({
            promise: () => this.areaApi.getArticleOverview(areaId),
            waitingForKey: ["fetchList"],
            setWaitingForValueTo: areaId,
            action: (response) => {
                if (isDefined(response.result)) {
                    this.mergeArticlesArea(response.result);
                }
                return response;
            },
        });
    }

    /** Overriden create function, as it sends data via formData. */
    create(body: IArticleWrite): Promise<ApiResponse<IArticleRead>> {
        this.waitingFor.create = true;
        const formData = new FormData();
        Object.entries(body).forEach(([key, value]) => {
            if (typeof value === "string" || value instanceof Blob) {
                formData.append(key, value);
            } else if (typeof value === "number") {
                formData.append(key, value.toString());
            } else {
                console.error("could not append value to formData:", value);
            }
        });

        return fetchUpload<IArticleRead>("POST", "/articles", formData)
            .then((res) => {
                console.log("success", res);
                this.prependList(res);
                this.waitingFor.create = false;
                return res;
            })
            .catch((err) => {
                console.log("err", err);
            }) as any; // TODO: cannot stay like this. DataService expects ApiResponse

        // TODO: maybe better use our DataService(with Repo Service) / APIService / Connection structure,
        // but I cannot make it work right now (types) and it's too deep for me...
        // return this.resolveAsAction({
        //     promise: () => this.apiService.upload<IArticleRead>("", formData),
        //     waitingForKey: "create",
        //     action: (result) => {
        //         if (result.result !== null) {
        //             this.prependList(result.result);
        //         }

        //         return result;
        //     },
        // });
    }

    /** could not override `update` as response type from DataService.update is
     * `Promise<ApiResponse<IArticleRead, fetchTypes.fetch>>` (not with fetchUpload) */
    updateViaFormData(id: number, body: IArticleWrite): Promise<ApiResponse<IArticleRead, fetchTypes.fetchUpload>> {
        // TODO: outsource?
        const formData = new FormData();
        Object.entries(body).forEach(([key, value]) => {
            if (typeof value === "string" || value instanceof Blob) {
                formData.append(key, value);
            } else if (typeof value === "number") {
                formData.append(key, value.toString());
            } else {
                console.error("could not append value to formData:", value);
            }
        });
        return this.resolveAsAction({
            promise: () =>
                this.apiService.invoke(fetchTypes.fetchUpload, {
                    route: `/${id}`,
                    body: formData,
                    method: HTTPMethod.PUT,
                }),
            waitingForKey: "update",
            action: (res) => {
                if (res.result !== null) {
                    this.mergeList(res.result);
                }
                return res;
            },
        });
    }

    private mergeArticlesDocu(result: IArticleOverviewItemDocu[], docuId?: number) {
        this.mergeList(result);

        for (const article of result) {
            documentationItemRepo.mergeList(article.documentationItems);
            this._docuItemAmounts[article.id] = article.documentationItems.length;

            for (const documentationItem of article.documentationItems) {
                this.docuItemToArticle[documentationItem.id] = article.id;
            }
        }
        if (docuId) {
            const docu = documentationRepo.get(docuId);

            if (docu?.buildingAreaId) {
                this.resolveAsAction({
                    promise: () => this.areaApi.getInstalledArticles(docu?.buildingAreaId ?? 0),
                    waitingForKey: "fetchList",
                    action: (response) => {
                        if (response.result) {
                            installedArticleRepo.mergeList(response.result);
                        }

                        return response;
                    },
                });
            }
        }
    }

    private mergeArticlesArea(result: IArticleOverviewItem[]) {
        this.mergeList(result);

        for (const article of result) {
            installedArticleRepo.mergeList(article.installedArticles);

            for (const installedArticle of article.installedArticles) {
                this.installedArticleToArticle[installedArticle.id] = article.id;
                this._installedArticleAmountMaintenance[installedArticle.id] = installedArticle.amountMaintenances;
            }
        }
    }

    deleteArticle(articleId: number) {
        this.resolveAsAction({
            promise: () => this.apiService.deleteArticle(articleId),
            waitingForKey: "delete",
            action: (response) => {
                if (response.response?.ok === true) {
                    this.drop(articleId);
                }
                return response;
            },
        });
    }
}

export const articleService = new ArticleService();
