import { designTheme, unitize } from "@abs-safety/lock-book-web-ui";
import { Form, Formik } from "formik";
import { isEmpty } from "lodash";
import { observer } from "mobx-react";
import moment from "moment";
import React, { FunctionComponent, useEffect, useState } from "react";
import styled from "styled-components";
import * as Yup from "yup";
import FieldLoading from "../../../components/FieldLoading";
import FormAutoSubmit from "../../../components/FormAutoSubmit";
import FormGtmIntegration from "../../../components/FormGtmIntegration";
import FormInput from "../../../components/FormInput";
import { getConstraints } from "../../../constraints/constraints";
import { IDocumentationUpdate } from "../../../entities/Documentation";
import { documentationService } from "../../../services/DocumentationService";
import { session } from "../../../session/Session";
import { getController } from "../../../stores/controller/ControllerFactory";
import { formatDate } from "../../../utils/formatDate";
import { gtmAutoSubmit } from "../../../utils/gtmEventCollection";
import { DocumentationController } from "../DocumentationController";

export interface EditDetailsModalProps {
    documentationId: number;
}

type FormValues = IDocumentationUpdate;

const EditDetails: FunctionComponent<EditDetailsModalProps> = (props: EditDetailsModalProps) => {
    const { controller } = getController(DocumentationController);
    const [initialValues, setInitialValues] = useState<FormValues | undefined>();
    const [validationSchema, setValidationSchema] = useState(Yup.object().shape<Partial<FormValues>>({}));

    useEffect(() => {
        const documentation = documentationService.get(props.documentationId);
        if (documentation === undefined) {
            return;
        }
        setInitialValues({
            ...documentation,
            name: documentation.name !== null ? documentation.name : "",
            date:
                documentation.date !== null && documentation.date !== undefined
                    ? formatDate(new Date(documentation.date))
                    : new Date(),
        });

        if (initialValues === undefined) {
            return;
        }
    }, [props.documentationId]);

    useEffect(() => {
        setValidationSchema(
            Yup.object().shape<Partial<FormValues>>({
                name: getConstraints("Documentation", "name"),
                date: Yup.string().matches(/^[0-9]{1,2}\.[0-9]{1,2}\.[0-9]{4}$/, "kein gültiges Format (dd.mm.yyyy)"),
            })
        );
    }, [session.constraints]);

    //#region render
    return controller.waitingFor.loadDocumentation === true || isEmpty(session.constraints) ? (
        <FieldLoading text="Dokumentation wird geladen..." />
    ) : initialValues === undefined ? (
        <S.NotFoundText>Keine Dokumentation zu der URL gefunden</S.NotFoundText>
    ) : (
        <Formik
            initialValues={initialValues}
            enableReinitialize={true}
            validationSchema={validationSchema}
            onSubmit={(values) => {
                const date = moment(values.date, "DD.MM.YYYY").utc(true).toDate();
                documentationService.update(props.documentationId, { ...values, date });
            }}
        >
            {() => (
                <ObservedForm
                    documentationType={
                        controller.documentation !== undefined
                            ? controller.documentation.type === "maintenance"
                                ? "Wartung"
                                : "Montage"
                            : "Dokumentation"
                    }
                />
            )}
        </Formik>
    );
};

/**
 * Form Component
 */
interface FormComponentProps {
    documentationType: string;
}

const FormComponent: FunctionComponent<FormComponentProps> = (props: FormComponentProps) => (
    <Form>
        <S.EditDetails>
            <FormInput type="text" name="name" label="Name der Dokumentation" size="lg" isHeading />
            <FormInput type="text" name="date" label={"Datum der " + props.documentationType} size="lg" />
            <FormInput type="textarea" name="comment" label="Bemerkung" size="sm" />
            <S.Suffix>Diese Bemerkung wird in der Dokumentation angedruckt.</S.Suffix>
        </S.EditDetails>
        <FormAutoSubmit onSuccess={gtmAutoSubmit("documentation")} />
        <FormGtmIntegration />
    </Form>
);

const ObservedForm = observer(FormComponent);
export default observer(EditDetails);

//#region styles
const S = {
    EditDetails: styled.div`
        margin-top: ${unitize(60)};
    `,
    NotFoundText: styled.h4`
        margin-top: ${unitize(20)};
    `,
    FormHeadline: styled.h5`
        margin-top: ${unitize(30)};
    `,
    Suffix: styled.p`
        margin-top: -${unitize(10)};
        color: ${designTheme.color.darkgrey};
        font-size: ${unitize(14)};
    `,
};
//#endregion styles
