import { Button, Checkbox, media, Modal, ModalContent, ModalHeader, unitize } from "@abs-safety/lock-book-web-ui";
import { observer } from "mobx-react";
import React, { FunctionComponent, useCallback, useState } from "react";
import styled from "styled-components";
import { dataLayerService } from "../../../services/DataLayerService";
import { getController } from "../../../stores/controller/ControllerFactory";
import { neverShowModalNextMaintenanceRefusalForDocu } from "../../../utils/neverShowModalNextMaintenanceRefusalForDocu";
import { DocumentationController } from "../DocumentationController";

interface ModalNextMaintenanceOfferProps {
    onClose: () => void;
}

export const ModalNextMaintenanceOffer: FunctionComponent<ModalNextMaintenanceOfferProps> = (
    props: ModalNextMaintenanceOfferProps
) => {
    const { controller } = getController(DocumentationController);
    const [approveOffer, setApproveOffer] = useState(false);

    const onCheckboxChange = useCallback((checked: boolean) => {
        setApproveOffer(checked);
    }, []);

    const onMaintenanceOfferRequest = () => {
        controller.sendDocumentationOfferMails(controller.currentId);
        neverShowModalNextMaintenanceRefusalForDocu(controller.currentId);
        props.onClose();
        dataLayerService.emitClick({
            category: dataLayerService.currentPage,
            action: "next_maintenance_offer_step2",
            label: `request_offer`,
        });
    };

    const onClose = () => {
        dataLayerService.emitClick({
            category: dataLayerService.currentPage,
            action: "next_maintenance_offer_step2",
            label: `cancel`,
        });
        props.onClose();
    };

    return (
        <S.ModalWrapper className={"uf-modalOffer"}>
            <Modal isOpen size="sm" style={{ paddingTop: `${unitize(50)}` }} onCloseClick={onClose}>
                <ModalHeader title="Wir übernehmen gerne Deine Wartung!" />
                <ModalContent>
                    <S.ModalTextWrapper>
                        Wir senden Dir ein unverbindliches Angebot für einen Wartungsvertrag an Deine hinterlegte
                        E&#8209;Mail Adresse zu. Den Vertrag kannst Du an den Gebäudebetreiber weitergeben und
                        unterzeichnen lassen.
                    </S.ModalTextWrapper>
                    <S.Checkbox>
                        <Checkbox
                            checked={approveOffer}
                            onChange={onCheckboxChange}
                            regularWeight={true}
                            text="Dokumentationsdaten an ABS senden"
                            className="uf-offerCheckBox"
                        />
                    </S.Checkbox>
                    <S.ButtonWrapper>
                        <Button className={"uf-offerRefusal"} variant="text" color="black">
                            <button type="button" onClick={onClose}>
                                Nein, danke
                            </button>
                        </Button>
                        <Button disabled={approveOffer === false}>
                            <button className={"uf-offerRequest"} type="submit" onClick={onMaintenanceOfferRequest}>
                                Anfragen
                            </button>
                        </Button>
                    </S.ButtonWrapper>
                </ModalContent>
            </Modal>
        </S.ModalWrapper>
    );
};

export default observer(ModalNextMaintenanceOffer);

//#region styles
const S = {
    ModalWrapper: styled.div`
        position: absolute;
        width: 100vw;
        height: 100vw;
        max-width: 100%;
        max-height: 100%;
        top: 0;
        left: 0;
    `,
    ButtonWrapper: styled.div`
        display: flex;
        justify-content: center;
        flex-direction: column;
        gap: ${unitize(10)};
        margin-top: ${unitize(37)};
        ${media("md")} {
            gap: ${unitize(30)};
            flex-direction: row;
        }
    `,
    ModalTextWrapper: styled.div`
        margin-top: ${unitize(10)};
        margin-bottom: ${unitize(30)};
        color: black;
        width: 100%;
        font-size: ${unitize(16)};
        text-align: left;
    `,
    Checkbox: styled.div`
        text-align: left;
    `,
};
//#endregion styles
