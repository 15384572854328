/**
 * based on lock-book-docu-api: api/src/Entity/DocumentationItemQuestion.php
 */

import { RepoStore } from "../stores/RepoStore";
import { QuestionType } from "./ArticleQuestion";
import { DocumentationType } from "./Documentation";
import { IDocumentationItemRead } from "./DocumentationItem";
import { IDocumentationItemAnswerRead } from "./DocumentationItemAnswer";
import { IUserRead } from "./User";

class DocumentationItemQuestionRepo extends RepoStore<IDocumentationItemQuestionWithAnswer> {}

export const documentationItemQuestionRepo = new DocumentationItemQuestionRepo();

// related services
type DocumentationItem = IDocumentationItemRead;
type User = IUserRead;
type DocumentationItemAnswer = IDocumentationItemAnswerRead;

export interface DocumentationItemQuestionBase {
    id: number;
    question?: string;
    description?: string;
    documentationType?: DocumentationType;
    position: number;
    createdAt?: Date;
    questionType?: QuestionType;
    /** default: false */
    isMandatory: boolean;

    //one to one
    answer?: DocumentationItemAnswer;

    //many to one
    documentationItem: DocumentationItem;
    user: User;

    documentationItemId: number;

    // extra props for other types
    /** yes_no question: number */
    defaultAnswer?: string | number | null;

    // extra props for type=list
    // by API definition it's from type `DocumentationItemQuestionTypeListItem[]`
    // but it comes back as an array of strings.
    items?: string[] | null;

    // extra props for type=number
    min?: number | null;
    max?: number | null;

    error?: boolean;
}

/** All DocumentationItemQuestion properties from php entity file with `question:read` annotation */
export type IDocumentationItemQuestionRead = Pick<
    DocumentationItemQuestionBase,
    | "id"
    | "question"
    | "description"
    | "documentationType"
    | "position"
    | "isMandatory"
    | "questionType"
    | "documentationItem"
    | "documentationItemId"
    | "defaultAnswer"
    | "answer"
    | "items"
    | "min"
    | "max"
>;

/** All DocumentationItemQuestion properties from php entity file with `question:write` annotation */
export type IDocumentationItemQuestionWrite = Pick<
    DocumentationItemQuestionBase,
    | "question"
    | "description"
    | "documentationType"
    | "position"
    | "isMandatory"
    | "questionType"
    | "documentationItem"
    | "documentationItemId"
    | "defaultAnswer"
    | "items"
    | "min"
    | "max"
>;

/**
 * Used by `GET /documentations/${documentationId}/documentation-item-questions` (API uses Custom Query)
 * where question.answer is not the DocumentationItemAnswer relation but directly the
 * DocumentationItemAnswer.answer.answer property
 */
export type IDocumentationItemQuestionWithAnswer = Omit<
    IDocumentationItemQuestionRead,
    "answer"
    // TODO: Tell API (Alex) to not parse answer to float, as DocumentationItemAnswer.answer also is only string
> & { answer: string | null | undefined };
