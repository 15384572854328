import { SelectBoxProps, unitize } from "@abs-safety/lock-book-web-ui";
import { Formik } from "formik";
import { isEmpty } from "lodash";
import React, { FunctionComponent, useEffect, useState } from "react";
import * as Yup from "yup";
import FieldLoading from "../../../components/FieldLoading";
import { getConstraints } from "../../../constraints/constraints";
import { IBuildingCreate } from "../../../entities/Building";
import { session } from "../../../session/Session";
import Step2Form from "./Step2Form";

interface Step2Props {
    onSubmit: (values: FormValues) => void;
    formData: Partial<IBuildingCreate>;
    onCloseClick: () => void;
    previousStep: (values: FormValues) => void;
}

export type FormValues = Pick<
    IBuildingCreate,
    | "managerRole"
    | "managerSalutation"
    | "managerFirstName"
    | "managerLastName"
    | "managerEmail"
    | "managerPhone1"
    | "managerPhone2"
    | "managerFax"
>;

export const salutationOptions: SelectBoxProps["options"] = [
    { text: "Frau", value: "Frau" },
    { text: "Herr", value: "Herr" },
    { text: "Divers", value: "Divers" },
];

const Step2: FunctionComponent<Step2Props> = (props: Step2Props) => {
    const [initialValues] = useState<FormValues>({
        managerSalutation: props.formData.managerSalutation ? props.formData.managerSalutation : "",
        managerFirstName: props.formData.managerFirstName ? props.formData.managerFirstName : "",
        managerLastName: props.formData.managerLastName ? props.formData.managerLastName : "",
        managerRole: props.formData.managerRole ? props.formData.managerRole : "",
        managerPhone1: props.formData.managerPhone1 ? props.formData.managerPhone1 : "",
        managerPhone2: props.formData.managerPhone2 ? props.formData.managerPhone2 : "",
        managerFax: props.formData.managerFax ? props.formData.managerFax : "",
        managerEmail: props.formData.managerEmail ? props.formData.managerEmail : "",
    });

    const [validationSchema, setValidationSchema] = useState(Yup.object().shape<Partial<FormValues>>({}));

    useEffect(() => {
        setValidationSchema(
            Yup.object().shape<Partial<FormValues>>({
                managerRole: getConstraints("Building", "managerRole"),
                managerSalutation: getConstraints("Building", "managerSalutation"),
                managerFirstName: getConstraints("Building", "managerFirstName"),
                managerLastName: getConstraints("Building", "managerLastName"),
                managerEmail: getConstraints("Building", "managerEmail"),
                managerPhone1: getConstraints("Building", "managerPhone1"),
                managerPhone2: getConstraints("Building", "managerPhone2"),
                managerFax: getConstraints("Building", "managerFax"),
            })
        );
    }, [session.constraints]);

    return isEmpty(session.constraints) ? (
        <FieldLoading height={unitize(400)} text="lädt..." />
    ) : (
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={props.onSubmit}>
            {(formikBag) => (
                <Step2Form
                    onCloseClick={props.onCloseClick}
                    previousStep={() => props.previousStep(formikBag.values)}
                    {...formikBag}
                />
            )}
        </Formik>
    );
};

export default Step2;
