import { Checkbox, unitize } from "@abs-safety/lock-book-web-ui";
import { observer } from "mobx-react";
import React, { FunctionComponent, useEffect, useState } from "react";
import styled, { css } from "styled-components";
import FieldLoading from "../../../../components/FieldLoading";
import { IDocumentationItemQuestionWithAnswer } from "../../../../entities/DocumentationItemQuestion";
import { documentationItemService } from "../../../../services/DocumentationItemService";
import { getController } from "../../../../stores/controller/ControllerFactory";
import { isDefined } from "../../../../utils/isDefined";
import { isNullish } from "../../../../utils/isNullish";
import { DocumentationController } from "../../DocumentationController";
import DocumentationItem from "../components/DocumentationItem";
import { DocumentController, SubViewKey } from "../DocumentController";
import FilterDocumentationItems from "./components/FilterDocumentationItems";

export type Filter = "notDocumented" | "locked" | "successful";

const ColDocumentationItems: FunctionComponent = () => {
    const { controller: documentController } = getController(DocumentController);
    const { controller } = getController(DocumentationController);
    const documentationCompleted = controller.documentation?.isCompleted;
    // save separate from allDocumentationItems in state, so comp can re-order when sorting list we can filter
    const [visibleDocumentationItems, setVisibleDocumentationItems] = useState(
        documentController.documentationItemsByArticle
    );

    useEffect(() => {
        documentController.setCurrentDocumentationItemIds(
            isDefined(visibleDocumentationItems[0]) ? [visibleDocumentationItems[0].id] : []
        );
    }, []);

    useEffect(() => {
        setVisibleDocumentationItems(documentController.documentationItemsByArticle);
    }, [documentController.documentationItemsByArticle]);

    useEffect(() => {
        setVisibleDocumentationItems(documentController.documentationItemsByArticle);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [documentController.currentArticle]);

    const onCardClick = (documentationItemId: number) => {
        documentController.setCurrentDocumentationItemIds([documentationItemId]);
        documentController.loadDocumentationItemAttachments();
    };

    const onDeleteClick = (documentationItemId: number) => {
        documentationItemService.delete(documentationItemId);
    };

    const onSelectAll = () => {
        documentController.setSubView(SubViewKey.Questions);
        documentController.setCurrentDocumentationItemIds([...visibleDocumentationItems.map((di) => di.id)]);
    };

    const onDeselectAll = () => {
        // if no specific article is checked, we always check the first one.
        documentController.setCurrentDocumentationItemIds([visibleDocumentationItems[0]["id"]]);
    };

    const toggleSelectAll: () => void = () => {
        if (documentController.currentDocumentationItemIds.length === visibleDocumentationItems.length) {
            onDeselectAll();
        } else {
            onSelectAll();
        }
    };

    const docuItemHasError = (questions: Set<IDocumentationItemQuestionWithAnswer>, docuItemId: number) => {
        return questions.size > 0 && [...questions].filter((q) => q.documentationItemId === docuItemId).length > 0;
    };

    return (
        <S.View>
            <S.Header className="hide-in-accordion" />
            <S.Title className="hide-in-accordion">
                <h3>Artikel</h3>
            </S.Title>

            <S.SelectMultipleControl>
                <S.Checkbox disabled={visibleDocumentationItems.length === 0} className={"uf-selectAll"}>
                    <Checkbox
                        onChange={toggleSelectAll}
                        checked={
                            visibleDocumentationItems.length !== 0 &&
                            documentController.currentDocumentationItemIds.length ===
                                documentController.documentationItemsByArticle.length
                        }
                        text="Alle auswählen"
                    />
                </S.Checkbox>
                <S.Dropdown>
                    {documentationCompleted !== true && (
                        <FilterDocumentationItems
                            allDocumentationItems={documentController.documentationItemsByArticle}
                            onFilter={setVisibleDocumentationItems}
                        />
                    )}
                </S.Dropdown>
            </S.SelectMultipleControl>

            {/*  List of VisibleDocumentationItems or placeholder */}
            {isNullish(documentController.article) ||
            documentController.waitingFor.deleteDocumentationItem !== false ? (
                <FieldLoading />
            ) : documentController.documentationItemsByArticle.length === 0 ||
              visibleDocumentationItems.length === 0 ? (
                <S.Placeholder>kein Artikel gefunden</S.Placeholder>
            ) : documentController.documentationItemsByArticle.length < 0 ? (
                // this should never happen, that there's an article in SideModal without DocumentationItems
                <S.Placeholder>keine Artikel vorhanden</S.Placeholder>
            ) : (
                visibleDocumentationItems.map((documentationItem) => (
                    <DocumentationItem
                        key={documentationItem.id}
                        documentationItem={documentationItem}
                        selected={documentController.currentDocumentationItemIds.indexOf(documentationItem.id) > -1}
                        amountAttachments={documentController.attachmentAmountPerItem[documentationItem.id]}
                        onClick={() => onCardClick(documentationItem.id)}
                        deleteClick={() => {
                            onDeleteClick(documentationItem.id);
                        }}
                        hasError={docuItemHasError(
                            new Set(documentController.errorDocuItemsWithQuestion),
                            documentationItem.id
                        )}
                    />
                ))
            )}
        </S.View>
    );
};

export default observer(ColDocumentationItems);

//#region styles
const S = {
    View: styled.div`
        .accordion & {
            /* otherwise Dropdown menu gets cropped when too little items */
            height: 100%;
        }
    `,
    Header: styled.div`
        /* has to be same height as the other cols, so Content is aligned at same height */
        height: ${unitize(136)};
    `,
    SelectMultipleControl: styled.div`
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 ${unitize(10)} ${unitize(7)} ${unitize(10)};
    `,
    Checkbox: styled.div<{ disabled: boolean }>`
        ${(props) =>
            props.disabled &&
            css`
                opacity: 0.6;
                pointer-events: none;
            `}
        span {
            margin-left: ${unitize(33)};
        }
    `,
    Dropdown: styled.div`
        position: relative;
        right: ${unitize(4)};
    `,
    Placeholder: styled.p`
        padding: 0 ${unitize(15)};
    `,
    Title: styled.div`
        display: flex;
        align-items: center;
        margin-bottom: ${unitize(18)};

        h3 {
            margin-left: ${unitize(15)};
        }
    `,
};
//#endregion styles
