import { FormikHelpers } from "formik";
import { action, makeObservable, observable } from "mobx";
import { ApiResponse } from "../../api/ApiService";
import { fetchTypes, ParamObject } from "../../api/Connection";
import { config } from "../../config/config";
import { IBuildingCreate, IBuildingRead } from "../../entities/Building";
import { ForbiddenError, NotFoundError } from "../../entities/ErrorResponse";
import { buildingService } from "../../services/BuildingService";
import { dataLayerService } from "../../services/DataLayerService";
import { transferKeyService } from "../../services/TransferKeyService";
import { session } from "../../session/Session";
import { Sort, SuperControllerStore } from "../../stores/controller/SuperControllerStore";
import { isDefined } from "../../utils/isDefined";
import { FormValues } from "../Building/subviews/ModalApplyObject";

type RequestParams = {
    searchValue?: string;
    sort?: Sort;
    itemsPerPage?: number;
    page?: number;
};
type WaitingFor = "delete" | "create" | "load" | "reload" | "redeem";

export class BuildingListController extends SuperControllerStore<WaitingFor> {
    static controllerName = "BuildingListController";

    allBuildingsLoaded = false;
    accountHasNoBuildings?: boolean;
    page = 0;
    itemsPerPage = config.PAGINATION_ITEMS_PER_PAGE;

    constructor() {
        super(session, {
            all: false,
            create: false,
            delete: false,
            load: false,
            reload: false,
            redeem: false,
        });

        makeObservable(this, {
            allBuildingsLoaded: observable,
            accountHasNoBuildings: observable,
            page: observable,
            itemsPerPage: observable,
            create: action,
            delete: action,
        });
    }

    init(props: { searchValue?: string; sort?: Sort; page?: number }): void {
        this.load(props, false);
        dataLayerService.emitHistory(location, dataLayerService.dataLayer, "buildings");
    }

    search(searchValue: string, onDone?: () => void): void {
        this.load({ searchValue, page: 1 }, false).then(onDone).catch(onDone);
    }

    load(
        params?: RequestParams,
        append = true,
        countPagination = true
    ): Promise<ApiResponse<IBuildingRead[], fetchTypes.fetchHydra>> {
        const itemsPerPage =
            params?.itemsPerPage ?? (this.itemsPerPage > 0 ? this.itemsPerPage : config.PAGINATION_ITEMS_PER_PAGE);
        const hasSearchValue = isDefined(params?.searchValue) && params?.searchValue !== "";
        const sort = params?.sort;

        this.page = params?.page ?? this.page;

        const query: ParamObject = {
            isActive: 1,
            pagination: true,
            items: `${itemsPerPage}`,
            page: `${this.page}`,
        };

        if (hasSearchValue) {
            query["search"] = `${params?.searchValue}`;
        }
        if (isDefined(sort)) {
            query[`order[${sort.property}]`] = `${sort.order.toLocaleLowerCase()}`;
        } else {
            query["order[createdAt]"] = `desc`;
        }

        return this.resolveAsAction({
            promise: () => buildingService.fetchList(query, !append),
            waitingForKey: [append ? "reload" : "load"],
            action: (result) => {
                const member = result.result?.["hydra:member"];
                const totalItems = result.result?.["hydra:totalItems"] ?? 0;
                if (!isDefined(member)) {
                    return result;
                }

                if (this.page === 1 && buildingService.length === 0) {
                    this.accountHasNoBuildings = true;
                }
                this.allBuildingsLoaded = this.page > 1 && member.length === 0;

                if (countPagination) {
                    this.page = this.page + 1;
                }

                this.accountHasNoBuildings = totalItems === 0;

                return result;
            },
        });
    }

    create(body: IBuildingCreate): Promise<ApiResponse<IBuildingRead>> {
        // optimistic ui, do it like it worked before the refactoring.
        this.accountHasNoBuildings = false;
        return this.resolveAsAction({
            promise: () => buildingService.create(body),
            waitingForKey: ["create"],
        });
    }

    delete(building: IBuildingRead, searchValue?: string, sort?: Sort): Promise<ApiResponse<never>> | void {
        if (building.amountAssemblies > 0 || building.amountMaintenances > 0) {
            return this.session.addNotification({
                title: "Objekt kann nicht gelöscht werden",
                description: "Das Objekt enthält noch Dachflächen.",
                key: new Date().getTime().toString(),
                type: "error",
            });
        }
        return this.resolveAsAction({
            promise: () => buildingService.deactivate(building.id),
            waitingForKey: ["delete"],
            action: (result) => {
                if (result.response?.ok !== undefined) {
                    this.load({ searchValue, sort, itemsPerPage: 1 }, true, false);
                }
                return result;
            },
        });
    }

    redeemTransferKey(transferKey: string, onCloseClick: () => void, helpers: FormikHelpers<FormValues>): void {
        transferKeyService
            .redeem(transferKey)
            .then((res) => {
                if (isDefined(res.result)) {
                    const buildingId = parseInt(res.result.building.replace(/\D/g, ""));
                    buildingService.fetch(buildingId);
                    return onCloseClick();
                } else {
                    return null;
                }
            })
            .catch((error) => {
                if (error instanceof NotFoundError) {
                    helpers.setErrors({
                        transferKey: "Dieser Transfer-Schlüssel ist uns nicht bekannt",
                    });
                } else if (error instanceof ForbiddenError) {
                    this.session.addNotification({
                        title: "Transfer-Schlüssel",
                        description: "Dieser Transfer-Schlüssel ist nicht mehr gültig",
                        key: new Date().getTime().toString(),
                        type: "error",
                    });
                } else
                    this.session.addNotification({
                        title: "Transfer-Schlüssel",
                        description: "Dieser Transfer-Schlüssel ist uns nicht bekannt",
                        key: new Date().getTime().toString(),
                        type: "error",
                    });
            });
    }
}
