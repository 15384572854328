import { makeObservable, observable, runInAction } from "mobx";
import { UnauthorizedError } from "../entities/ErrorResponse";
import { isBoolean } from "../utils/isBoolean";

/**
 * `waitingFor` properties are usually a boolean, but can be also:
 * - number: in case we want to know which (id) item we are waiting for
 * - number[]: in case we are waiting for multiple ids
 */
export type WaitingForValue = number | boolean | number[];

export type WaitingForBag<K extends string> = {
    [P in K]: WaitingForValue;
};

export abstract class SuperStore<WaitingForKey extends string> {
    readonly name: string;
    waitingFor = { all: false } as WaitingForBag<WaitingForKey | "all">;

    protected constructor(waitingFor: WaitingForBag<WaitingForKey | "all">) {
        makeObservable(this, {
            waitingFor: observable,
        });

        this.name = this.constructor.name;

        runInAction(() => {
            this.waitingFor = { ...this.waitingFor, ...waitingFor };
        });
    }

    /**
     * helper to check if some waitingFor of key is "active" (non-boolean).
     * If you need to know it more specific, then check directly the waitingFor value (e.g. waitingFor.loadItem === 3)
     *
     * @param key waitingForKey to check
     */
    isWaitingFor(key: WaitingForKey): boolean {
        const waitingForVal = this.waitingFor[key];

        if (isBoolean(waitingForVal)) {
            return waitingForVal;
        }

        if (Array.isArray(waitingForVal)) {
            return waitingForVal.length > 0;
        }

        if (Number.isInteger(waitingForVal as unknown as number)) {
            return true;
        }

        throw Error("value has an unexpected type. It's no boolean, Array, nor an Integer");
    }

    async resolveAsAction<ActionResponse>(params: {
        promise: () => Promise<ActionResponse>;
        waitingForKey: WaitingForKey | WaitingForKey[];
        setWaitingForValueTo?: WaitingForValue;
        action?: (result: ActionResponse) => ActionResponse | Promise<ActionResponse>;
    }): Promise<ActionResponse> {
        let finished = false;
        this.setWaitingFor(params.waitingForKey, params.setWaitingForValueTo);

        const resolved = await params
            .promise()
            .then(async (result) => {
                if (params.action !== undefined) {
                    const action = params.action;
                    return await runInAction(async () => {
                        const response = await action(result);
                        this.setWaitingFor(params.waitingForKey, false);
                        finished = true;
                        return response;
                    });
                }
                return result;
            })
            .catch(async (e) => {
                if (e instanceof UnauthorizedError) {
                    window.location.assign(`${process.env.REACT_APP_HUB_URL}/logout?redirect=${window.location.href}`);
                }

                this.setWaitingFor(params.waitingForKey, false);
                finished = true;
                throw e;
            });

        // finish definitely not truthy
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        !finished && this.setWaitingFor(params.waitingForKey, false);
        return resolved;
    }

    runAction<Result>(callback: () => Result): Result {
        return runInAction(callback);
    }

    protected setWaitingFor(key: WaitingForKey | WaitingForKey[], val?: WaitingForValue): void {
        runInAction(() => {
            val = val === undefined ? true : val;
            if (this.waitingFor === false) {
                this.waitingFor = {} as WaitingForBag<WaitingForKey | "all">;
                return;
            }

            if (!Array.isArray(key)) {
                key = [key];
            }

            for (let i = 0; i < key.length; i++) {
                this.waitingFor[key[i]] = val;
            }
            this.waitingFor.all = val;
        });
    }
}
