import { Button, SearchBar } from "@abs-safety/lock-book-web-ui";
import { autorun } from "mobx";
import { observer } from "mobx-react";
import React, { FunctionComponent, useCallback, useEffect, useState } from "react";
import { ArticleType, IArticleRead } from "../../../entities/Article";
import { articleService } from "../../../services/ArticleService";
import { getController } from "../../../stores/controller/ControllerFactory";
import { addOrDeleteFromSet } from "../../../utils/addOrDeleteFromSet";
import { isDefined } from "../../../utils/isDefined";
import { isNullish } from "../../../utils/isNullish";
import { AddArticleController, SubViewKey } from "../AddArticleController";
import { filterArticleByCategory } from "../utils/filterArticleByCategory";
import { filterArticleByType } from "../utils/filterArticleByType";
import { filterArticlesByArticleVisibility } from "../utils/filterArticlesByArticleVisibility";
import { fuzzySearchArticles } from "../utils/fuzzySearchArticles";
import { sortArticlesByName } from "../utils/sortArticlesByName";
import ArticleTypeFilter from "./ArticleTypeFilter";
import CardArticle from "./CardArticle";
import CategoryFilter from "./CategoryFilter";
import Step1ContentWhenNoArticles from "./Step1ContentWhenNoArticles";
import Step1Template from "./Step1Template";

interface Step1NewArticlesProps {
    articles?: IArticleRead[];
    searchValue: string;
    onSearchChange: (value: string) => void;
    onCloseClick: () => void;
}

const Step1NewArticles: FunctionComponent<Step1NewArticlesProps> = (props: Step1NewArticlesProps) => {
    const { controller: modalController } = getController(AddArticleController);
    const [articleSelected, setArticleSelected] = useState<IArticleRead | undefined>(undefined);
    const [articlesVisible, setArticlesVisible] = useState<IArticleRead[]>([]);
    const [selectedAmount, setSelectedAmount] = useState(1);
    const [activeArticleTypeFilter, setActiveArticleTypeFilter] = useState(new Set<ArticleType>());

    // update articlesVisible (depending on filter, search, subView Tab)
    useEffect(() => {
        return autorun(() => {
            if (props.articles === undefined) return;

            // clone
            let articles = props.articles.slice(0);

            switch (modalController.subView) {
                case SubViewKey.FallProtection:
                    articles = articles.filter((a) => (["abs", "thirdparty"] as ArticleType[]).includes(a.type));
                    articles = filterArticleByType(articles, activeArticleTypeFilter);
                    break;
                case SubViewKey.Custom:
                    articles = articles.filter((a) => a.type === "custom");
                    break;
                case SubViewKey.Misc:
                    articles = articles.filter((a) => a.type === "misc");
                    break;
                case SubViewKey.Climb:
                    articles = articles.filter((a) => a.type === "climb");
                    break;
                case SubViewKey.Drainage:
                    articles = articles.filter((a) => a.type === "drainage");
                    break;
                case SubViewKey.Ventilation:
                    articles = articles.filter((a) => a.type === "ventilation");
                    break;

                default:
                    throw Error(`subView had unexpected value: ${modalController.subView}`);
            }

            articles = filterArticleByCategory(articles, modalController.categoryFilter);
            articles = sortArticlesByName(articles);
            articles = fuzzySearchArticles(articles, props.searchValue);
            if (modalController.isInPage === "documentation") {
                articles = filterArticlesByArticleVisibility(
                    articles,
                    articleService.articlesVisibility,
                    modalController.documentationType
                );
            }
            setArticlesVisible(articles);
        });
    }, [props.articles, props.searchValue, modalController, activeArticleTypeFilter]);

    const onArticleSelectedChange = useCallback(
        (articleId: number) => {
            if (props.articles === undefined) return;
            const article = props.articles.find((article) => article.id === articleId);
            if (article?.id === articleSelected?.id) {
                setArticleSelected(undefined);
            } else {
                setSelectedAmount(1);
                setArticleSelected(article);
            }
            if (isNullish(articleId)) {
                throw Error("onArticleSelected() called with articleId which wasn't found in visibleArticles.");
            }
        },
        [props.articles, articleSelected]
    );

    const onArticleTypeFilterChange = (type: ArticleType, checked: boolean) => {
        setActiveArticleTypeFilter((_oldState) => addOrDeleteFromSet(_oldState, type, checked ? "add" : "delete"));
    };

    /**
     * how many installedArticles of a certain article exist in buildingArea
     */
    const getInstalledArticlesAmount = useCallback(
        (articleId: number) => {
            if (props.articles === undefined) return 0;

            return (
                modalController.buildingAreaArticleOverview.find((a) => a.id === articleId)?.installedArticles.length ??
                0
            );
        },
        [props.articles, modalController.buildingAreaArticleOverview]
    );

    return (
        <Step1Template
            articlesLoading={
                articleService.waitingFor.loadArticles === true ||
                modalController.waitingFor.loadBuildingAreaArticleOverview === true
            }
            list={
                articlesVisible.length < 1 ? (
                    <Step1ContentWhenNoArticles
                        searchIsActive={props.searchValue !== ""}
                        filterIsActive={activeArticleTypeFilter.size > 0}
                    />
                ) : (
                    <>
                        {articlesVisible.map((article) => (
                            <CardArticle
                                article={article}
                                amountTotal={getInstalledArticlesAmount(article.id)}
                                key={article.id}
                                showArticleCounter={isDefined(articleSelected) && articleSelected.id === article.id}
                                articleCount={articleSelected !== undefined ? selectedAmount : 1}
                                whenArticleCountChanges={setSelectedAmount}
                                expanded={isDefined(articleSelected) && articleSelected.id === article.id}
                                whenExpandedChanges={() => onArticleSelectedChange(article.id)}
                                firstItem={article.id === articlesVisible[0].id}
                                reloadUrl={() => articleService.fetchArticles(true)}
                                waitingForReloadUrl={articleService.waitingFor.reloadUrl}
                                displayCheckbox={false}
                                whenSelectChanges={() => console.log("add add whenSelectChanges Step1NewArticle")}
                            />
                        ))}
                    </>
                )
            }
            search={
                <SearchBar
                    placeholder="Produkt suchen"
                    value={props.searchValue}
                    onChange={(value) => props.onSearchChange(value)}
                />
            }
            filter={
                <>
                    {modalController.subView === SubViewKey.FallProtection && (
                        <ArticleTypeFilter
                            onCheckboxChange={onArticleTypeFilterChange}
                            activeArticleTypeFilter={activeArticleTypeFilter}
                            hideCustom={true}
                            hideMisc={true}
                            hideClimb={true}
                            hideDrainage={true}
                            hideVentilation={true}
                        />
                    )}
                    <CategoryFilter />
                </>
            }
            footer={
                <>
                    <Button disabled={articleSelected === undefined}>
                        <button
                            className={"uf-addArticleNext"}
                            type="button"
                            onClick={() => modalController.chooseArticle(articleSelected?.id, selectedAmount)}
                        >
                            Weiter
                        </button>
                    </Button>
                    <Button variant="text" color="black">
                        <button type="button" onClick={props.onCloseClick}>
                            Abbrechen
                        </button>
                    </Button>
                </>
            }
        />
    );
};

export default observer(Step1NewArticles);
