import {
    ButtonSwitch,
    Card,
    ColumnSizes,
    designTheme,
    GridColumns,
    IconArrowRotation,
    IconProduct,
    unitize,
} from "@abs-safety/lock-book-web-ui";
import { observer } from "mobx-react";
import React, { FunctionComponent, useState } from "react";
import styled from "styled-components";
import { IArticleOverviewItem } from "../../../api/ArticleApi";
import PresignedImage from "../../../components/PresignedImage";
import { getController } from "../../../stores/controller/ControllerFactory";
import { isNotEmptyString } from "../../../utils/isNotEmptyString";
import { isNullish } from "../../../utils/isNullish";
import { AreaController } from "../AreaController";
import InstalledArticlesList from "./InstalledArticlesList";

interface CardArticleProps {
    article: IArticleOverviewItem;
    columnSizes: ColumnSizes;
    firstItem?: boolean;
}

const CardArticle: FunctionComponent<CardArticleProps> = (props: CardArticleProps) => {
    const { controller } = getController(AreaController);
    //#region states

    const [isExpanded, setIsExpanded] = useState(false);

    //#endregion states

    //#region render
    return (
        <S.Component>
            <Card className={"uf-documentationCard"}>
                <GridColumns
                    columnSizes={props.columnSizes}
                    childrensDisplay="flex"
                    style={{ alignItems: "center", height: unitize(142) }}
                >
                    {/* Column */}
                    <S.Col1>
                        <S.ImageWrapper className="d-xs-none d-sm-flex">
                            {isNotEmptyString(props.article.imageUrl) ? (
                                <PresignedImage
                                    url={props.article.imageUrl}
                                    reloadUrl={() => controller.loadArticles(true)}
                                    waitingForValue={controller.waitingFor.reloadUrlArticles}
                                    style={{ height: 122, width: 122 }}
                                    disableRefresh={isNullish(props.firstItem)}
                                />
                            ) : (
                                <IconProduct color={designTheme.color.darkgrey} />
                            )}
                        </S.ImageWrapper>
                        <S.TitleWrapper>
                            <h4>{props.article.name}</h4>
                            <p>{props.article.number}</p>
                        </S.TitleWrapper>
                    </S.Col1>

                    {/* Column */}
                    <S.ColAmount>
                        <h4>
                            {
                                // WAITFOR API: responses should not include deleted and inactive services
                                props.article.installedArticles.filter(
                                    (installedArticle) => !(installedArticle.isDeleted ?? false)
                                ).length
                            }
                        </h4>

                        <S.ColButton>
                            <ResponsiveExpandToggleButton
                                isExpanded={isExpanded}
                                onToggle={() => setIsExpanded((isExpanded) => !isExpanded)}
                            />
                        </S.ColButton>
                    </S.ColAmount>
                </GridColumns>
            </Card>
            {isExpanded && <InstalledArticlesList article={props.article} />}
        </S.Component>
    );

    //#endregion render
};

export default observer(CardArticle);

interface ResponsiveExpandToggleButtonProps {
    isExpanded: boolean;
    onToggle: () => void;
}
export const ResponsiveExpandToggleButton: FunctionComponent<ResponsiveExpandToggleButtonProps> = (
    props: ResponsiveExpandToggleButtonProps
) => (
    <>
        <S.MobileButtonSwitch className="d-xs-flex d-md-none" onClick={props.onToggle}>
            <IconArrowRotation rotate={props.isExpanded} color="currentColor" />
        </S.MobileButtonSwitch>

        <div className="d-xs-none d-md-inline-block">
            <ButtonSwitch
                onToggle={props.onToggle}
                isOpen={props.isExpanded}
                textClosed="Alle anzeigen"
                textOpened="Keine Anzeigen"
            />
        </div>
    </>
);

//#region styles
const S = {
    Component: styled.div`
        margin-bottom: ${unitize(10)};
    `,
    CardIcon: styled.div`
        position: absolute;
        left: ${unitize(10)};
        top: ${unitize(10)};
    `,
    Col1: styled.div`
        margin-left: ${unitize(20)};
        display: flex;
        align-items: center;
        column-gap: ${unitize(20)};
    `,
    ImageWrapper: styled.div`
        width: ${unitize(122)};
        height: ${unitize(122)};
        align-items: center;
        justify-content: center;
        img {
            max-width: 100%;
            max-height: 100%;
        }
        svg {
            width: 100%;
            height: 100%;
        }
    `,
    TitleWrapper: styled.div`
        p {
            font-size: ${unitize(14)};
        }
    `,
    ColAmount: styled.div`
        display: flex;
        align-items: center;
        justify-content: space-between;
        p {
            font-size: ${unitize(14)};
        }
    `,
    UserIcon: styled.div`
        margin-right: ${unitize(10)};
    `,
    ColButton: styled.div`
        padding-right: ${unitize(30)};
        display: flex;
        justify-content: flex-end;
        align-items: center;
    `,
    MobileButtonSwitch: styled.div`
        cursor: pointer;
        border: 1px solid ${designTheme.color.primary};
        color: ${designTheme.color.primary};

        @media (hover: hover) {
            &:hover {
                background-color: ${designTheme.color.primary};
                color: white;
            }
        }
        align-items: center;
        justify-content: center;
        height: 3rem;
        width: 3rem;
        border-radius: 1.5rem;
        > div {
            margin: 0;
        }
    `,
};
//#endregion styles
