import { MainNavigation } from "@abs-safety/lock-book-web-ui";
import React, { FunctionComponent } from "react";
import EditDetails from "./EditDetails";

export interface EditModalProps {
    buildingAreaId: number;
}

const Edit: FunctionComponent<EditModalProps> = (props: EditModalProps) => {
    return (
        <>
            <MainNavigation items={[{ id: "tab__details", text: "Details zur Dachfläche" }]} small />
            <EditDetails buildingAreaId={props.buildingAreaId} />
        </>
    );
};

export default Edit;
