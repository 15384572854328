import { RepoStore } from "../stores/RepoStore";
import { IUserRead } from "./User";

type User = IUserRead;

class UserPropsRepo extends RepoStore<IUserPropsRead> {}

export const userPropsRepo = new UserPropsRepo();
export interface IUserPropsBase {
    id: number;
    survey_documented_before?: string;
    referrer?: string;
    landing_page?: string;
    survey_documented_before_updatedAt?: Date;
    referrer_updatedAt?: Date;
    landing_page_updatedAt?: Date;
    user: User;
}

export type IUserPropsWrite = Pick<IUserPropsBase, "survey_documented_before" | "survey_documented_before_updatedAt">;
export type IUserPropsRead = Pick<IUserPropsBase, "survey_documented_before" | "referrer" | "user" | "id">;
