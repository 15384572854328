import { computed, makeObservable, observable, reaction, runInAction } from "mobx";
import React from "react";
import TagManager from "react-gtm-module";
import { IBuildingRead } from "../entities/Building";
import { IBuildingAreaRead } from "../entities/BuildingArea";
import { ICompanyRead } from "../entities/Company";
import { DocumentType } from "../entities/Document";
import { DocumentationType, IDocumentationRead } from "../entities/Documentation";
import { IPageTypes, session } from "../session/Session";
import { isDefined } from "../utils/isDefined";
import { areaService } from "./AreaService";
import { buildingService } from "./BuildingService";
import { documentationService } from "./DocumentationService";

export interface UserData {
    firstname?: string;
    lastname?: string;
    username?: string;
    userId?: number;
    lang?: string;
    hash?: string;
    company?: ICompanyRead;
    created?: Date | null;
    userflowSignature?: string;
    landingPage?: string | null;
    referrer?: string | null;
}

export type LockBookEvents =
    | "click"
    | "push"
    | "search"
    | "event"
    | "history"
    | "error"
    | "init"
    | "input"
    | "submit"
    | "conversion";

export type AddArticleActions =
    | "assign_article_type: abs"
    | "assign_article_type: custom"
    | "assign_article_type: thirdparty"
    | "assign_article_type: misc"
    | "assign_article_type: climb"
    | "assign_article_type: drainage"
    | "assign_article_type: ventilation";

export type DataLayerActions =
    | "push"
    | "event"
    | "outbound"
    | "error"
    | "page_error"
    | "click"
    | "input"
    | "search"
    | "submit"
    | "edit"
    | "navigate"
    | "init"
    | "answer_questions"
    | "submit_docuitem"
    | "edit_docuitem"
    | "lock_docuitem"
    | "release_docuitem"
    | "open_pdf"
    | "create_pdf"
    | "create_pdf-sharing"
    | "copy_pdf-sharing"
    | "unlock_documentation"
    | "complete_documentation"
    | AddArticleActions
    | "add_articles"
    | "drag_attachment_cancel"
    | "drag_attachment_sort"
    | "drag_attachment_start"
    | "maintenance_offer_poll"
    | "pre_docu_survey"
    | "next_maintenance_calendar"
    | "next_maintenance_offer_step1"
    | "next_maintenance_offer_step2"
    | "open_documentation_side_modal";
export interface EventData {
    category: PageNamespaces;
    action: DataLayerActions;
    label?: string | null;
    payload?: unknown | null;
}

interface PageData {
    location: string;
    params: string;
    category: PageNamespaces;
    building: Pick<IBuildingRead, "id" | "name" | "createdAt" | "updatedAt"> | null;
    area: Pick<IBuildingAreaRead, "id" | "name" | "createdAt" | "updatedAt"> | null;
    documentation: Pick<IDocumentationRead, "id" | "name" | "type" | "createdAt" | "updatedAt"> | null;
    currentReferrer: string;
}

export interface IDataLayer {
    env?: string;
    event?: LockBookEvents;
    userData?: UserData;
    eventData?: EventData;
    pageData?: PageData;
}

type DebounceObject = {
    triggered: number;
    timeout?: ReturnType<typeof setTimeout>;
    waitForMs: number;
};
type DebounceObjects = Record<LockBookEvents, DebounceObject>;

const getTime = () => new Date().getTime();

export const onOutbound = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, payload?: string): void => {
    e.preventDefault();
    const url = e.currentTarget.href;
    dataLayerService.emitOutbound({ label: url, payload }, () => window.open(url, "_blank"));
};

export type ModalPages =
    | "assembly | add-available-article"
    | "assembly | add-article"
    | "maintenance | add-available-article"
    | "maintenance | add-article"
    | "maintenance | add-deliveryNote"
    | "documentation | add-article"
    | "documentation | add-available-article"
    | "area | add-article";
export type PageNamespaces = IPageTypes | ModalPages;

export class DataLayerService {
    public static debounces: DebounceObjects = {
        click: {
            triggered: getTime(),
            waitForMs: 50,
        },
        push: {
            triggered: getTime(),
            waitForMs: 500,
        },
        search: {
            triggered: getTime(),
            waitForMs: 1000,
        },
        event: {
            triggered: getTime(),
            waitForMs: 0,
        },
        history: {
            triggered: getTime(),
            waitForMs: 500,
        },
        error: {
            triggered: getTime(),
            waitForMs: 300,
        },
        init: {
            triggered: getTime(),
            waitForMs: 50,
        },
        input: {
            triggered: getTime(),
            waitForMs: 1000,
        },
        submit: {
            triggered: getTime(),
            waitForMs: 1500,
        },
        conversion: {
            triggered: getTime(),
            waitForMs: 500,
        },
    };
    initialized = false;
    historyTracking = false;
    referrer = document.referrer === "" ? "blank" : document.referrer;
    constructor() {
        makeObservable<DataLayerService, "_lastBuildingId" | "_lastAreaId" | "_lastDocumentationId">(this, {
            dataLayer: computed,
            _lastBuildingId: observable,
            _lastAreaId: observable,
            _lastDocumentationId: observable,
        });
    }

    private _location?: string;

    get location(): string {
        return this._location ?? "";
    }

    set location(value: string) {
        this._location = value;
    }

    private _currentPage?: PageNamespaces;

    get currentPage(): PageNamespaces {
        return this._currentPage ?? "init";
    }

    private _lastBuildingId: number | null = null;

    get lastBuildingId(): number | null {
        return this._lastBuildingId;
    }

    set lastBuildingId(value: number | null) {
        runInAction(() => {
            this._lastBuildingId = value;
        });
    }

    private _lastAreaId: number | null = null;

    get lastAreaId(): number | null {
        return this._lastAreaId;
    }

    set lastAreaId(value: number | null) {
        runInAction(() => {
            this._lastAreaId = value;
        });
    }

    private _lastDocumentationId: number | null = null;

    get lastDocumentationId(): number | null {
        return this._lastDocumentationId;
    }

    set lastDocumentationId(value: number | null) {
        runInAction(() => {
            this._lastDocumentationId = value;
        });
    }

    get dataLayer(): IDataLayer {
        const buildingRead = isDefined(this._lastBuildingId) ? buildingService.get(this._lastBuildingId) : null;
        const areaRead = isDefined(this._lastAreaId) ? areaService.get(this._lastAreaId) : null;
        const areaName = areaRead?.name;
        const documentationRead = isDefined(this._lastDocumentationId)
            ? documentationService.get(this._lastDocumentationId)
            : null;
        const docuType = documentationRead?.type;

        const building: PageData["building"] | null = isDefined(this._lastBuildingId)
            ? {
                  id: this._lastBuildingId,
                  name: buildingRead?.name,
                  createdAt: buildingRead?.createdAt,
                  updatedAt: buildingRead?.updatedAt,
              }
            : null;

        const area: PageData["area"] | null =
            isDefined(this._lastAreaId) && isDefined(areaName)
                ? {
                      id: this._lastAreaId,
                      name: areaName,
                      createdAt: areaRead?.createdAt ?? null,
                      updatedAt: areaRead?.updatedAt ?? null,
                  }
                : null;

        const documentation: PageData["documentation"] | null =
            isDefined(this._lastDocumentationId) && isDefined(docuType)
                ? {
                      id: this._lastDocumentationId,
                      name: documentationRead?.name,
                      createdAt: documentationRead?.createdAt,
                      updatedAt: documentationRead?.updatedAt,
                      type: docuType,
                  }
                : null;

        return {
            env: process.env.REACT_APP_ENVIRONMENT ?? "prod",
            userData: {
                username: session.currentUser?.username,
                firstname: session.currentUser?.firstName,
                lastname: session.currentUser?.lastName,
                userId: session.currentUser?.id,
                lang: session.locale,
                hash: session.currentUserHash,
                company: session.currentUser?.company,
                created: session.currentUser?.createdAt,
                userflowSignature: session.currentUser?.userflowSignature,
                landingPage: session.currentUser?.userProps.landingPage,
                referrer: session.currentUser?.userProps.referrer,
            },
            pageData: {
                location: window.location.href,
                params: window.location.search,
                currentReferrer: this.referrer,
                category: this.currentPage,
                building:
                    ["building", "area", "documentation", "maintenance", "assembly", "document"].find(
                        (cat) => cat === this.currentPage
                    ) !== undefined
                        ? building
                        : null,
                area:
                    ["area", "documentation", "maintenance", "assembly", "document"].find(
                        (cat) => cat === this.currentPage
                    ) !== undefined
                        ? area
                        : null,
                documentation:
                    ["documentation", "maintenance", "assembly", "document"].find((cat) => cat === this.currentPage) !==
                    undefined
                        ? documentation
                        : null,
            },
        };
    }

    static send(event: LockBookEvents = "push", data: IDataLayer): void {
        TagManager.dataLayer({ dataLayer: { ...data, event: `lb.${event}` } });
    }

    init(gtmKey: string): void {
        if (this.initialized) {
            return;
        }

        if (gtmKey === "") {
            return;
        }

        const tagManagerArgs = {
            gtmId: gtmKey,
            dataLayer: this.dataLayer,
        };

        TagManager.initialize(tagManagerArgs);

        // todo it works but test this / make this testable
        const dispose = reaction(
            () => {
                return { userId: this.dataLayer.userData?.userId, page: this.dataLayer.pageData?.category };
            },
            (data) => {
                if (data.userId !== undefined && data.page !== "init" && !this.initialized) {
                    this.location = window.location.href;
                    this.push("init", {
                        userData: this.dataLayer.userData,
                        pageData: this.dataLayer.pageData,
                        eventData: {
                            category: this.currentPage,
                            action: "init",
                            label: window.location.href,
                        },
                    });
                    this.initialized = true;
                    dispose();
                }
            }
        );
    }

    /**
     * waits for page data are fully loaded
     */
    trackHistory(): void {
        let fired = false;
        if (!this.historyTracking) {
            reaction(
                () => this.dataLayer.pageData,
                (data) => {
                    this.emitHistory(window.location, { pageData: data }, this.currentPage);
                    fired = true;
                }
            );

            // isn't always truthy
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            if (!fired) {
                this.emitHistory(window.location, { pageData: this.dataLayer.pageData }, this.currentPage);
            }
            this.historyTracking = true;
        }
    }

    // todo use Debouncer.collect method - refactoring required
    async debounce(type: LockBookEvents, action: (type: LockBookEvents) => void): Promise<boolean> {
        const debounceData = DataLayerService.debounces[type];
        const now = new Date().getTime();
        debounceData.timeout !== undefined && clearTimeout(debounceData.timeout);

        return new Promise((resolve) => {
            debounceData.timeout = setTimeout(() => {
                if (now - debounceData.triggered <= debounceData.waitForMs) {
                    debounceData.triggered = now;
                    return resolve(false);
                }

                action(type);
                debounceData.triggered = now;
                return resolve(true);
            }, debounceData.waitForMs);
        });
    }

    async emitEvent(type: LockBookEvents, eventData: EventData): Promise<boolean> {
        return await this.push(type, { eventData });
    }

    async emitOutbound(data: Omit<EventData, "action" | "category">, cb?: () => void): Promise<void> {
        return await this.emitEvent("event", { ...data, action: "outbound", category: this.currentPage }).then(cb);
    }

    /**
     * @param location
     * @param data
     * @param currentPage
     */
    async emitHistory(location: Location, data: IDataLayer, currentPage: PageNamespaces): Promise<void> {
        const url = location.href;
        const last = `${this.location}`;
        if (url !== last || currentPage !== this.currentPage) {
            this._currentPage = currentPage;
            (await this.push("history", {
                ...data,
                eventData: {
                    category: currentPage,
                    action: "navigate",
                    label: url,
                    payload: last,
                },
            })) && (this.location = url);
        }
    }

    /**
     * @return triggered boolean
     * @param data
     */
    async emitInput(data: EventData): Promise<boolean> {
        return this.emitEvent("input", data);
    }

    /**
     * @return triggered boolean
     * @param data
     */
    async emitPush(data: IDataLayer): Promise<boolean> {
        return this.push("push", {
            ...data,
            eventData: {
                category: this.currentPage,
                action: "push",
            },
        });
    }

    /**
     * @return triggered boolean
     * @param data
     */
    async emitClick(data: EventData): Promise<boolean> {
        return this.emitEvent("click", data);
    }

    /**
     * @return triggered boolean
     * @param data
     */
    async emitOpenPdf(
        data: Omit<EventData, "action"> & { docuType: DocumentationType; documentType: DocumentType }
    ): Promise<boolean> {
        return this.emitEvent("click", {
            action: "open_pdf",
            ...data,
        });
    }

    /**
     * @return triggered boolean
     * @param data
     */
    async emitSearch(data: Omit<EventData, "action">): Promise<boolean> {
        return this.emitEvent("search", {
            ...data,
            action: "search",
            label: data.label
                ?.toLowerCase()
                .replace(/[^0-9a-zäöüß]/g, " ")
                .replace(/ {2}/g, ""),
        });
    }

    prepareEmit(type: LockBookEvents, eventData: Omit<EventData, "category">, cb?: () => void): () => Promise<boolean> {
        const category = `${this.currentPage}` as PageData["category"];

        return () =>
            this.emitEvent(type, { ...eventData, category }).then((res) => {
                isDefined(cb) && cb();
                return res;
            });
    }

    prepareSubmit(eventData: Omit<EventData, "category" | "action">, cb?: () => void): () => Promise<boolean> {
        return this.prepareEmit("submit", { ...eventData, action: "submit" }, cb);
    }

    private async push<T extends IDataLayer>(type: LockBookEvents, data: T): Promise<boolean> {
        return await this.debounce(type, (type) => DataLayerService.send(type, data));
    }
}

export const dataLayerService = new DataLayerService();
