import {
    Button,
    ButtonClose,
    Modal,
    ModalContent,
    ModalHeader,
    RadioButtonGroup,
    unitize,
} from "@abs-safety/lock-book-web-ui";
import InputField from "@abs-safety/lock-book-web-ui/dist/esm/components/form/Input/elements/InputField";
import { observer } from "mobx-react";
import React, { FunctionComponent, useCallback, useState } from "react";
import styled from "styled-components";
import { session } from "../../../session/Session";
import { getController } from "../../../stores/controller/ControllerFactory";
import { gtmTrackMaintenanceOfferPoll, gtmTrackMaintenanceOfferPollCancel } from "../../../utils/gtmEventCollection";
import { neverShowModalNextMaintenanceRefusalForDocu } from "../../../utils/neverShowModalNextMaintenanceRefusalForDocu";
import { DocumentationController } from "../DocumentationController";

type RadiobuttonValue = "already_agreed" | "not_interested" | "other";
interface ModalNextMaintenanceRefusalProps {
    onClose: () => void;
}

export const ModalNextMaintenanceRefusal: FunctionComponent<ModalNextMaintenanceRefusalProps> = (
    props: ModalNextMaintenanceRefusalProps
) => {
    const { controller } = getController(DocumentationController);
    const [radioValue, setRadioValue] = useState<RadiobuttonValue>();
    const [inputValue, setInputValue] = useState<string>("");

    const onSelectionChange = useCallback((value: RadiobuttonValue) => {
        setRadioValue(value);
    }, []);

    const onSubmitClick = () => {
        if (radioValue === undefined) {
            return;
        }
        if (radioValue === "other") {
            if (inputValue === "") {
                return;
            }
            submit();
            return;
        }
        submit();
    };

    const submit = () => {
        const valToSubmit = radioValue === "other" ? inputValue : radioValue;
        gtmTrackMaintenanceOfferPoll(valToSubmit ?? "something not tracked right");
        neverShowModalNextMaintenanceRefusalForDocu(controller.currentId);
        props.onClose();
        session.addNotification({
            title: `Danke!`,
            description: `Wir freuen uns über Dein Feedback!`,
            key: new Date().getTime().toString(),
            type: "success",
        });
    };

    const onClose = () => {
        gtmTrackMaintenanceOfferPollCancel();
        props.onClose();
    };

    return (
        <S.ModalWrapper className={"uf-modalOfferRefusal"}>
            <Modal isOpen size="sm" style={{ paddingTop: `${unitize(50)}` }} onCloseClick={onClose}>
                <S.ButtonClose>
                    <ButtonClose onClick={onClose} />
                </S.ButtonClose>
                <ModalHeader title="Warum hast Du keine der Optionen ausgewählt?" />
                <ModalContent>
                    <S.RadioButtons>
                        <RadioButtonGroup
                            options={[
                                {
                                    value: "not_interested",
                                    label: "Kein Interesse an Wartungen",
                                },
                                {
                                    value: "already_agreed",
                                    label: "Wartung schon vereinbart",
                                },
                                {
                                    value: "other",
                                    label: "Anderer Grund",
                                },
                            ]}
                            name="MaintenanceOfferRefusal"
                            value={radioValue}
                            onChange={(e) => onSelectionChange(e.target.value as RadiobuttonValue)}
                            regularWeight={true}
                        />
                        {radioValue === "other" && (
                            <InputField
                                type="text"
                                placeholder="bitte eingeben"
                                value={inputValue}
                                onChange={(e) => setInputValue(e.target.value)}
                            />
                        )}
                    </S.RadioButtons>
                </ModalContent>

                <S.ButtonWrapper>
                    <Button className={"uf-offerRefusal"} variant="text" color="black">
                        <button type="button" onClick={onClose}>
                            Abbrechen
                        </button>
                    </Button>
                    <Button disabled={radioValue === undefined || (radioValue === "other" && inputValue === "")}>
                        <button className={"uf-offerRefusalReason"} type="submit" onClick={onSubmitClick}>
                            Antworten
                        </button>
                    </Button>
                </S.ButtonWrapper>
            </Modal>
        </S.ModalWrapper>
    );
};

export default observer(ModalNextMaintenanceRefusal);

//#region styles
const S = {
    ModalWrapper: styled.div`
        position: absolute;
        width: 100vw;
        height: 100vw;
        max-width: 100%;
        max-height: 100%;
        top: 0;
        left: 0;
    `,
    ButtonWrapper: styled.div`
        display: flex;
        justify-content: flex-end;
        flex-direction: row;
        gap: ${unitize(30)};
    `,
    RadioButtons: styled.div`
        text-align: left;
        margin: ${unitize(0)} ${unitize(24)};
        padding-bottom: ${unitize(10)};
    `,
    Checkbox: styled.div`
        text-align: left;
    `,
    ButtonClose: styled.div`
        position: absolute;
        right: ${unitize(30)};
        top: ${unitize(30)};
    `,
};
//#endregion styles
