import { FormikErrors } from "formik";
import { ConstraintViolationError } from "../entities/ErrorResponse";

type FormValues = { [key: string]: unknown };

/**
 * checks in API response error for constraint errors.
 * If found: pass them to Formik via `setErrors` param.
 * Otherwise throw error */
export function apiConstraintErrorsToFormErrors(
    error: unknown | ConstraintViolationError,
    setErrors: (errrors: FormikErrors<unknown>) => void
): void {
    if (error instanceof ConstraintViolationError) {
        const formErrors = parseApiError<FormValues>(error);
        setErrors(formErrors);
    } else throw error;
}

/**
 * pure function for apiConstraintErrorsToFormErrors. (Unit tested)
 */
export function parseApiError<T extends FormValues>(error: ConstraintViolationError): FormikErrors<T> {
    return Object.fromEntries(
        error.payload.violations.map((violation) => [violation.propertyPath, violation.message])
    ) as unknown as FormikErrors<T>;
}
