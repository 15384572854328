import { Checkbox, designTheme, IconCheck, IconCross, unitize } from "@abs-safety/lock-book-web-ui";
import { observer } from "mobx-react";
import React, { FunctionComponent, useRef, useState } from "react";
import styled, { css } from "styled-components";
import {
    IDocumentationItemDocumentationOverview,
    IDocumentationItemRead,
} from "../../../../entities/DocumentationItem";
import { getController } from "../../../../stores/controller/ControllerFactory";
import { getInstalledArticlePreviewName } from "../../../../utils/getInstalledArticlePreviewName";
import { isDefined } from "../../../../utils/isDefined";
import { isNotEmptyString } from "../../../../utils/isNotEmptyString";
import { isNullish } from "../../../../utils/isNullish";
import { DocumentController, SubViewKey } from "../DocumentController";

interface DocumentationItemProps {
    documentationItem: IDocumentationItemRead & IDocumentationItemDocumentationOverview;
    documented?: boolean;
    success?: boolean;
    locked?: boolean;
    /** if (isSelectable): gray backround with checked Checkbox; if (!isSelectable) only gray background */
    selected?: boolean;
    /** additional infos are shown at bottom (card gets higher) */
    open?: boolean;
    hasError: boolean;
    amountAttachments: number;
    onClick: (e: React.MouseEvent<HTMLElement>) => void;
    deleteClick?: () => void;
}

const DocumentationItem: FunctionComponent<DocumentationItemProps> = (props: DocumentationItemProps) => {
    const { controller: documentController } = getController(DocumentController);
    const optionsIconRef = useRef<HTMLDivElement>(null);
    const [isDropdownOpen] = useState(false);

    const onCardClick = (e: React.MouseEvent<HTMLElement>) => {
        if (optionsIconRef.current === null) {
            // this should actually never happen, that user clicks faster than React can initialize optionsIconRef...
            return;
        }

        if (e.target === optionsIconRef.current || optionsIconRef.current.contains(e.target as HTMLElement)) {
            // don't call onClick, when user clicked on Dropdown Options Icon (to open Dropdown)
            return;
        }

        if (isNullish(props.onClick)) {
            return;
        }
        props.onClick(e);
    };

    const onCheckboxClick = (documentationItemId: number, checked: boolean) => {
        if (checked) {
            const currentDocumentationItemIds: number[] = [...documentController.currentDocumentationItemIds];
            currentDocumentationItemIds.push(documentationItemId);

            if (currentDocumentationItemIds.length > 1) {
                documentController.setSubView(SubViewKey.Questions);
            }

            documentController.setCurrentDocumentationItemIds(currentDocumentationItemIds);
        } else {
            if (documentController.currentDocumentationItemIds.length === 1) return;
            documentController.setCurrentDocumentationItemIds([
                ...documentController.currentDocumentationItemIds.filter((id) => id !== documentationItemId),
            ]);
        }
    };

    const questionsAnswered =
        isDefined(props.documentationItem.questionsAnswered) && props.documentationItem.questionsAnswered;
    const success =
        isDefined(props.documentationItem.isSuccessful) && props.documentationItem.isSuccessful && questionsAnswered;
    const locked =
        isDefined(props.documentationItem.isSuccessful) && !props.documentationItem.isSuccessful && questionsAnswered;

    const { identificationNumber, identificationCounter } = props.documentationItem.installedArticle;

    const title = getInstalledArticlePreviewName(props.documentationItem.installedArticle);
    const subTitle =
        isNotEmptyString(identificationNumber) || isNotEmptyString(identificationCounter)
            ? `${identificationNumber}${identificationCounter}`
            : null;

    return (
        <S.DocumentationItem {...props} onClick={onCardClick}>
            {/* Column: Checkbox */}
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
            <span
                onClick={(e) => {
                    e.stopPropagation();
                }}
            >
                {/* stopPropagation, so it doesn't count as a card click  */}
                <S.CheckBoxExtendClickArea
                    checked={documentController.currentDocumentationItemIds.indexOf(props.documentationItem.id) !== -1}
                    onChange={(checked) => onCheckboxClick(props.documentationItem.id, checked)}
                    small={false}
                />
            </span>

            {/* Column: Text */}
            <S.TextWrapper>
                <h5>{title}</h5>
                {subTitle !== null && title !== subTitle && (
                    <S.Identification>
                        Kennung:{" "}
                        <S.IdentificationData>
                            {identificationNumber}
                            {identificationCounter}
                        </S.IdentificationData>
                    </S.Identification>
                )}
                <S.SubTitleText>
                    <span>
                        Status: <strong>{questionsAnswered ? (success ? "freigegeben" : "gesperrt") : "offen"}</strong>
                    </span>
                    <S.SubtitleDivider>|</S.SubtitleDivider>
                    <span>
                        Fotos: <strong>{props.amountAttachments}</strong>
                    </span>
                </S.SubTitleText>
                {props.open === true && (
                    <S.ExpandedContainer>
                        <S.ExpandedRow>
                            <S.ExpandedLabel>Seriennr.:</S.ExpandedLabel>{" "}
                            <strong>{props.documentationItem.installedArticle.serialNumber !== null || "-"}</strong>
                        </S.ExpandedRow>
                        <S.ExpandedRow>
                            <S.ExpandedLabel>Kennung:</S.ExpandedLabel>{" "}
                            <strong>
                                {props.documentationItem.installedArticle.identificationNumber !== null || "-"}
                            </strong>
                        </S.ExpandedRow>
                        <S.ExpandedRow>
                            <S.ExpandedLabel>App ID:</S.ExpandedLabel>{" "}
                            <strong>{props.documentationItem.installedArticle.appId !== 0 || "-"}</strong>
                        </S.ExpandedRow>
                    </S.ExpandedContainer>
                )}
            </S.TextWrapper>

            {/* Column: Icon Success|Locked|DotsMenu & Dropdown */}
            <IconWrapperStyle ref={optionsIconRef}>
                {(success || locked) && (
                    <S.SuccessLockedIcon isDropdownOpen={isDropdownOpen}>
                        {success && <IconCheck width={21} height={16} />}
                        {locked && <IconCross width={21} height={16} />}
                    </S.SuccessLockedIcon>
                )}
            </IconWrapperStyle>
        </S.DocumentationItem>
    );
};

export default observer(DocumentationItem);

//#region styles

const IconWrapperStyle = styled.div`
    align-self: center;
`;

const DropdownIconWrapperStyle = styled.span<{ isDropdownOpen: boolean }>`
    padding: ${unitize(4)};
    height: ${unitize(24)};
    align-items: center;
    display: ${(props) => (props.isDropdownOpen ? "inline-flex" : "none")};
`;

const S = {
    DocumentationItem: styled.div<DocumentationItemProps>`
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        cursor: pointer;
        background-color: ${(props) => (props.selected === true ? designTheme.color.lightgrey : "transparent")};
        padding: ${unitize(10)} ${unitize(15)};
        .accordion & {
            padding: ${unitize(10)};
        }

        @media (hover: hover) {
            &:hover {
                background-color: ${(props) =>
                    props.hasError ? designTheme.color.error + "70" : designTheme.color.lightgrey};

                ${DropdownIconWrapperStyle} {
                    /* show IconDotsMenu on Card hover (normally display: none) */
                    display: inline-flex;
                }

                ${IconWrapperStyle} > div:first-of-type {
                    /* hide success or locked icon on hover (because IconDotsMenu gets displayed instead) */
                    display: ${(props) => (props.success === true || props.locked) && "none"};
                }
            }
        }

        ${(props) =>
            props.hasError &&
            css`
                background-color: ${designTheme.color.error}50;
            `};

        ${(props) =>
            props.hasError &&
            props.selected &&
            css`
                background-color: ${designTheme.color.error}70;
            `}
    `,

    CheckBoxExtendClickArea: styled(Checkbox)`
        padding: ${unitize(15)};
        margin: ${unitize(-5)} ${unitize(-10)} 0 ${unitize(-15)};
        box-sizing: content-box;
    `,

    TextWrapper: styled.div`
        flex: 1;
        margin-left: ${unitize(10)};
    `,

    Identification: styled.span`
        margin-top: ${unitize(5)};
        font-size: ${unitize(14)};
        display: flex;
    `,

    IdentificationData: styled.p`
        font-weight: ${designTheme.typography.weight.bold};
        font-size: ${unitize(14)};
        margin-left: ${unitize(14)};
        line-height: inherit;
    `,

    SubTitleText: styled.p`
        display: inline-block;
        font-size: ${unitize(14)};
        margin-top: ${unitize(5)};
    `,

    SubtitleDivider: styled.span`
        margin: 0 ${unitize(10)};
    `,

    ExpandedContainer: styled.div`
        margin-top: ${unitize(10)};
    `,

    ExpandedRow: styled.p`
        margin-top: ${unitize(6)};
    `,

    ExpandedLabel: styled.span`
        margin-right: ${unitize(12)};
    `,

    // Column: Icon Succes
    SuccessLockedIcon: styled.div<{ isDropdownOpen: boolean }>`
        display: ${(props) => (props.isDropdownOpen ? "none" : "inline-flex")};
        align-items: center;
        margin-right: ${unitize(4)};
        margin-top: ${unitize(4)};
    `,
};

//#endregion styles
