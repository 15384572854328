/**
 * immutable way to add or delete from Set.
 * Meaning: a new instance of Set will be returned, where value is either added or deleted.
 */
export function addOrDeleteFromSet<T extends unknown>(set: Set<T>, value: T, operation: "delete" | "add"): Set<T> {
    const clone = new Set(set);
    if (operation === "add") {
        clone.add(value);
    } else {
        clone.delete(value);
    }
    return clone;
}
