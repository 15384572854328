import { unitize } from "@abs-safety/lock-book-web-ui";
import { when } from "mobx";
import React, { FunctionComponent, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import FieldLoading from "../../../components/FieldLoading";
import { IArticleAttachmentRead } from "../../../entities/ArticleAttachment";
import { IArticleQuestionRead, IArticleQuestionWrite } from "../../../entities/ArticleQuestion";
import { articleAttachmentService } from "../../../services/ArticleAttachmentService";
import { articleQuestionService } from "../../../services/ArticleQuestionService";
import { articleService } from "../../../services/ArticleService";
import { session } from "../../../session/Session";
import MainPage from "../../../templates/MainPage";
import { isDefined } from "../../../utils/isDefined";
import { useQuery } from "../../../utils/useQuery";
import ProductFormStep1, { FormValues as ProductFormValues, OnNextParams } from "./components/ProductFormStep1";
import ProductFormStep2 from "./components/ProductFormStep2";

export interface ProductFormPageProps {
    pageType: "create" | "edit";
}

/**
 * Page component for:
 *
 * - create new product
 * - edit existing product
 */
const ProductFormPage: FunctionComponent<ProductFormPageProps> = (props: ProductFormPageProps) => {
    const [formStep, setFormStep] = useState(1);
    const [productFormValues, setProductFormValues] = useState<ProductFormValues | undefined>();
    const [fetchedQuestions, setFetchedQuestions] = useState<IArticleQuestionRead[]>([]);

    const [newImage, setNewImage] = useState<File | undefined>();
    const [deleteFetchedImage, setDeleteFetchedImage] = useState(false);
    const [fetchedImageUrl, setFetchedImageUrl] = useState<string | undefined>();

    const [newAttachments, setNewAttachments] = useState<File[]>([]);
    const [fetchedAttachments, setFetchedAttachments] = useState<IArticleAttachmentRead[]>([]);
    const [attachmentsToDelete, setAttachmentsToDelete] = React.useState<number[]>([]);

    const history = useHistory();
    const query = useQuery();

    useEffect(() => {
        articleService.fetchArticlesIfNotYetFetched();
    }, []);

    /** if queryParam "id" in URL (when we are in edit mode): set states, load attachments, load questions */
    useEffect(
        () =>
            when(
                () => articleService.list.length !== 0,
                async () => {
                    const id = getArticleIdFromUrl();
                    if (id === undefined) {
                        return;
                    }
                    const article = articleService.get(id);
                    if (article === undefined) {
                        console.error(`article with id "${id}" not found`);
                        return;
                    }

                    setProductFormValues(article);
                    setFetchedImageUrl(article.imageUrl ?? undefined);

                    const questions = await articleQuestionService.getArticleQuestions(article.id);
                    const attachments = await articleAttachmentService.fetchArticleAttachments(article.id);

                    if (!isDefined(questions.result) || !isDefined(attachments.result)) {
                        return;
                    }
                    setFetchedQuestions(questions.result);
                    setFetchedAttachments(attachments.result);
                }
            ),
        []
    );

    const getArticleIdFromUrl = (): number | undefined => {
        const paramId = query.get("id");
        if (paramId === null) return;
        const parsed = parseInt(paramId);
        if (isNaN(parsed)) return;
        return parsed;
    };

    const verbCreateOrEdit = props.pageType === "create" ? "anlegen" : "bearbeiten";

    const createArticleWithQuestions = async (questions: IArticleQuestionWrite[]) => {
        if (productFormValues === undefined) {
            throw "productFormValues not defined";
        }

        // create article
        const resCreateArticle = await articleService.create({
            ...productFormValues,
            imageFile: newImage,
        });
        const articleId = (resCreateArticle as any)!.id;

        // upload attachments
        await Promise.all(
            newAttachments.map((attachment) => articleAttachmentService.uploadAttachment(articleId, attachment))
        );

        // create questions
        await articleQuestionService.createBatch(articleId, questions);

        session.addNotification({ key: `new-article-${articleId}`, type: "success", title: "Produkt erstellt" });
    };

    const updateArticleWithQuestions = async (
        questions: IArticleQuestionWrite[],
        productQuestionsToDelete?: number[]
    ) => {
        const articleId = getArticleIdFromUrl();
        if (productFormValues === undefined) {
            throw "productFormValues not defined";
        }

        if (articleId === undefined) {
            console.error("no articleId found");
            return;
        }

        //update article
        const imageFile = newImage ?? (deleteFetchedImage ? "" : undefined);
        await articleService.updateViaFormData(articleId, { ...productFormValues, imageFile });

        //delete article attachments
        if (attachmentsToDelete.length > 0) {
            articleAttachmentService.deleteMultiple(articleId, attachmentsToDelete);
        }

        // upload attachments
        await Promise.all(
            newAttachments.map((attachment) => articleAttachmentService.uploadAttachment(articleId, attachment))
        );

        //delete article questions
        if (isDefined(productQuestionsToDelete) && productQuestionsToDelete.length > 0) {
            articleQuestionService.deleteMultiple(articleId, productQuestionsToDelete);
        }
        await articleQuestionService.updateMultiple(articleId, questions);
    };

    const onStep1Next = (params: OnNextParams) => {
        setProductFormValues(params.formValues);
        setNewImage(params.newImage);
        setNewAttachments(params.newAttachments ?? []);
        setAttachmentsToDelete(params.attachmentsToDelete ?? []);
        setDeleteFetchedImage(params.deleteFetchedImage);
        setFormStep(2);
    };

    const onSave = async (questions: IArticleQuestionWrite[], questionsToDelete?: number[]) => {
        if (props.pageType === "create") {
            await createArticleWithQuestions(questions);
        } else {
            await updateArticleWithQuestions(questions, questionsToDelete);
        }
        history.push("..");
    };

    const onStep1Cancel = () => {
        history.push("..");
    };

    const onStep2Back = () => {
        setFormStep(1);
    };

    return (
        <MainPage
            title={`Produkt ${verbCreateOrEdit} (Schritt ${formStep}/2)`}
            breadcrumb={{
                data: [
                    { title: "Administration", onClick: () => history.push(`../..`) },
                    { title: "Produkte", onClick: () => history.push(`..`) },
                    { title: verbCreateOrEdit },
                ],
            }}
            pageType={"admin"}
        >
            <S.View>
                {(props.pageType === "edit" && productFormValues === undefined) || session.currentUser === undefined ? (
                    <FieldLoading text="Daten werden geladen..." />
                ) : (
                    <>
                        {formStep === 1 && (
                            <ProductFormStep1
                                fetchedAttachments={fetchedAttachments}
                                initialValues={productFormValues}
                                fetchedImageUrl={fetchedImageUrl}
                                newAttachments={newAttachments}
                                newImage={newImage}
                                onNext={onStep1Next}
                                onCancel={onStep1Cancel}
                                pageType={props.pageType}
                            />
                        )}
                        {formStep === 2 && (
                            <ProductFormStep2
                                onBack={onStep2Back}
                                onSave={onSave}
                                fetchedQuestions={fetchedQuestions}
                                pageType={props.pageType}
                            />
                        )}
                    </>
                )}
            </S.View>
        </MainPage>
    );
};

export default ProductFormPage;

//#region styles
const S = {
    View: styled.main`
        margin-top: ${unitize(20)};
    `,
};
//#endregion styles
