import { Button, designTheme, IconCheck, ModalContent, ModalHeader, unitize } from "@abs-safety/lock-book-web-ui";
import { media } from "@abs-safety/lock-book-web-ui/dist/esm/shared/breakpoints";
import { observer } from "mobx-react";
import React, { FunctionComponent } from "react";
import styled from "styled-components";
import { config } from "../../../../config/config";
import { nextMaintenanceTexts } from "../NextMaintenanceTexts";

interface Step2CompleteDocumentationAssemblyProps {
    onMaintenanceOfferClick: () => void;
    onCalendarButtonClick: () => void;
}

const text = nextMaintenanceTexts;
const quickSwitchMaintenanceVoucher = config.QUICK_SWITCHES.maintenance_voucher;

export const Step2CompleteDocumentationAssembly: FunctionComponent<Step2CompleteDocumentationAssemblyProps> = (
    props
) => {
    return (
        <>
            <ModalHeader title="Dokumentation erfolgreich abgeschlossen" />
            <ModalContent>
                <S.ModalContentWrapper>
                    <S.CompletedIcon>
                        <IconCheck width={50} height={50} />
                    </S.CompletedIcon>
                    <S.ModalHeader>
                        <p>{text.title}</p>
                    </S.ModalHeader>
                    <S.InfoText>{text.description}</S.InfoText>
                    {quickSwitchMaintenanceVoucher === true && (
                        <>
                            <S.ModalTextWrapperTemporaryMoney>
                                <span>{nextMaintenanceTexts.temporaryDescription}</span>
                            </S.ModalTextWrapperTemporaryMoney>
                            <S.ModalTextWrapperTemporaryDisclaimer>
                                <span>{nextMaintenanceTexts.temporaryDisclaimer}</span>
                            </S.ModalTextWrapperTemporaryDisclaimer>
                        </>
                    )}
                </S.ModalContentWrapper>

                <S.ButtonWrapper>
                    <Button>
                        <button
                            className="uf-maintenanceCalendarFileButton_complete"
                            onClick={props.onCalendarButtonClick}
                        >
                            {text.btnCalendar}
                        </button>
                    </Button>
                    <Button>
                        <button
                            className="uf-maintenanceOfferButton_complete"
                            onClick={() => {
                                props.onMaintenanceOfferClick();
                            }}
                        >
                            {text.btnOffer}
                        </button>
                    </Button>
                </S.ButtonWrapper>
            </ModalContent>
        </>
    );
};

export default observer(Step2CompleteDocumentationAssembly);

//#region styles
const S = {
    CompletedIcon: styled.div`
        border-radius: ${unitize(138)};
        border: ${unitize(2)} solid ${designTheme.color.primary};

        display: flex;
        justify-content: center;
        align-items: center;
        margin: ${unitize(12)} auto;
        width: ${unitize(80)};
        height: ${unitize(80)};
        ${media("md")} {
            margin-top: ${unitize(10)};
            width: ${unitize(120)};
            height: ${unitize(120)};
        }
    `,
    ModalContentWrapper: styled.div`
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: ${unitize(26)};
    `,
    ModalHeader: styled.div`
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        margin-top: ${unitize(10)};
        color: ${designTheme.color.black};

        p {
            font-size: ${unitize(26)};
            font-weight: bold;
            line-height: ${unitize(30)};
        }
    `,
    InfoText: styled.p`
        font-size: ${unitize(19)};
        line-height: ${designTheme.typography.lineHeight.pBig};
        margin-top: ${unitize(15)};
        margin-bottom: ${unitize(10)};
        width: 80%;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
    `,
    ModalTextWrapperTemporaryMoney: styled.div`
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        margin-top: ${unitize(16)};
        width: 90%;
        font-size: ${unitize(22)};
        font-weight: bold;
        span {
            color: ${designTheme.color.primary};
        }
    `,
    ModalTextWrapperTemporaryDisclaimer: styled.div`
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: ${unitize(14)};
        margin-top: ${unitize(2)};
        width: 90%;
        span {
            color: ${designTheme.color.black};
        }
    `,
    ButtonWrapper: styled.div`
        display: flex;
        justify-content: center;
        flex-direction: column;
        gap: ${unitize(10)};
        margin-top: ${unitize(30)};
        ${media("md")} {
            gap: ${unitize(30)};
            flex-direction: row;
        }
    `,
};
//#endregion styles
