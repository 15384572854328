import { MainNavigation } from "@abs-safety/lock-book-web-ui";
import { autorun } from "mobx";
import React, { FunctionComponent, useEffect, useState } from "react";
import { documentationService } from "../../../services/DocumentationService";
import { documentationSharingHashService } from "../../../services/DocumentationSharingHashService";
import { session } from "../../../session/Session";
import { isNullish } from "../../../utils/isNullish";
import EditCertificate from "./EditCertificate";
import EditDetails from "./EditDetails";

enum SubViewKey {
    Details = "tab__sidemodal__details",
    Certificate = "tab__sidemodal__certificate",
}

export interface EditModalProps {
    documentationId: number;
    buildingId: number;
}

const Edit: FunctionComponent<EditModalProps> = (props: EditModalProps) => {
    const [activeSubView, setActiveSubView] = useState<SubViewKey>(SubViewKey.Details);

    const documentation = documentationService.get(props.documentationId);
    const isABSAdmin = session.hasAbsAdminRights;

    useEffect(() => {
        return autorun(() => {
            if (isABSAdmin && !isNullish(props.documentationId)) {
                documentationSharingHashService.getDocumentationSharingHashes(props.documentationId);
            }
        });
    }, []);

    return (
        <MainNavigation
            activeId={activeSubView}
            onActiveChange={(id) => setActiveSubView(id as SubViewKey)}
            items={[
                {
                    id: SubViewKey.Details,
                    text: "Details zur Dokumentation",
                    view: <EditDetails key="details" documentationId={props.documentationId} />,
                },

                {
                    id: SubViewKey.Certificate,
                    text: documentation?.type === "assembly" ? "Installationszertifikat" : "Prüfbescheinigung",
                    hidden: !isABSAdmin,
                    view: (
                        <EditCertificate
                            key="certificate"
                            documentationId={props.documentationId}
                            buildingId={props.buildingId}
                        />
                    ),
                },
            ]}
            small
        />
    );
};

export default Edit;
