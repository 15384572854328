import {
    Button,
    CardFile,
    designTheme,
    Dropzone,
    FieldFileUpload,
    IconDownload,
    IconPlus,
    IconTag,
    IconTrash,
    unitize,
} from "@abs-safety/lock-book-web-ui";
import { media } from "@abs-safety/lock-book-web-ui/dist/esm/shared/breakpoints";
import { runInAction } from "mobx";
import { observer } from "mobx-react";
import React, { FunctionComponent, useEffect, useRef, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { ReactSortable, SortableEvent } from "react-sortablejs";
import styled, { css } from "styled-components";
import { config } from "../config/config";
import { IBuildingAttachmentRead, IBuildingAttachmentUpdate } from "../entities/BuildingAttachment";
import { attachmentService } from "../services/AttachmentService";
import { session } from "../session/Session";
import { WaitingForValue } from "../stores/SuperStore";
import { fileToUrlOrThumbnailElement } from "../utils/fileToUrlOrThumbnailElement";
import { getSortedAttachmentList } from "../utils/getSortedAttachmentList";
import {
    gtmTrackAttachmentDrag,
    gtmTrackAttachmentDrag_Cancel,
    gtmTrackAttachmentDrag_Sort,
} from "../utils/gtmEventCollection";
import { isDefined } from "../utils/isDefined";
import { useRefreshImageUrl } from "../utils/useRefreshImageUrl";
import { validateFileSize } from "../utils/validators/file";
import FieldLoading from "./FieldLoading";

const isTouchDevice = "ontouchstart" in window || navigator.msMaxTouchPoints;

type FileData = Pick<
    IBuildingAttachmentRead,
    "categoryId" | "id" | "imageFilename" | "imageUrl" | "imageDownloadUrl" | "createdAt" | "sorting"
>;

interface FilesGridProps {
    files: IBuildingAttachmentRead[] | undefined;
    waitingForLoadFiles: WaitingForValue;
    waitingForUploadFile: WaitingForValue;
    waitingForDeleteFile: WaitingForValue;

    /** in Page Documentation when user cannot edit something */
    isLocked?: boolean;
    insideSidemodal?: boolean;
    onUploadFiles: (files: File[]) => void;
    onDeleteFile: (id: number) => void;
    onUpdateFile: (id: number, data: IBuildingAttachmentUpdate) => void;
    loadFiles: () => void;
}

/**
 * Used in page `BuildingArea` and `Documentation` and in `Photos` with `insideSidemodal: true`
 *
 * - grid with CardFiles
 * - Button to UPLOAD File
 * - Cards have option to DELETE and UPDATE file
 */
const FilesGrid: FunctionComponent<FilesGridProps> = ({
    files,
    waitingForDeleteFile,
    isLocked,
    insideSidemodal,
    ...props
}: FilesGridProps) => {
    const { url } = useRouteMatch();
    const history = useHistory();
    const hiddenFileInputRef = useRef<HTMLInputElement>(null);
    const [attachmentList, setAttachmentList] = useState<FileData[] | undefined>();
    const [uploadDisabled, setUploadDisabled] = useState<boolean>(false);
    const waitingForLoadFiles = props.waitingForLoadFiles !== false;
    const waitingForUploadFile = props.waitingForUploadFile !== false;
    const firstImageUrl = files !== undefined && files.length > 0 ? files[0].imageUrl : undefined;
    const inDocumentation = url.includes("documentations");
    useRefreshImageUrl(firstImageUrl, props.loadFiles, false);

    useEffect(() => {
        const sorted = getSortedAttachmentList(files);
        setAttachmentList(sorted);
    }, [files]);

    //#region event handlers

    const onFileInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files === null) {
            return;
        }
        onFilesSelectedToUpload(Array.from(event.target.files));
        event.target.value = "";
    };

    /** called by FieldFileUpload (props.onFilesAccepted) and by onFileInputChange (the extra button) */
    const onFilesSelectedToUpload = (files: File[]) => {
        if (files.length === 0) {
            return;
        }
        if (isLocked === true) {
            session.addNotification({
                type: "error",
                title: "Gesperrt",
                description: "Die Dokumentation ist gesperrt",
                key: new Date().getTime().toString(),
            });
            return;
        }
        if (validateFiles(files)) {
            props.onUploadFiles(files.reverse());
        }
    };

    const validateFiles = (files: FileList | File[]) => {
        for (const file of files) {
            const validated = validateFileSize(file);
            if (validated instanceof Error) {
                session.addNotification({
                    title: validated.name,
                    description: validated.message,
                    key: new Date().getTime().toString(),
                    type: "error",
                });
                return false;
            }
        }
        return true;
    };

    const dragEnd = (e: SortableEvent) => {
        if (e.oldIndex === e.newIndex) {
            gtmTrackAttachmentDrag_Cancel(e.oldIndex);
            return;
        }

        runInAction(() => {
            let i = 0;
            gtmTrackAttachmentDrag_Sort(e.newIndex);
            isDefined(attachmentList) &&
                attachmentService.updateAttachments(
                    attachmentList.map((attachment) => {
                        const id = attachment.id;
                        const sorting = i;
                        attachment.sorting = i;

                        const f = attachmentService.get(attachment.id);
                        isDefined(f) && (f.sorting = i);

                        i++;
                        return {
                            id,
                            sorting,
                        };
                    })
                );
        });
    };

    const dragStart = (e: SortableEvent) => {
        gtmTrackAttachmentDrag(e.oldIndex);
        setUploadDisabled(true);
    };

    const onFileClick = (file: IBuildingAttachmentRead) => {
        history.push(`${url}/file/${file.id}`);
    };

    //#endregion event handlers

    return (
        <S.Component>
            <S.UploadButton className={"uf-uploadButton"}>
                <Button>
                    <button
                        onClick={() => hiddenFileInputRef.current?.click()}
                        disabled={
                            waitingForLoadFiles || waitingForUploadFile || isLocked || waitingForDeleteFile !== false
                        }
                    >
                        {inDocumentation ? "Datei hochladen" : "Plan hochladen"}
                        <IconPlus />
                    </button>
                </Button>
                <input
                    style={{ display: "none" }}
                    type="file"
                    multiple={true}
                    accept={config.UPLOAD_ACCEPT_BUILDING_ATTACHMENT}
                    name="attachment"
                    onChange={onFileInputChange}
                    ref={hiddenFileInputRef}
                />
            </S.UploadButton>
            {(files?.length === 0 && waitingForLoadFiles) || files === undefined ? (
                <FieldLoading text="Dateien werden geladen" height={unitize(300)} />
            ) : (
                <div className={"uf-uploadArea"}>
                    {files.length === 0 && !waitingForUploadFile && isLocked !== true ? (
                        <FieldFileUpload
                            multiple={true}
                            accept={config.UPLOAD_ACCEPT_BUILDING_ATTACHMENT}
                            onFilesAccepted={onFilesSelectedToUpload}
                            text="Zum Hochladen Datei hier ablegen"
                        />
                    ) : (
                        <Dropzone
                            width="100%"
                            multiple={true}
                            accept={config.UPLOAD_ACCEPT_BUILDING_ATTACHMENT}
                            onFilesAccepted={onFilesSelectedToUpload}
                            disabled={uploadDisabled}
                        >
                            <S.GridContainer insideSidemodal={insideSidemodal}>
                                {waitingForUploadFile && (
                                    <FieldLoading height="100%" text="Datei wird hochgeladen ..." />
                                )}

                                {isDefined(attachmentList) && (
                                    <ReactSortable
                                        touchStartThreshold={100}
                                        onStart={dragStart}
                                        delay={300}
                                        delayOnTouchOnly={true}
                                        animation={100}
                                        easing={"cubic-bezier(1, 0, 0, 1)"}
                                        onEnd={dragEnd}
                                        className={"sortingContainer"}
                                        list={attachmentList}
                                        setList={setAttachmentList}
                                    >
                                        {attachmentList.map((file) => (
                                            <CardFile
                                                dropdownWidth={250}
                                                key={file.id}
                                                disabled={waitingForDeleteFile === file.id}
                                                {...fileToUrlOrThumbnailElement(file)}
                                                title={file.imageFilename}
                                                subtitle={session.categoryNamesById.get(file.categoryId) ?? ""}
                                                onClick={
                                                    !(insideSidemodal ?? false)
                                                        ? () => {
                                                              const f = attachmentService.get(file.id);
                                                              isDefined(f) && onFileClick(f);
                                                          }
                                                        : undefined
                                                }
                                                dropdownItems={
                                                    isLocked ?? false
                                                        ? undefined
                                                        : [
                                                              {
                                                                  text: "Kategorie zuweisen",
                                                                  icon: <IconTag />,
                                                                  subItems: session.categories.map((category) => {
                                                                      return {
                                                                          // TODO-i18n ?
                                                                          text: category.category,
                                                                          onClick: () =>
                                                                              props.onUpdateFile(file.id, {
                                                                                  category: `/building-attachment-categories/${category.id}`,
                                                                              }),
                                                                      };
                                                                  }),
                                                              },
                                                              {
                                                                  text: "Löschen",
                                                                  icon: <IconTrash />,
                                                                  textColor: designTheme.color.error,
                                                                  onClick: () => props.onDeleteFile(file.id),
                                                              },
                                                              {
                                                                  text: "Herunterladen",
                                                                  icon: <IconDownload width={21} height={21} />,
                                                                  href: file.imageDownloadUrl,
                                                                  hidden: !(insideSidemodal ?? false),
                                                                  download: file.imageFilename,
                                                              },
                                                          ]
                                                }
                                            />
                                        ))}
                                    </ReactSortable>
                                )}
                            </S.GridContainer>
                        </Dropzone>
                    )}
                </div>
            )}
        </S.Component>
    );
};

export default observer(FilesGrid);

//#region styles
const S = {
    Component: styled.div``,
    UploadButton: styled.div`
        margin-bottom: ${unitize(30)};
    `,
    GridContainer: styled.div<{ insideSidemodal?: boolean }>`
        .sortingContainer {
            .sortable-ghost {
                border: 2px dashed ${designTheme.color.primary};
                box-shadow: none;

                * {
                    visibility: hidden;
                }
            }

            .sortable-chosen {
                ${isTouchDevice === true
                    ? `
                transform: scale3d(1.01, 1.01, 1.01);
                box-shadow: 5px 5px 1.1875rem rgb(0 0 0 / 35%);
                * {
                    pointer-events: none;
                }
                `
                    : ""}
            }

            display: grid;
            grid-template-columns: 1fr;
            grid-auto-rows: ${unitize(330)};
            grid-column-gap: ${unitize(30)};
            grid-row-gap: ${unitize(30)};
            ${media("sm")} {
                grid-template-columns: 1fr 1fr;
            }
            ${media("lg")} {
                grid-template-columns: 1fr 1fr 1fr;
            }
            ${media("xl")} {
                grid-template-columns: 1fr 1fr 1fr 1fr;
            }
            ${({ insideSidemodal }) =>
                insideSidemodal === true &&
                css`
                    grid-template-columns: 1fr;
                    grid-auto-rows: ${unitize(270)};
                    grid-row-gap: ${unitize(15)};
                    grid-column-gap: ${unitize(15)};
                    ${media("sm")} {
                        grid-template-columns: 1fr;
                    }
                    ${media("lg")} {
                        grid-template-columns: 1fr 1fr;
                    }
                    ${media("xl")} {
                        grid-template-columns: 1fr 1fr;
                    }
                `}
        }
    `,
};
//#endregion styles
