import { observer } from "mobx-react";
import React, { FunctionComponent, useEffect } from "react";
import { Redirect, Route, Switch, useHistory, useRouteMatch } from "react-router-dom";
import { session } from "../../session/Session";
import { isDefined } from "../../utils/isDefined";
import { getLocalStorageValue, removeLocalStorageValue, setLocalStorageValue } from "../../utils/localStorage";
import { useQuery } from "../../utils/useQuery";
import Error403 from "../errors/Error403";
import { adminStore } from "./AdminStore";
import ProductFormPage from "./Products/ProductFormPage";
import ProductListPage from "./Products/ProductListPage";

/**
 * Page: Admin
 */
const Admin: FunctionComponent = () => {
    const history = useHistory();
    const { path } = useRouteMatch();
    const query = useQuery();

    // TODO onAdminRelease: remove
    useEffect(() => {
        if (query.get("activate") !== null) {
            setLocalStorageValue("adminPageActive", true);
        } else if (query.get("deactivate") !== null) {
            removeLocalStorageValue("adminPageActive");
        }
        if (getLocalStorageValue("adminPageActive") !== true) {
            console.log("#####################\nAdmin area feature is not enabled!\n#####################");
            history.push("/");
        }
    }, [history, query]);

    useEffect(() => {
        adminStore.init();
        adminStore.setAdminPageIsOpened(true);
        return () => {
            adminStore.setAdminPageIsOpened(false);
        };
    }, []);

    //#region render
    if (isDefined(session.currentUser) && !session.hasAbsAdminRights && !session.hasCompanyAdminRights) {
        return <Error403 />;
    }
    return (
        <Switch>
            <Route path={`${path}/products/create`}>
                <ProductFormPage pageType="create" />
            </Route>
            <Route path={`${path}/products/edit`}>
                <ProductFormPage pageType="edit" />
            </Route>
            <Route path={`${path}/products`} exact component={ProductListPage} />
            <Route path={`${path}*`}>
                <Redirect to="/admin/products" />
            </Route>
        </Switch>
    );
};
//#endregion

export default observer(Admin);
