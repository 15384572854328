import { observer } from "mobx-react";
import React, { FunctionComponent } from "react";
import CertificateView from "../../../components/CertificateView/CertificateView";
import { FormValues } from "../../../components/CertificateView/ModalNewCertificate";
import { getController } from "../../../stores/controller/ControllerFactory";
import { DocumentationController } from "../DocumentationController";
export interface EditCertificateModalProps {
    documentationId: number;
    buildingId: number;
}

const EditCertificate: FunctionComponent<EditCertificateModalProps> = (props: EditCertificateModalProps) => {
    const { controller } = getController(DocumentationController);
    const onGenerateSharingLink = () => {
        controller.createDocumentationSharingHash({
            documentationId: props.documentationId,
            type: "installation-certificate",
        });
    };

    const onCreateCertificate = (values: FormValues) => controller.createCertificate(values, props.documentationId);

    return (
        <CertificateView
            documentationId={props.documentationId}
            buildingId={props.buildingId}
            waitingForCreateSharingHash={controller.waitingFor.createSharingHash === true}
            waitingForUpdateDocumentation={controller.waitingFor.updateDocumentation === true}
            onCreateCertificate={onCreateCertificate}
            onGenerateSharingLink={onGenerateSharingLink}
        />
    );
};

export default observer(EditCertificate);
