import {
    Button,
    ColumnSizes,
    FieldEmpty,
    IconPlus,
    ListSortable,
    SearchBar,
    unitize,
} from "@abs-safety/lock-book-web-ui";
import { observer } from "mobx-react";
import React, { FunctionComponent, useEffect, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import styled from "styled-components";
import FieldLoading from "../../../components/FieldLoading";
import { fuzzySearchArticles } from "../../../components/ModalAddArticle/utils/fuzzySearchArticles";
import { articleService } from "../../../services/ArticleService";
import { session } from "../../../session/Session";
import MainPage from "../../../templates/MainPage";
import { getArticleTypeName } from "../../../utils/getArticleTypeName";
import CardProduct from "./components/CardProduct";

const listColumnSizes: ColumnSizes = { xs: [6, 3, 3] };

const ProductListPage: FunctionComponent = () => {
    const history = useHistory();
    const { url } = useRouteMatch();
    const [searchValue, setSearchValue] = useState("");
    const isLoading = articleService.waitingFor.loadArticles === true;

    /** only show Custom articles if normal (non ABS-)Admin  */
    const articles = session.hasAbsAdminRights
        ? articleService.list
        : articleService.list.filter((a) => a.type === "custom");

    useEffect(() => {
        articleService.fetchArticlesIfNotYetFetched();
    }, []);

    const onNewProduct = () => {
        history.push(`${url}/create`);
    };

    return (
        <MainPage
            title={"Produkte"}
            breadcrumb={{
                data: [{ title: "Administration" }, { title: "Produkte" }],
            }}
            pageType={"admin"}
        >
            <S.View>
                <S.SubHeader>
                    <Button disabled={isLoading}>
                        <button className={"uf-createButton"} onClick={onNewProduct}>
                            Produkt anlegen <IconPlus />
                        </button>
                    </Button>
                    <S.SearchBar>
                        <SearchBar
                            placeholder="Produkt suchen"
                            value={searchValue}
                            onChange={setSearchValue}
                            onSubmit={setSearchValue}
                        />
                    </S.SearchBar>
                </S.SubHeader>
                {articles.length === 0 && <FieldEmpty text="Noch kein Produkt angelegt" />}
                {articles.length > 0 && (
                    <ListSortable
                        headerStyle={{ padding: unitize(10) }}
                        listStyle={{ display: "grid", gridGap: unitize(10) }}
                        columnSizes={listColumnSizes}
                        items={articles}
                        columns={[
                            {
                                title: "Name",
                                sortProperty: "name",
                            },
                            {
                                title: "Typ",
                                sortFunction: (a, b) => {
                                    return getArticleTypeName(a.type).singular.localeCompare(
                                        getArticleTypeName(b.type).singular
                                    );
                                },
                            },
                            {
                                title: "Kategorie",
                                // sortProperty: "categoryId", // TODO: if sort, then via sortFunction, as sorting by name, and not by categoryId
                            },
                        ]}
                    >
                        {(sortedList) => (
                            <>
                                {isLoading && <FieldLoading text="Produkte werden geladen..." />}
                                {fuzzySearchArticles(sortedList, searchValue).map((article) => (
                                    <CardProduct
                                        key={article.id}
                                        article={article}
                                        columnSizes={listColumnSizes}
                                        onClick={() => history.push(`${url}/edit?id=${article.id}`)}
                                    />
                                ))}
                            </>
                        )}
                    </ListSortable>
                )}
            </S.View>
        </MainPage>
    );
};

export default observer(ProductListPage);

//#region styles
const S = {
    View: styled.main`
        margin-top: ${unitize(20)};
    `,
    SubHeader: styled.div`
        display: flex;
        align-items: center;
        margin-bottom: ${unitize(30)};
        > h2 {
            margin-right: ${unitize(20)};
        }
    `,
    SearchBar: styled.div`
        flex: 0 1 ${unitize(330)};
        margin-left: auto;
    `,
};
//#endregion styles
