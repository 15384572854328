import { Button, designTheme, unitize } from "@abs-safety/lock-book-web-ui";
import { Form, Formik, FormikProps } from "formik";
import { observer } from "mobx-react";
import React, { FunctionComponent, useEffect, useState } from "react";
import styled from "styled-components";
import * as Yup from "yup";
import FormAutoSubmit from "../../../../components/FormAutoSubmit";
import FormGtmIntegration from "../../../../components/FormGtmIntegration";
import FormFieldsInstalledArticle from "../../../../components/ModalAddArticle/components/FormFieldsInstalledArticle";
import { getConstraints } from "../../../../constraints/constraints";
import { ArticleType } from "../../../../entities/Article";
import {
    InstalledArticleDocumentationOverview,
    InstalledArticlesFormValues,
} from "../../../../entities/InstalledArticle";
import { documentationItemService } from "../../../../services/DocumentationItemService";
import { session } from "../../../../session/Session";
import { getController } from "../../../../stores/controller/ControllerFactory";
import { apiConstraintErrorsToFormErrors } from "../../../../utils/apiConstraintErrorsToFormErrors";
import { gtmAutoSubmit } from "../../../../utils/gtmEventCollection";
import { ViewOfInstalledArticleForm } from "../../../../utils/isInstalledArticleFormFieldVisible";
import { isNullish } from "../../../../utils/isNullish";
import { processInstalledArticleFormData } from "../../../../utils/processInstalledArticleFormData";
import PopupDeleteArticle from "../../components/PopupDeleteArticle";
import { DocumentationController } from "../../DocumentationController";
import { DocumentController } from "../DocumentController";

interface ItemProps {
    selectedInstalledArticle: InstalledArticleDocumentationOverview;
}

const ItemDetails: FunctionComponent<ItemProps> = (props: ItemProps) => {
    const { controller: documentController } = getController(DocumentController);
    const { controller: documentationController } = getController(DocumentationController);
    const [validationSchema, setValidationSchema] = useState(
        Yup.object().shape<Partial<InstalledArticlesFormValues>>({})
    );

    useEffect(() => {
        setValidationSchema(
            Yup.object().shape<Partial<InstalledArticlesFormValues>>({
                identificationNumber: getConstraints("InstalledArticle", "identificationNumber"),
                identificationCounter: getConstraints("InstalledArticle", "identificationCounter"),
                absSerialNumber: getConstraints("InstalledArticle", "absSerialNumber")?.nullable(true),
                comment: getConstraints("InstalledArticle", "comment"),
                identificationPlateNumber: getConstraints("InstalledArticle", "identificationPlateNumber")?.nullable(
                    true
                ),
            })
        );
    }, [session.constraints]);

    const articleType = documentController.article.type;

    if (articleType === undefined || !documentationController.documentation) {
        return null;
    }

    const inView: ViewOfInstalledArticleForm =
        documentationController.documentation.type === "assembly"
            ? "documentation/assembly"
            : "documentation/maintenance";

    return (
        <S.Component>
            <Formik
                key={props.selectedInstalledArticle.id}
                initialValues={
                    {
                        identificationNumber: props.selectedInstalledArticle.identificationNumber,
                        identificationCounter: props.selectedInstalledArticle.identificationCounter,
                        absSerialNumber: props.selectedInstalledArticle.absSerialNumber,
                        comment: props.selectedInstalledArticle.comment,
                        serialNumber: props.selectedInstalledArticle.serialNumber,
                        identificationPlateNumber: props.selectedInstalledArticle.identificationPlateNumber,
                    } as InstalledArticlesFormValues
                }
                validationSchema={validationSchema}
                onSubmit={(values, helpers) => {
                    const cleanedValues = processInstalledArticleFormData(values, inView, articleType);
                    documentController
                        .updateInstalledArticle(props.selectedInstalledArticle.id, cleanedValues)
                        .catch((error) => apiConstraintErrorsToFormErrors(error, helpers.setErrors));
                }}
                enableReinitialize
            >
                {(formikBag) => <ObservedForm {...formikBag} articleType={articleType} inView={inView} />}
            </Formik>
        </S.Component>
    );
};

type FormComponentProps = FormikProps<InstalledArticlesFormValues> & {
    articleType: ArticleType;
    inView: ViewOfInstalledArticleForm;
};

/**
 * Sub Component
 */
const FormComponent: FunctionComponent<FormComponentProps> = (props: FormComponentProps) => {
    const { controller: documentController } = getController(DocumentController);
    const { controller: documentationController } = getController(DocumentationController);
    const [popupDeleteArticleOpen, setPopupDeleteArticleOpen] = useState(false);
    const { controller } = getController(DocumentationController);
    const documentationCompleted = controller.documentation?.isCompleted;

    const onDeleteClick = () => {
        documentationItemService.delete(documentController.currentDocumentationItemIds[0]).then((res) => {
            if (!(res.response?.ok ?? false)) return;
            const article =
                documentationController.articles.find((article) => article.id === documentController.currentArticle) ??
                documentationController.articles[0];

            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            if (article !== undefined) {
                documentController.setCurrentArticle(article.id);
                documentController.setCurrentDocumentationItemIds([article.documentationItems[0].id]);
            }
        });
        setPopupDeleteArticleOpen(false);
    };

    if (isNullish(documentationController.documentation)) {
        return null;
    }
    const view: ViewOfInstalledArticleForm =
        documentationController.documentation.type === "assembly"
            ? "documentation/assembly"
            : "documentation/maintenance";
    return (
        <>
            <Form style={{ maxWidth: unitize(720) }}>
                <FormFieldsInstalledArticle
                    articleType={props.articleType}
                    view={view}
                    amountInstalledArticles={1}
                    values={props.values}
                    disabled={documentationCompleted === true}
                />

                <FormAutoSubmit onSuccess={gtmAutoSubmit("docuitem")} />
                <FormGtmIntegration action={"edit_docuitem"} />
            </Form>
            {popupDeleteArticleOpen && (
                <PopupDeleteArticle onClose={() => setPopupDeleteArticleOpen(false)} onDelete={onDeleteClick} />
            )}
            {documentationCompleted !== true && (
                <Button color="decline" size="small" variant="outline" style={{ marginTop: unitize(20) }}>
                    <button
                        type="button"
                        onClick={() => {
                            setPopupDeleteArticleOpen(true);
                        }}
                    >
                        Artikel löschen
                    </button>
                </Button>
            )}
        </>
    );
};

const ObservedForm = observer(FormComponent);

export default observer(ItemDetails);

//#region styles
const S = {
    Component: styled.div`
        margin: ${unitize(20)} 0;
    `,
    NamingPreview: styled.div`
        padding: ${unitize(20)};
        background-color: ${designTheme.color.lightestgrey};
        margin-bottom: ${unitize(30)};

        ul {
            margin-top: ${unitize(10)};
            margin-bottom: 0;
            padding-left: 0;
            list-style-position: inside;
        }
    `,
    NotFoundText: styled.h4`
        margin-top: ${unitize(20)};
    `,
};
//#endregion styles
