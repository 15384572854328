import { IArticleCategoryRead } from "../entities/ArticleCategory";
import { ArticlesVisibilityApiResponse } from "../entities/ArticleVisibility";
import { IBuildingAttachmentCategoryRead } from "../entities/BuildingAttachmentCategory";
import { IConstraintRead } from "../entities/Constraint";
import { ApiResponse, ApiService } from "./ApiService";
import { fetchTypes, HTTPMethod } from "./Connection";

export class MiscApi extends ApiService<IBuildingAttachmentCategoryRead, IBuildingAttachmentCategoryRead> {
    getBuildingAttachmentCategories(): Promise<ApiResponse<IBuildingAttachmentCategoryRead[]>> {
        return this.get<IBuildingAttachmentCategoryRead[]>("/building-attachment-categories");
    }

    getConstraints(locale: string): Promise<ApiResponse<IConstraintRead>> {
        return this.invoke<IConstraintRead>(fetchTypes.fetch, {
            method: HTTPMethod.GET,
            route: `/constraints`,
            params: { locale: locale },
        });
    }

    getArticleCategories(): Promise<ApiResponse<IArticleCategoryRead[]>> {
        return this.get<IArticleCategoryRead[]>(`/article-categories`);
    }

    getArticlesVisibility(): Promise<ApiResponse<ArticlesVisibilityApiResponse>> {
        return this.get<ArticlesVisibilityApiResponse>(`/articles-visibility`);
    }
}

export const miscApi = new MiscApi();
