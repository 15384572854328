/**
 * based on lock-book-docu-api: api/src/Entity/InstalledArticle.php
 */

import { RepoStore } from "../stores/RepoStore";
import { IArticleRead } from "./Article";
import { IDocumentationItemRead } from "./DocumentationItem";
import { IUserRead } from "./User";

class InstalledArticleRepo extends RepoStore<IInstalledArticleRead> {}

export const installedArticleRepo = new InstalledArticleRepo();

// related services
type Article = IArticleRead;
type DocumentationItem = IDocumentationItemRead;
type User = IUserRead;

type BuildingArea = unknown;
type Building = unknown;

export interface IInstalledArticleBase {
    amount: number;
    articleId: number;
    /** The array of installedArticle IDs that are being deleted or added. */
    installedArticleIds: number[];
    id: number;
    appId: number;
    serialNumber?: string | null;
    absSerialNumber?: string | null;
    identificationNumber?: string | null;
    identificationCounter?: string | null;
    identificationPlateNumber?: string | null;
    comment?: string | null;
    installationDate: Date;
    deletionDate?: Date | null;
    deletionReason?: string | null;
    /** default: false */
    isDeleted?: boolean;
    updatedAt?: Date | null;
    createdAt?: Date | null;
    serialseperator?: string | null;
    /** if there are no documentation items */
    isAssembled: boolean;
    documentationItemIds: number[];
    buildingAreaId: number;
    buildingId: number;
    amountAssemblies: number;
    amountMaintenances: number;

    // many to one

    deletedBy: User;
    user: User;
    article: Article;
    area: BuildingArea;
    building: Building;
    documentationItems: DocumentationItem;
}

/** All InstalledArticles properties from php entity file with `documentation:read` annotation */
export type IInstalledArticleRead = Pick<
    IInstalledArticleBase,
    | "id"
    | "appId"
    | "serialNumber"
    | "identificationNumber"
    | "identificationCounter"
    | "comment"
    | "installationDate"
    | "isDeleted"
    | "isAssembled"
    | "articleId"
    | "buildingAreaId"
    | "buildingId"
    | "documentationItemIds"
    | "amountAssemblies"
    | "absSerialNumber"
    | "identificationPlateNumber"
>;

/** All InstalledArticles properties from php entity file with `documentation:write` annotation. */
export type IInstalledArticleWrite = Pick<
    IInstalledArticleBase,
    | "serialNumber"
    | "identificationNumber"
    | "identificationCounter"
    | "comment"
    | "installationDate"
    | "isDeleted"
    | "identificationPlateNumber"
>;

/**
 * All InstalledArticles properties from php entity file with `installed-article:install` annotation.
 * Used by
 * - POST /documentations/{id}/articles
 * - POST /building-areas/{id}/articles
 * */
export type IInstalledArticleInstall = Pick<
    IInstalledArticleBase,
    | "amount"
    | "articleId"
    | "serialNumber"
    | "identificationNumber"
    | "identificationCounter"
    | "comment"
    | "identificationPlateNumber"
>;

/**
 * All InstalledArticles properties from php entity file with `installed-article:add` annotation.
 * Used by
 * - POST /documentations/{id}/installed-articles
 * */
export type IInstalledArticleAdd = Pick<IInstalledArticleBase, "installedArticleIds">;

/**
 * All InstalledArticles properties from php entity file with `documentation-overview` annotation.
 * */
export type InstalledArticleDocumentationOverview = Pick<
    IInstalledArticleBase,
    | "appId"
    | "absSerialNumber"
    | "serialNumber"
    | "identificationNumber"
    | "identificationCounter"
    | "id"
    | "installationDate"
    | "comment"
    | "identificationPlateNumber"
>;

/**
 * All InstalledArticles properties from php entity file with `installedArticle:amounts` annotation.
 * Used by
 * - GET /building-areas/{id}/installed-articles (together with IInstalledArticleRead)
 * */
export type IInstalledArticleAmounts = Pick<IInstalledArticleBase, "amountAssemblies" | "amountMaintenances">;

/**
 * All InstalledArticles properties from php entity file with `installedArticle:amounts` annotation.
 * Used by
 * - GET /building-areas/{id}/installed-articles (together with IInstalledArticleRead)
 * */
export type IInstalledArticleListCard = Pick<
    IInstalledArticleBase,
    "id" | "installationDate" | "amountAssemblies" | "amountMaintenances"
>;

export type InstalledArticlesFormValues = Pick<
    IInstalledArticleBase,
    | "identificationNumber"
    | "identificationCounter"
    | "comment"
    | "serialNumber"
    | "absSerialNumber"
    | "identificationPlateNumber"
>;

export const initialInstalledArticlesFormValues: InstalledArticlesFormValues = {
    identificationNumber: "",
    identificationCounter: "",
    comment: "",
    serialNumber: "",
    absSerialNumber: "",
    identificationPlateNumber: "",
};
