import { UnauthorizedError } from "../entities/ErrorResponse";
import { ICurrentUser } from "../entities/User";
import { IUserPropsRead, IUserPropsWrite } from "../entities/UserProps";
import { ApiResponse, ApiService } from "./ApiService";
import { fetchTypes, HTTPMethod } from "./Connection";

export class AuthApi extends ApiService<ICurrentUser> {
    constructor() {
        super(``, `${process.env.REACT_APP_HUB_API_URL}`);
    }

    getRefresh(): Promise<ApiResponse<unknown>> {
        return this.invoke(fetchTypes.fetchString, {
            method: HTTPMethod.GET,
            mode: "cors",
            route: "/refresh-token",
            credentials: "include",
            redirect: "manual",
        });
    }

    getUser(refresh = true): Promise<ApiResponse<ICurrentUser>> {
        return this.get(`/user`).catch((e) => {
            if (e instanceof UnauthorizedError && refresh) {
                return this.getRefresh().then(() => {
                    return this.getUser(false);
                });
            }

            throw e;
        });
    }

    async getDocumentedBeforeSurvey(): Promise<ApiResponse<boolean>> {
        return await this.get<boolean>(`/should-show-documented-before-survey`);
    }

    putUserProps(body: IUserPropsWrite): Promise<ApiResponse<IUserPropsRead>> {
        return this.put(`user-props`, body);
    }
}
