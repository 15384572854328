import { Button, SearchBar } from "@abs-safety/lock-book-web-ui";
import { observer } from "mobx-react";
import React, { FunctionComponent, useCallback, useEffect, useState } from "react";
import { IArticleOverviewItem } from "../../../api/ArticleApi";
import { ArticleType } from "../../../entities/Article";
import { DocumentationController } from "../../../pages/Documentation/DocumentationController";
import { articleService } from "../../../services/ArticleService";
import { getController } from "../../../stores/controller/ControllerFactory";
import { addOrDeleteFromSet } from "../../../utils/addOrDeleteFromSet";
import { AddArticleController } from "../AddArticleController";
import { filterArticleByCategory } from "../utils/filterArticleByCategory";
import { filterArticleByType } from "../utils/filterArticleByType";
import { fuzzySearchArticles } from "../utils/fuzzySearchArticles";
import { getAmountAvailableInstalledArticles } from "../utils/getAmountAvailableInstalledArticles";
import { sortArticlesByName } from "../utils/sortArticlesByName";
import ArticleTypeFilter from "./ArticleTypeFilter";
import CategoryFilter from "./CategoryFilter";
import Step1AvailableArticlesList from "./Step1AvailableArticlesList";
import Step1ContentWhenNoArticles from "./Step1ContentWhenNoArticles";
import Step1Template from "./Step1Template";

export interface Step1AvailableArticlesProps {
    articles?: IArticleOverviewItem[];
    searchValue: string;
    onSearchChange: (value: string) => void;
    onCloseClick: () => void;
}

const Step1AvailableArticles: FunctionComponent<Step1AvailableArticlesProps> = (props: Step1AvailableArticlesProps) => {
    const { controller: modalController } = getController(AddArticleController);
    const { controller: documentationController } = getController(DocumentationController);
    /* article that is currently selected and its installedArticles are expanded */
    const [articleExpanded, setArticleExpanded] = useState<IArticleOverviewItem | undefined>(undefined);

    /* visible articles in upper list (search already applied) */
    const [articlesEnabled, setArticlesEnabled] = useState<IArticleOverviewItem[]>([]);
    /* visible articles in bottom list (search already applied) */
    const [articlesDisabled, setArticlesDisabled] = useState<IArticleOverviewItem[]>([]);
    const [activeArticleTypeFilter, setActiveArticleTypeFilter] = useState(new Set<ArticleType>());

    const applyFilterToArticles = useCallback(
        (articles: IArticleOverviewItem[]) => {
            let filteredArticles = filterArticleByCategory(articles, modalController.categoryFilter);
            filteredArticles = filterArticleByType(filteredArticles, activeArticleTypeFilter);

            return fuzzySearchArticles(filteredArticles, props.searchValue);
        },
        [props.searchValue, activeArticleTypeFilter, modalController.categoryFilter]
    );

    // init & update articlesEnabled & articlesDisabled
    useEffect(() => {
        if (props.articles === undefined) return;

        const enabled: IArticleOverviewItem[] = [];
        const disabled: IArticleOverviewItem[] = [];

        const sortedArticles = sortArticlesByName(props.articles);

        sortedArticles.forEach((article) => {
            if (getAmountAvailableInstalledArticles(article) === 0) {
                disabled.push(article);
            } else {
                enabled.push(article);
            }
        });

        setArticlesEnabled(applyFilterToArticles(enabled));
        setArticlesDisabled(applyFilterToArticles(disabled));
    }, [
        props.articles,
        props.searchValue,
        activeArticleTypeFilter,
        modalController.categoryFilter.size,
        applyFilterToArticles,
    ]);

    useEffect(() => {
        articlesEnabled
            .map((ae) => ae.id)
            .forEach((id) => modalController.selectAvailableArticle(id, documentationController.documentation?.type));
    }, [articlesEnabled, documentationController.documentation?.type, modalController]);

    const onArticleTypeFilterChange = (type: ArticleType, checked: boolean) => {
        setActiveArticleTypeFilter((_oldState) => addOrDeleteFromSet(_oldState, type, checked ? "add" : "delete"));
    };

    const onArticleExpandedChange = useCallback(
        (articleId: number) => {
            const article = articlesEnabled.find((article) => article.id === articleId);
            article?.id !== articleExpanded?.id ? setArticleExpanded(article) : setArticleExpanded(undefined);

            if (articleId === 0) {
                throw Error("onArticleExpandedChange() called with articleId which wasn't found in articlesEnabled.");
            }
        },
        [articleExpanded, articlesEnabled]
    );

    const addInstalledArticles = () => {
        modalController.addInstalledArticlesToDocumentation(
            documentationController.documentation?.id,
            modalController.availableInstalledArticlesSelected
        );
        props.onCloseClick();
    };

    return (
        <Step1Template
            articlesLoading={
                articleService.waitingFor.loadArticles === true ||
                modalController.waitingFor.loadBuildingAreaArticleOverview === true
            }
            list={
                articlesEnabled.length < 1 && articlesDisabled.length < 1 ? (
                    <Step1ContentWhenNoArticles
                        searchIsActive={props.searchValue !== ""}
                        filterIsActive={activeArticleTypeFilter.size > 0}
                    />
                ) : (
                    <div className="uf-articleList">
                        <Step1AvailableArticlesList
                            {...props}
                            isSearchOrFilterActive={
                                props.searchValue !== "" ||
                                activeArticleTypeFilter.size !== 0 ||
                                modalController.categoryFilter.size !== 0
                            }
                            listType="enabled"
                            visibleArticles={articlesEnabled}
                            expandedArticleId={articleExpanded?.id}
                            whenExpandedChanges={onArticleExpandedChange}
                        />
                        <Step1AvailableArticlesList
                            {...props}
                            isSearchOrFilterActive={
                                props.searchValue !== "" ||
                                activeArticleTypeFilter.size !== 0 ||
                                modalController.categoryFilter.size !== 0
                            }
                            listType="disabled"
                            visibleArticles={articlesDisabled}
                        />
                    </div>
                )
            }
            search={
                <SearchBar placeholder="Produkt suchen" value={props.searchValue} onChange={props.onSearchChange} />
            }
            filter={
                <>
                    <ArticleTypeFilter
                        onCheckboxChange={onArticleTypeFilterChange}
                        activeArticleTypeFilter={activeArticleTypeFilter}
                        hideMisc={modalController.documentationType === "assembly"}
                    />
                    <CategoryFilter />
                </>
            }
            footer={
                <>
                    <Button disabled={modalController.availableInstalledArticlesSelected.size <= 0 ? true : false}>
                        <button className="uf-addArticleNext" type="button" onClick={addInstalledArticles}>
                            Produkt(e) hinzufügen
                        </button>
                    </Button>
                    <Button variant="text" color="black">
                        <button type="button" onClick={props.onCloseClick}>
                            Abbrechen
                        </button>
                    </Button>
                </>
            }
        />
    );
};

export default observer(Step1AvailableArticles);
