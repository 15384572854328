import { IEntityRead, IEntityWrite } from "../entities/Base";
import { ConstraintViolationError, ForbiddenError, NotFoundError, UnauthorizedError } from "../entities/ErrorResponse";
import { apiEndpoint, Connection, fetchOptions, fetchTypes, HTTPMethod, HydraResponse, ParamBag } from "./Connection";

type ResultObject<FetchType extends fetchTypes, T> = FetchType extends fetchTypes.fetchHydra
    ? HydraResponse<T>
    : FetchType extends fetchTypes.fetchString
    ? string
    : T;

export type ApiResponse<T, FetchType extends fetchTypes = fetchTypes.fetch> = {
    response: Response | null;
    result: ResultObject<FetchType, T> | null;
};

export class ApiService<Read extends IEntityRead, Write extends IEntityWrite = Read> {
    readonly endpoint: apiEndpoint;
    fetcher: Connection;

    constructor(endpoint?: apiEndpoint, apiUrl: apiEndpoint = `${process.env.REACT_APP_API_URL}`) {
        this.endpoint = apiUrl;

        if (endpoint !== undefined) {
            this.endpoint = `${this.endpoint}${endpoint}`;
        }
        this.fetcher = new Connection(this.endpoint);
    }

    async get<T = Read>(route = "", params?: ParamBag): Promise<ApiResponse<T>> {
        return await this.invoke<T>(fetchTypes.fetch, {
            method: HTTPMethod.GET,
            route: route,
            params: params,
        });
    }

    async getHydrate<T = Read>(route = "", params?: ParamBag): Promise<ApiResponse<T, fetchTypes.fetchHydra>> {
        return await this.invoke<T, Write, fetchTypes.fetchHydra>(fetchTypes.fetchHydra, {
            method: HTTPMethod.GET,
            route: route,
            params: params,
        });
    }

    async put<T = Read, T2 = Write>(route = "", body?: T2, params?: ParamBag): Promise<ApiResponse<T>> {
        return await this.invoke<T, T2>(fetchTypes.fetch, {
            route: `${route}`,
            body,
            method: HTTPMethod.PUT,
            params,
        });
    }

    async post<T = Read, T2 = Write>(route = "", body?: T2, params?: ParamBag): Promise<ApiResponse<T>> {
        return await this.invoke<T, T2>(fetchTypes.fetch, {
            route: `${route}`,
            body,
            method: HTTPMethod.POST,
            params,
        });
    }

    async upload<T = Read, T2 = Write>(
        route = "",
        body: FormData,
        params?: ParamBag
    ): Promise<ApiResponse<T, fetchTypes.fetchUpload>> {
        return await this.invoke<T, T2, fetchTypes.fetchUpload>(fetchTypes.fetchUpload, {
            route: `${route}`,
            body,
            method: HTTPMethod.POST,
            params,
        });
    }

    async delete<T = Read, T2 = Write>(route = "", params?: ParamBag): Promise<ApiResponse<T>> {
        return await this.invoke<never, T2>(fetchTypes.fetch, {
            route,
            method: HTTPMethod.DELETE,
            params,
        });
    }

    async invoke<T = Read, T2 = Write, FetchType extends fetchTypes = fetchTypes.fetch>(
        fetchMethod: fetchTypes,
        options: fetchOptions<T2>
    ): Promise<ApiResponse<T, FetchType>> {
        const response = await this.fetcher[fetchMethod](options).catch((err) => {
            throw err;
        });

        if (response.type === "opaqueredirect") {
            return { response, result: null } as ApiResponse<T, FetchType>;
        }

        if (fetchMethod === fetchTypes.fetchString) {
            const text = await response.text();
            return { response, result: text } as ApiResponse<T, FetchType>;
        }

        if (response.status === 204) {
            // no body
            return { response, result: null };
        }

        const body = await response.json();
        if (!response.ok) {
            switch (response.status) {
                case 400:
                    // body contains information about violated fields
                    throw new ConstraintViolationError(response.statusText, body);
                case 401:
                    // body contains code and message on 401 error
                    throw new UnauthorizedError(body.message);
                case 403:
                    // body contains code and message on 403 error
                    throw new ForbiddenError(body.message);
                case 404:
                    // body contains code and message on 404 error
                    throw new NotFoundError(response.statusText, body);
                default:
                    throw new Error(response.statusText);
            }
        }

        return {
            response,
            result: body,
        } as ApiResponse<T, FetchType>;
    }
}
