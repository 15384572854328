import { ApiResponse } from "../api/ApiService";
import { BuildingApi } from "../api/BuildingApi";
import { TransferKeyApi } from "../api/TransferKeyApi";
import { ITransferKeyBase, ITransferKeyCreate, ITransferKeyRead, transferKeyRepo } from "../entities/TransferKey";
import { session } from "../session/Session";
import { getRepoIndexFromString } from "../stores/RepoStore";
import { DataService } from "./abstract/DataService";
class TransferKeyService extends DataService<"redeem" | "delete", ITransferKeyRead, ITransferKeyCreate> {
    repo = transferKeyRepo;
    apiService = new TransferKeyApi();
    buildingApi = new BuildingApi();

    constructor() {
        super({ redeem: false, delete: false });
    }

    async getTransferKeys(): Promise<ApiResponse<ITransferKeyRead[]>> {
        return this.resolveAsAction({
            promise: () => this.apiService.get<ITransferKeyRead[]>(``),
            waitingForKey: "fetch",
            action: (result) => {
                if (result.result !== null) {
                    this.repo.list = result.result.map((key) => getRepoIndexFromString(key.transferKey, key));
                }

                return result;
            },
        });
    }

    async redeem(id: string): Promise<ApiResponse<ITransferKeyRead>> {
        return this.resolveAsAction({
            promise: () => this.apiService.get<ITransferKeyRead>(`/${id}/redeem`),
            waitingForKey: "redeem",
            action: async (result) => {
                if (result.result !== null) {
                    this.mergeList(getRepoIndexFromString(result.result.transferKey, result.result));
                }

                if (result.response !== null && result.response.ok && result.result !== null) {
                    session.addNotification({
                        title: "Objekt übernommen",
                        description: `Das Objekt ${result.result.buildingName} wurde erfolgreich übernommen.`,
                        key: new Date().getTime().toString(),
                        type: "success",
                    });
                }
                return result;
            },
        });
    }

    async deleteTransferKeys(transferKey: string | undefined): Promise<ApiResponse<ITransferKeyRead>> {
        return this.resolveAsAction({
            promise: () => this.apiService.delete<ITransferKeyRead>(`/${transferKey}`),
            waitingForKey: "delete",
            action: (response) => {
                if (response.response?.ok !== undefined) {
                    this.repo.list = this.list.filter((item) => {
                        this.repo.total--;
                        return item.transferKey !== transferKey;
                    });
                }
                return response;
            },
        });
    }

    async create(body: ITransferKeyCreate): Promise<ApiResponse<ITransferKeyBase>> {
        return this.resolveAsAction({
            promise: () => this.apiService.post<ITransferKeyBase>("", body),
            waitingForKey: "create",
            action: (result) => {
                if (result.result !== null) {
                    this.prependList(getRepoIndexFromString(result.result.transferKey, result.result));
                }

                return result;
            },
        });
    }
}
export const transferKeyService = new TransferKeyService();
