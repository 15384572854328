import { LockBookHeader, LockBookHeaderProps } from "@abs-safety/lock-book-header";
import { Button } from "@abs-safety/lock-book-web-ui";
import { observer } from "mobx-react";
import React, { FunctionComponent } from "react";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components";
import { adminStore } from "../pages/Admin/AdminStore";
import { dataLayerService } from "../services/DataLayerService";
import { session } from "../session/Session";
import { layout } from "../utils/layout";
import { getLocalStorageValue } from "../utils/localStorage";

const Header: FunctionComponent = () => {
    const history = useHistory();

    const environment = (process.env.REACT_APP_ENVIRONMENT ?? "prod") as LockBookHeaderProps["environment"];

    // TODO onAdminRelease: only navigate to /admin
    const onAdminAreaClicked = () => {
        if (getLocalStorageValue("adminPageActive") === true) {
            adminStore.saveBackToDocuPath();
            history.push(`/admin`);
            return;
        }

        const url = process.env.REACT_APP_ADMIN_URL;
        if (url === undefined) {
            console.error("URL not defined in process.env");
            return;
        }

        dataLayerService.emitOutbound({ label: url, payload: "adminArea" }, () => window.open(url, "_blank"));
    };

    const user: LockBookHeaderProps["user"] =
        session.currentUser !== undefined
            ? {
                  firstname: session.currentUser.firstName,
                  lastname: session.currentUser.lastName,
                  company: session.currentUser.company.name,
                  username: session.currentUser.username,
                  picture: session.currentUser.imageUrl,
              }
            : undefined;

    const applicationSettings: LockBookHeaderProps["applicationSettings"] =
        session.currentUser !== undefined && session.hasCompanyAdminRights && !adminStore.adminPageIsOpened
            ? {
                  menuTitle: "Administration",
                  items: [{ text: "Adminbereich", onClick: onAdminAreaClicked }],
              }
            : undefined;

    return (
        <>
            <S.FixedPosition>
                <LockBookHeader
                    environment={environment}
                    application="docu"
                    user={user}
                    customNodesRight={
                        adminStore.adminPageIsOpened && (
                            <Button size="small" variant="outline" color="white">
                                <Link to={adminStore.backToDocuPath ?? "/"}>zurück zu Docu</Link>
                            </Button>
                        )
                    }
                    applicationSettings={applicationSettings}
                    onLogoClick={() => history.push("/")}
                />
            </S.FixedPosition>
            <S.PushContent />
        </>
    );
};

export default observer(Header);

//#region styles
const S = {
    FixedPosition: styled.div`
        position: fixed;
        z-index: 11;
        width: 100%;
    `,
    PushContent: styled.div`
        height: ${layout.header.height}px;
    `,
};
//#endregion styles
