import {
    Button,
    designTheme,
    IconCheck,
    ModalContent,
    ModalFooter,
    ModalHeader,
    unitize,
} from "@abs-safety/lock-book-web-ui";
import { media } from "@abs-safety/lock-book-web-ui/dist/esm/shared/breakpoints";
import { observer } from "mobx-react";
import React, { FunctionComponent } from "react";
import styled from "styled-components";
import { DocumentationType } from "../../../../entities/Documentation";
import { dataLayerService } from "../../../../services/DataLayerService";

interface Step2CompleteDocumentationMaintenanceProps {
    onClose: () => void;
    pdfUrl?: string;
    docuType: DocumentationType;
}

export const Step2CompleteDocumentationMaintenance: FunctionComponent<Step2CompleteDocumentationMaintenanceProps> = (
    props: Step2CompleteDocumentationMaintenanceProps
) => {
    return (
        <>
            <ModalHeader title="Dokumentation erfolgreich abgeschlossen" />
            <ModalContent>
                <S.CompletedIcon>
                    <IconCheck width={130} height={130} />
                </S.CompletedIcon>
            </ModalContent>

            <ModalFooter>
                <Button>
                    <a
                        href={props.pdfUrl}
                        className="uf-openPdf"
                        target="_blank"
                        rel="noreferrer"
                        onClick={() =>
                            dataLayerService.emitOpenPdf({
                                category: props.docuType,
                                docuType: props.docuType,
                                documentType: "documentation",
                            })
                        }
                    >
                        PDF öffnen
                    </a>
                </Button>
                <Button variant="text" color="black">
                    <button className={"uf-closePdf"} onClick={props.onClose}>
                        Schließen
                    </button>
                </Button>
            </ModalFooter>
        </>
    );
};

export default observer(Step2CompleteDocumentationMaintenance);

//#region styles
const S = {
    CompletedIcon: styled.div`
        border-radius: ${unitize(138)};
        border: ${unitize(2)} solid ${designTheme.color.primary};

        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        margin-top: ${unitize(30)};
        width: ${unitize(200)};
        height: ${unitize(200)};
        ${media("sm")} {
            margin-top: ${unitize(70)};
            width: ${unitize(274)};
            height: ${unitize(274)};
        }
    `,
};
//#endregion styles
