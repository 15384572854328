import { Button, Modal, ModalContent, ModalHeader, RadioButtonGroup, unitize } from "@abs-safety/lock-book-web-ui";
import InputField from "@abs-safety/lock-book-web-ui/dist/esm/components/form/Input/elements/InputField";
import { observer } from "mobx-react";
import React, { FunctionComponent, useCallback, useState } from "react";
import styled from "styled-components";
import { userPropsService } from "../../../services/UserPropsService";
import { session } from "../../../session/Session";
import { gtmTrackPreDocuSurvey } from "../../../utils/gtmEventCollection";

type RadiobuttonValue = "pen_and_paper" | "digital_form" | "different_softare_solution" | "photos_only" | "other";
interface ModalPreDocuSurveyProps {
    onClose: () => void;
}

export const ModalPreDocuSurvey: FunctionComponent<ModalPreDocuSurveyProps> = (props: ModalPreDocuSurveyProps) => {
    const [radioValue, setRadioValue] = useState<RadiobuttonValue>();
    const [inputValue, setInputValue] = useState<string>("");

    const onSelectionChange = useCallback((value: RadiobuttonValue) => {
        setRadioValue(value);
    }, []);

    const onSubmitClick = () => {
        if (radioValue === undefined) {
            return;
        }
        if (radioValue === "other") {
            if (inputValue === "") {
                return;
            }
            submit();
            return;
        }
        submit();
    };

    const submit = () => {
        const valToSubmit = radioValue === "other" ? inputValue : radioValue;
        gtmTrackPreDocuSurvey(valToSubmit ?? "something not tracked right");
        userPropsService.updateUserProps({ survey_documented_before: valToSubmit });
        props.onClose();
        session.addNotification({
            title: `Danke!`,
            description: `Wir freuen uns über Dein Feedback!`,
            key: new Date().getTime().toString(),
            type: "success",
        });
    };

    return (
        <S.ModalWrapper className={"uf-modalPreDocuSurvey"}>
            <Modal isOpen size="sm" style={{ paddingTop: `${unitize(50)}` }}>
                <ModalHeader title="Wie hast Du dokumentiert bevor Du Lock Book Docu entdeckt hast?" />
                <ModalContent>
                    <S.RadioButtons>
                        <RadioButtonGroup
                            options={[
                                {
                                    value: "pen_and_paper",
                                    label: "Stift und Papier",
                                },
                                {
                                    value: "digital_form",
                                    label: "Digitales Formular zur Dokumentation",
                                },
                                {
                                    value: "different_softare_solution",
                                    label: "Software-Lösung anderer Anbieter/Hersteller",
                                },
                                {
                                    value: "photos_only",
                                    label: "Reine Fotodokumentation",
                                },
                                {
                                    value: "other",
                                    label: "Sonstiges",
                                },
                            ]}
                            name="PreDocuDocumentingTool1"
                            value={radioValue}
                            onChange={(e) => onSelectionChange(e.target.value as RadiobuttonValue)}
                            regularWeight={true}
                        />
                        {radioValue === "other" && (
                            <InputField
                                type="text"
                                placeholder="bitte eingeben"
                                value={inputValue}
                                onChange={(e) => setInputValue(e.target.value)}
                            />
                        )}
                        <S.SingleRadioButton>
                            <RadioButtonGroup
                                options={[
                                    {
                                        value: "first_documentation",
                                        label: "Ich dokumentiere zum ersten Mal",
                                    },
                                ]}
                                name="PreDocuDocumentingTool2"
                                value={radioValue}
                                onChange={(e) => onSelectionChange(e.target.value as RadiobuttonValue)}
                                regularWeight={true}
                            />
                        </S.SingleRadioButton>
                    </S.RadioButtons>
                </ModalContent>

                <S.ButtonWrapper>
                    <Button disabled={radioValue === undefined || (radioValue === "other" && inputValue === "")}>
                        <button className={"uf-preDocuDocumentingTool"} type="submit" onClick={onSubmitClick}>
                            Antworten
                        </button>
                    </Button>
                </S.ButtonWrapper>
            </Modal>
        </S.ModalWrapper>
    );
};

export default observer(ModalPreDocuSurvey);

//#region styles
const S = {
    ModalWrapper: styled.div`
        position: absolute;
        width: 100vw;
        height: 100vw;
        max-width: 100%;
        max-height: 100%;
        top: 0;
        left: 0;
    `,
    ButtonWrapper: styled.div`
        display: flex;
        justify-content: flex-end;
        flex-direction: row;
        gap: ${unitize(30)};
    `,
    RadioButtons: styled.div`
        text-align: left;
        margin: ${unitize(0)} ${unitize(24)};
        padding-bottom: ${unitize(10)};
    `,
    Checkbox: styled.div`
        text-align: left;
    `,
    ButtonClose: styled.div`
        position: absolute;
        right: ${unitize(30)};
        top: ${unitize(30)};
    `,
    SingleRadioButton: styled.div`
        margin-top: 50px;
    `,
};
//#endregion styles
