import { isNotEmptyString } from "./isNotEmptyString";

/**
 * LocalStorage keys & values.
 * Use really specific names, as renaming later could cause wrong behavior for users
 * which have already saved values.
 */
interface LocalStorage {
    neverShowPopupWarnDeleteArticleInDocu?: boolean;
    neverShowPopupWarnMultipleSelectionInDocument?: boolean;
    neverShowPopupAfterLockMultipleInDocument?: boolean;
    /** ids of documentations */
    neverShowModalNextMaintenanceRefusalForDocu?: number[];
    mobile_active?: boolean;
    adminPageActive?: boolean;
}

export type LocalStorageKey = keyof LocalStorage;

export function getLocalStorageValue<T extends LocalStorageKey>(key: T): LocalStorage[T] {
    const value = localStorage.getItem(key);
    try {
        return isNotEmptyString(value) ? JSON.parse(value) : undefined;
    } catch {
        return undefined;
    }
}

export function setLocalStorageValue<T extends LocalStorageKey>(key: T, value: LocalStorage[T]): void {
    localStorage.setItem(key, JSON.stringify(value));
}

export function removeLocalStorageValue(key: LocalStorageKey): void {
    localStorage.removeItem(key);
}
