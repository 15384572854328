import { RepoStore } from "../stores/RepoStore";
import { IArticleRead } from "./Article";

class ArticleAttachmentRepo extends RepoStore<IArticleAttachmentRead> {}

export const articleAttachmentRepo = new ArticleAttachmentRepo();

// related services
type User = unknown;

export interface IArticleAttachmentBase {
    id: number;
    imageUrl?: string | null;
    imageFolderName?: string | null;
    imageFilename?: string | null;
    imageSize?: string | null;
    updatedAt?: Date | null;
    createdAt?: Date | null;

    // many to one
    user?: User;
    userId?: number;
    article?: IArticleRead;
    articleId?: number;
}

export type IArticleAttachmentRead = Pick<
    IArticleAttachmentBase,
    | "id"
    | "imageUrl"
    | "imageSize"
    | "imageFilename"
    | "imageFolderName"
    | "userId"
    | "articleId"
    | "createdAt"
    | "updatedAt"
>;

export type IArticleAttachmentWrite = {
    attachment: Blob;
};
