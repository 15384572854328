export const nextMaintenanceTexts = {
    title: "Jetzt schon an die Wartung denken!",
    description:
        "Übernimm die Wartung selbst und erhalte per E‑Mail einen Kalendereintrag. Alternativ kannst Du die Wartung an ABS übergeben und ein kostenloses Angebot anfragen.",
    temporaryDescription:
        "Erhalte 150 € Cashback oder 250 € ABS Warengutschein, wenn Du die Wartung an ABS weiterleitest!*",
    temporaryDisclaimer: "*Gilt nur für Wartungen in Deutschland. Vergütung nur bei Vertragsabschluss",
    btnCalendar: "Kalendereintrag",
    btnOffer: "Wartungsangebot",
};
