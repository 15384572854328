import {
    Button,
    ButtonClose,
    designTheme,
    media,
    Modal,
    ModalContent,
    ModalHeader,
    unitize,
} from "@abs-safety/lock-book-web-ui";
import { observer } from "mobx-react";
import React, { FunctionComponent } from "react";
import styled from "styled-components";
import { config } from "../../../../src/config/config";
import { nextMaintenanceTexts } from "./NextMaintenanceTexts";

interface ModalNextMaintenanceProps {
    onClose: () => void;
    onCalendarButtonClick: () => void;
    onMaintenanceOfferClick: () => void;
}

const quickSwitchMaintenanceVoucher = config.QUICK_SWITCHES.maintenance_voucher;

export const ModalNextMaintenance: FunctionComponent<ModalNextMaintenanceProps> = (
    props: ModalNextMaintenanceProps
) => {
    return (
        <S.ModalWrapper className={"uf-modalWrapper"}>
            <Modal isOpen size="sm" style={{ paddingTop: `${unitize(50)}` }} onCloseClick={props.onClose}>
                <S.ButtonClose>
                    <ButtonClose onClick={props.onClose} />
                </S.ButtonClose>
                <S.ModalHeader>
                    <ModalHeader title={nextMaintenanceTexts.title} />
                </S.ModalHeader>
                <ModalContent>
                    <S.ModalContentWrapper>
                        <S.ModalTextWrapper>
                            <span>{nextMaintenanceTexts.description}</span>
                        </S.ModalTextWrapper>
                        {quickSwitchMaintenanceVoucher === true && (
                            <>
                                <S.ModalTextWrapperTemporaryMoney>
                                    <span>{nextMaintenanceTexts.temporaryDescription}</span>
                                </S.ModalTextWrapperTemporaryMoney>
                                <S.ModalTextWrapperTemporaryDisclaimer>
                                    <span>{nextMaintenanceTexts.temporaryDisclaimer}</span>
                                </S.ModalTextWrapperTemporaryDisclaimer>
                            </>
                        )}
                    </S.ModalContentWrapper>
                    <S.ButtonWrapper>
                        <Button>
                            <button
                                className="uf-maintenanceCalendarFileButton_modal"
                                onClick={props.onCalendarButtonClick}
                            >
                                {nextMaintenanceTexts.btnCalendar}
                            </button>
                        </Button>
                        <Button>
                            <button
                                className="uf-maintenanceOfferButton_modal"
                                onClick={() => {
                                    props.onMaintenanceOfferClick();
                                }}
                            >
                                {nextMaintenanceTexts.btnOffer}
                            </button>
                        </Button>
                    </S.ButtonWrapper>
                </ModalContent>
            </Modal>
        </S.ModalWrapper>
    );
};

export default observer(ModalNextMaintenance);

//#region styles
const S = {
    ModalWrapper: styled.div`
        position: absolute;
        width: 100vw;
        height: 100vw;
        max-width: 100%;
        max-height: 100%;
        top: 0;
        left: 0;
    `,
    ButtonWrapper: styled.div`
        display: flex;
        justify-content: center;
        flex-direction: column;
        gap: ${unitize(10)};
        margin-top: ${unitize(37)};
        ${media("md")} {
            gap: ${unitize(30)};
            flex-direction: row;
        }
    `,
    ModalContentWrapper: styled.div`
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: #0f8c77;
        font-size: ${unitize(19)};
    `,
    ModalHeader: styled.div`
        font-weight: bold;
    `,
    ModalTextWrapper: styled.div`
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: ${unitize(10)};
        font-size: ${unitize(19)};
        width: 90%;
        span {
            color: black;
        }
    `,
    ModalTextWrapperTemporaryMoney: styled.div`
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: ${unitize(22)};
        width: 100%;
        font-size: ${unitize(22)};
        font-weight: bold;
        span {
            color: ${designTheme.color.primary};
        }
    `,
    ModalTextWrapperTemporaryDisclaimer: styled.div`
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: ${unitize(14)};
        margin-top: ${unitize(6)};
        width: 65%;
        span {
            color: ${designTheme.color.black};
        }
    `,
    ButtonClose: styled.div`
        position: absolute;
        right: ${unitize(30)};
        top: ${unitize(30)};
    `,
};
//#endregion styles
