import { designTheme, IconProduct, ProgressCircle, unitize } from "@abs-safety/lock-book-web-ui";
import { observer } from "mobx-react";
import React, { FunctionComponent } from "react";
import styled, { css } from "styled-components";
import { IArticleOverviewItemDocu } from "../../../../api/DocumentationApi";
import PresignedImage from "../../../../components/PresignedImage";
import { getController } from "../../../../stores/controller/ControllerFactory";
import { DocumentationController } from "../../DocumentationController";

interface ArticleItemProps {
    article: IArticleOverviewItemDocu;
    isSelected: boolean;
    onClick: () => void;
    firstItem?: boolean;
}

const ArticleItem: FunctionComponent<ArticleItemProps> = (props: ArticleItemProps) => {
    const { controller } = getController(DocumentationController);
    const documentationItems = props.article.documentationItems.length;
    const documentedDocumentationItems = props.article.documentationItems.filter(
        (item) => item.isSuccessful && item.questionsAnswered
    ).length;
    const lockedDocumentationItems = props.article.documentationItems.filter(
        (item) => !item.isSuccessful && item.questionsAnswered
    ).length;

    return (
        <S.Component isSelected={props.isSelected} onClick={props.onClick}>
            <S.ImageWrapper>
                {props.article.imageUrl ? (
                    <PresignedImage
                        reloadUrl={() => controller.loadArticles(true)}
                        url={props.article.imageUrl}
                        style={{ width: 92, height: 92 }}
                        waitingForValue={controller.waitingFor.reloadUrlArticles}
                        disableRefresh={!props.firstItem}
                    />
                ) : (
                    <S.NoImage>
                        <IconProduct color={designTheme.color.darkgrey} />
                    </S.NoImage>
                )}
            </S.ImageWrapper>
            <S.ArticleInfos>
                <S.ArticleName>{props.article.name}</S.ArticleName>
                <p>{props.article.number}</p>
            </S.ArticleInfos>
            <S.ProgressCircleWrapper>
                <ProgressCircle
                    totalCount={documentationItems}
                    documentedCount={documentedDocumentationItems}
                    lockedCount={lockedDocumentationItems}
                />
            </S.ProgressCircleWrapper>
        </S.Component>
    );
};

export default observer(ArticleItem);

//#region styles
const S = {
    Component: styled.div<{ isSelected: boolean }>`
        height: ${unitize(132)};
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        margin-left: -${unitize(30)};
        margin-right: -${unitize(30)};
        padding-left: ${unitize(30)};
        .accordion & {
            margin-left: 0;
            margin-right: 0;
            padding-left: ${unitize(10)};
        }
        ${(props) =>
            props.isSelected &&
            css`
                background-color: ${designTheme.color.lightgrey};
                cursor: default;
            `}
    `,
    ImageWrapper: styled.div`
        width: ${unitize(92)};
        height: ${unitize(92)};
        flex-shrink: 0;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
    `,
    Image: styled.img`
        max-width: 100%;
        max-height: 100%;
    `,
    NoImage: styled.div`
        width: 100%;
        height: 100%;
        svg {
            width: 100%;
            height: 100%;
        }
        /* background: ${designTheme.color.lightestgrey}; */
    `,
    ArticleInfos: styled.div`
        margin-left: ${unitize(10)};
        margin-right: ${unitize(10)};
        width: 100%;
        overflow: hidden;
    `,
    ArticleName: styled.h4`
        text-overflow: ellipsis;
        overflow: hidden;
    `,
    ProgressCircleWrapper: styled.div`
        margin-right: ${unitize(10)};
    `,
};
//#endregion styles
