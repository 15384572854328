import { RepoStore } from "../stores/RepoStore";
import { IEntityBase } from "./Base";

/** * based on lock-book-docu-api: api/src/Entity/TransferKey.php */
class TransferKeyRepo extends RepoStore<ITransferKeyRead> {}
export const transferKeyRepo = new TransferKeyRepo();
export interface ITransferKeyBase extends IEntityBase {
    transferKey: string;
    isActive: boolean;
    createdAt: Date;
    usedAt: Date | null;
    company: string;
    newCompany: string | null;
    building: string;
    buildingName: string;
}
export type ITransferKeyRead = ITransferKeyBase;
export type ITransferKeyCreate = Pick<ITransferKeyBase, "building">;
