import {
    Button,
    designTheme,
    Dropdown,
    IconDotsMenu,
    IconTrash,
    IconUpload,
    unitize,
} from "@abs-safety/lock-book-web-ui";
import { observer } from "mobx-react";
import React, { FunctionComponent, useRef } from "react";
import styled from "styled-components";
import FieldLoading from "../../../components/FieldLoading";
import PresignedImage from "../../../components/PresignedImage";
import { config } from "../../../config/config";
import { buildingService } from "../../../services/BuildingService";
import { session } from "../../../session/Session";
import { getController } from "../../../stores/controller/ControllerFactory";
import { isDefined } from "../../../utils/isDefined";
import { validateFileSize } from "../../../utils/validators/file";
import { BuildingController } from "../BuildingController";
import { EditModalProps } from "./Edit";

const EditTitleImage: FunctionComponent<EditModalProps> = (props: EditModalProps) => {
    const { controller } = getController(BuildingController);
    const buildingId = props.selectedBuildingId;
    const building = buildingService.get(buildingId);

    const hiddenFileInputRef = useRef<HTMLInputElement>(null);

    const onFileInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files === null) {
            return;
        }
        const file = event.target.files[0];
        const validated = validateFileSize(file);
        props.onError !== undefined && props.onError(validated === true ? undefined : validated);
        if (validated instanceof Error) {
            session.addNotification({
                title: validated.name,
                description: validated.message,
                key: new Date().getTime().toString(),
                type: "error",
            });
            return false;
        }

        buildingService.uploadBuildingImage(buildingId, file);
    };

    // Component while loading
    if (building === undefined) {
        return (
            <S.EditTitleImage>
                <FieldLoading height={unitize(160)} text="Objekt wird geladen..." />
            </S.EditTitleImage>
        );
    }
    // Component if building has imageUrl
    if (isDefined(building.imageUrl) && building.imageUrl !== "") {
        return (
            <S.EditTitleImage>
                <input
                    style={{ display: "none" }}
                    type="file"
                    accept={config.UPLOAD_ACCEPT_BUILDING_ATTACHMENT}
                    name="attachment"
                    onChange={onFileInputChange}
                    ref={hiddenFileInputRef}
                />
                {controller.waitingFor.uploadBuildingImage !== false ? (
                    <FieldLoading height={unitize(400)} text="Titelbild wird hochgeladen..." />
                ) : controller.waitingFor.deleteBuildingImage === true ? (
                    <FieldLoading height={unitize(400)} text="Titelbild wird gelöscht..." />
                ) : (
                    <S.ImageWrapper>
                        <PresignedImage
                            url={building.imageUrl}
                            reloadUrl={() => controller.loadBuilding(buildingId)}
                            waitingForValue={controller.waitingFor.loadBuilding}
                            style={{ width: 690, height: 400 }}
                        />
                        <S.ImageMenu>
                            <Dropdown
                                toggleNode={
                                    <S.MenuIcon>
                                        <IconDotsMenu />
                                    </S.MenuIcon>
                                }
                                dropdownAlign="right"
                                items={[
                                    {
                                        icon: <IconUpload width={16} />,
                                        text: "neues Bild hochladen",
                                        onClick: () => hiddenFileInputRef.current?.click(),
                                    },
                                    {
                                        icon: <IconTrash />,
                                        text: "Löschen",
                                        textColor: designTheme.color.error,
                                        onClick: () => buildingService.removeBuildingImage(props.selectedBuildingId),
                                    },
                                ]}
                                width={270}
                            />
                        </S.ImageMenu>
                    </S.ImageWrapper>
                )}

                <S.InfoText>
                    Das Titelbild dient der Übersicht und der optischen Aufwertung Deiner Dokumentation. Es hat keine
                    technische Relevanz.
                </S.InfoText>
            </S.EditTitleImage>
        );
    }
    // Component if building has not yet an imageUrl
    else {
        return (
            <S.EditTitleImage>
                <input
                    style={{ display: "none" }}
                    type="file"
                    accept={config.UPLOAD_ACCEPT_BUILDING_ATTACHMENT}
                    name="attachment"
                    onChange={onFileInputChange}
                    ref={hiddenFileInputRef}
                />
                <S.ButtonWrapper>
                    <Button disabled={controller.waitingFor.uploadBuildingImage === true}>
                        <button onClick={() => hiddenFileInputRef.current?.click()}>
                            Titelbild hochladen <IconUpload color={designTheme.color.white} width={16} />
                        </button>
                    </Button>
                </S.ButtonWrapper>
                {controller.waitingFor.uploadBuildingImage === true && (
                    <FieldLoading height={unitize(400)} text="Titelbild wird hochgeladen..." />
                )}
                <S.InfoText>
                    Das Titelbild dient der Übersicht und der optischen Aufwertung Deiner Dokumentation. Es hat keine
                    technische Relevanz.
                </S.InfoText>
            </S.EditTitleImage>
        );
    }
};

export default observer(EditTitleImage);

//#region styles
const S = {
    EditTitleImage: styled.div``,
    ImageWrapper: styled.div`
        position: relative;

        width: 100%;
        max-width: ${unitize(690)};
        height: auto;
        max-height: ${unitize(400)};
        border-radius: ${designTheme.borderRadius};
        background-color: ${designTheme.color.lightestgrey};
        overflow: hidden;
        border: 1px solid ${designTheme.color.lightgrey};
    `,
    ButtonWrapper: styled.div`
        margin-bottom: ${unitize(20)};
    `,
    InfoText: styled.p`
        margin: ${unitize(20)} 0;
        font-size: ${designTheme.typography.size.p}rem;
    `,
    MenuIcon: styled.div`
        display: flex;
        align-items: center;
        justify-content: center;
        background: white;
        width: ${unitize(26)};
        height: ${unitize(26)};
        border-radius: ${unitize(13)};
    `,
    ImageMenu: styled.div`
        position: absolute;
        top: ${unitize(5)};
        right: ${unitize(5)};
    `,
};
//#endregion styles
