import {
    Button,
    designTheme,
    FieldSignature,
    ModalContent,
    ModalFooter,
    ModalHeader,
    Signature,
    unitize,
} from "@abs-safety/lock-book-web-ui";
import { autorun } from "mobx";
import { observer } from "mobx-react";
import React, { FunctionComponent, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import SignatureCanvas from "react-signature-canvas";
import styled from "styled-components";
import { dataLayerService } from "../../../../services/DataLayerService";
import { layoutService } from "../../../../services/LayoutService";
import { session } from "../../../../session/Session";
import { getController } from "../../../../stores/controller/ControllerFactory";
import { DocumentationController } from "../../DocumentationController";

interface CompleteDocumentationProps {
    onClose: () => void;
    openNextStep: (signature?: Signature) => void;
}

const Step1CompleteDocumentation: FunctionComponent<CompleteDocumentationProps> = (
    props: CompleteDocumentationProps
) => {
    const { controller } = getController(DocumentationController);
    const params: {
        documentationId: string;
    } = useParams();

    const currentURL = window.location.href;

    const [signature, setSignature] = useState<Signature>();
    const documentationId = parseInt(params.documentationId);
    const maxWidthSignature = 690;
    const widthSignature = layoutService.windowWidth * 0.9 - 2 * modalPadding;

    useEffect(() => {
        // show signature:
        // ABS Admin can complete with signature defined in docu before (might be different person).
        // Other users see only their own signature.
        return autorun(() => {
            const isABSAdmin = session.hasAbsAdminRights;
            const userSignature = session.currentUser?.signature;
            const documentationSignature = controller.documentation?.signature;

            if (isABSAdmin && documentationSignature !== null) {
                setSignature(documentationSignature);
                return;
            }

            setSignature(userSignature ?? undefined);
        });
    }, []);

    useEffect(() => {
        if (controller.documentation === undefined) {
            return;
        }
        controller.currentId = documentationId;
    }, [documentationId]);

    const onDeleteSignature = () => {
        setSignature([]);
    };

    const onNextStepClick = () => {
        props.openNextStep(signature);
    };

    const onSignatureEnd = (signatureCanvas: SignatureCanvas) => {
        const base64String = signatureCanvas.toDataURL("image/png");
        const base64Array = base64String.split(",");
        setSignature(base64Array);
    };

    const toHubAccountSignature = () => {
        const rootUrl = process.env.REACT_APP_HUB_URL;
        const url = `${rootUrl}/account/signature?src=${currentURL}`;

        dataLayerService.emitOutbound({ label: url, payload: "toHubAccountSignature" }, () =>
            window.open(url, "_self")
        );
    };

    const signatureEditItem = [
        {
            onClick: toHubAccountSignature,
            text: "Standardunterschrift bearbeiten",
        },
    ];

    return (
        <>
            <ModalHeader title="Dokumentation abschließen und PDF erstellen?" />
            <ModalContent>
                <S.ModalContentParagraph>
                    Hinweis: Nach Erstellung der PDF ist eine Bearbeitung der Dokumentation nur mit erweiterten
                    Admin-Funktionen möglich.
                </S.ModalContentParagraph>
                <S.SignatureHeading>Unterschrift Monteur</S.SignatureHeading>
                <S.Signature className={"uf-signature"}>
                    <FieldSignature
                        width={layoutService.windowWidth >= 862 ? maxWidthSignature : widthSignature}
                        signature={signature}
                        additionalDropdownItems={session.hasAbsAdminRights === false ? signatureEditItem : undefined}
                        disabled={session.hasAbsAdminRights}
                        onSignatureEnd={onSignatureEnd}
                        onSignatureDelete={onDeleteSignature}
                        mobilePlaceholderVideoUrl="/signature_mobile.mp4"
                        desktopPlaceholderVideoUrl="/signature_desktop.mp4"
                    />
                </S.Signature>
                <p>
                    Der ausführende Monteur bestätigt, dass er alle in dieser Dokumentation aufgeführten
                    Absturzsicherungseinrichtungen sach- und fachgerecht sowie nach Herstellerangaben und
                    Montageanleitung montiert hat.
                </p>
            </ModalContent>

            <ModalFooter>
                <Button disabled={controller.waitingFor.completeDocumentation === true}>
                    <button className={"uf-completeDocument"} onClick={onNextStepClick}>
                        Abschließen
                    </button>
                </Button>
                <Button variant="text" color="black">
                    <button onClick={props.onClose}>Abbrechen</button>
                </Button>
            </ModalFooter>
        </>
    );
};

export default observer(Step1CompleteDocumentation);

//#region styles

const modalPadding = 30;

const S = {
    SignatureHeading: styled.h5`
        margin-top: ${unitize(26)};
    `,
    Signature: styled.div`
        margin-top: ${unitize(10)};
        margin-bottom: ${unitize(20)};
        overflow-x: hidden;
    `,

    ModalContentParagraph: styled.p`
        color: ${designTheme.color.error};
        font-weight: 600;
    `,
};
//#endregion styles
