import { action, computed, makeObservable, observable } from "mobx";
import { ApiResponse } from "../../api/ApiService";
import { IArticleOverviewItem } from "../../api/ArticleApi";
import { IArticleOverviewItemDocu } from "../../api/DocumentationApi";
import { IBuildingRead } from "../../entities/Building";
import { IBuildingAreaRead, IBuildingAreaUpdate } from "../../entities/BuildingArea";
import { IBuildingAttachmentRead, IBuildingAttachmentUpdate } from "../../entities/BuildingAttachment";
import { DocumentationType, IDocumentationBase, IDocumentationRead } from "../../entities/Documentation";
import { IInstalledArticleRead, IInstalledArticleWrite } from "../../entities/InstalledArticle";
import { areaService } from "../../services/AreaService";
import { articleService } from "../../services/ArticleService";
import { attachmentService } from "../../services/AttachmentService";
import { buildingService } from "../../services/BuildingService";
import { dataLayerService } from "../../services/DataLayerService";
import { documentationService } from "../../services/DocumentationService";
import { installedArticleService } from "../../services/InstalledArticleService";
import { session } from "../../session/Session";
import { SuperControllerStore } from "../../stores/controller/SuperControllerStore";

type waitingForKeys =
    | "all"
    | "reloadUrlArticles"
    | "loadArticles"
    | "deleteInstalledArticles"
    | "updateBuildingArea"
    | "reloadUrl"
    | "uploadFile"
    | "deleteFile"
    | "updateFile"
    | "loadFiles"
    | "createInstalledArticles"
    | "loadDocumentations"
    | "createDocumentation"
    | "deleteDocumentation"
    | "updateInstalledArticle";

export class AreaController extends SuperControllerStore<waitingForKeys> {
    static controllerName = "AreaController";
    buildingId = 0;

    get building(): IBuildingRead | undefined {
        return buildingService.get(this.buildingId);
    }

    get buildingArea(): IBuildingAreaRead | undefined {
        return areaService.get(this.currentId);
    }

    get files(): IBuildingAttachmentRead[] {
        return attachmentService.list.filter((attachment) => {
            return (
                attachment.buildingAreaId === this.currentId &&
                (attachment.documentationId ?? false) === false &&
                (attachment.documentationItemId ?? false) === false
            );
        });
    }

    get articles(): IArticleOverviewItem[] {
        return articleService.getCombinedListArea(this.currentId);
    }

    get documentations(): IDocumentationRead[] {
        return documentationService.list
            .filter((item) => item.buildingAreaId === this.currentId)
            .sort((a, b) => b.id - a.id);
    }
    constructor() {
        super(session, {
            createInstalledArticles: false,
            updateFile: false,
            deleteFile: false,
            uploadFile: false,
            loadFiles: false,
            reloadUrl: false,
            deleteInstalledArticles: false,
            loadArticles: false,
            reloadUrlArticles: false,
            all: false,
            updateBuildingArea: false,
            loadDocumentations: false,
            createDocumentation: false,
            deleteDocumentation: false,
            updateInstalledArticle: false,
        });

        makeObservable(this, {
            buildingId: observable,
            files: computed,
            articles: computed,
            documentations: computed,
            uploadFiles: action,
        });
    }

    init(buildingId: number, buildingAreaId: number): void {
        this.currentId = buildingAreaId;
        this.buildingId = buildingId;
        dataLayerService.lastAreaId = buildingAreaId;
        dataLayerService.emitHistory(location, dataLayerService.dataLayer, "area");

        this.usePageData(
            () => areaService.getOrFetch(buildingAreaId),
            () => buildingService.getOrFetch(buildingId),
            () => documentationService.fetchByArea(buildingAreaId)
        );
    }

    updateBuildingArea(body: IBuildingAreaUpdate): void {
        if (this.currentId === 0) {
            throw Error("Cannot update area, before buildingAreaId is stored");
        }

        this.resolveAsAction({
            promise: () => areaService.update(this.currentId, body),
            waitingForKey: ["updateBuildingArea"],
        });
    }

    createDocumentation(
        buildingAreaId: number,
        type: DocumentationType,
        name: IDocumentationBase["name"]
    ): Promise<ApiResponse<IDocumentationRead>> {
        return this.resolveAsAction({
            promise: () => documentationService.createByArea(buildingAreaId, { type, name }),
            waitingForKey: ["createDocumentation"],
        });
    }

    deleteDocumentation(documentation: IDocumentationRead): Promise<ApiResponse<IArticleOverviewItemDocu>> | void {
        if (documentation.documentationItemIds.length > 0) {
            return this.session.addNotification({
                title: "Dokumentation kann nicht gelöscht werden",
                description: "Die Dokumentation enthält noch Produkte",
                key: new Date().getTime().toString(),
                type: "error",
            });
        }
        return this.resolveAsAction({
            promise: () => documentationService.deactivate(documentation.id),
            waitingForKey: ["deleteDocumentation"],
        });
    }

    loadArticles(reloadUrl?: boolean): Promise<ApiResponse<IArticleOverviewItem[]>> {
        if (this.currentId === 0) {
            throw Error("Cannot load articles, before buildingAreaId is stored");
        }
        return this.resolveAsAction({
            promise: () => articleService.fetchArticlesByArea(this.currentId),
            waitingForKey: [reloadUrl === true ? "reloadUrlArticles" : "loadArticles"],
        });
    }

    loadFiles(reloadUrl?: boolean): Promise<ApiResponse<IBuildingAttachmentRead[]>> {
        if (this.currentId === 0) {
            throw Error("Cannot load files, before buildingAreaId is stored");
        }
        return this.resolveAsAction({
            promise: () => attachmentService.fetchByBuildingArea(this.currentId),
            waitingForKey: reloadUrl === true ? "reloadUrl" : "loadFiles",
        });
    }

    deleteInstalledArticles(installedArticleIds: number[]): Promise<ApiResponse<IInstalledArticleRead[]>> {
        if (this.currentId === 0) {
            throw Error("Cannot delete installedArticles, before buildingAreaId is stored");
        }
        return this.resolveAsAction({
            promise: () =>
                installedArticleService.removeArticlesByIdAndAreaId(this.currentId, {
                    installedArticleIds,
                    deletionReason: "Über Web 2.0 gelöscht - ohne Angabe",
                }),
            waitingForKey: "deleteInstalledArticles",
            setWaitingForValueTo: installedArticleIds,
            action: (response) => {
                return response;
            },
        });
    }

    updateInstalledArticle(
        installedArticleId: number,
        data: Partial<IInstalledArticleWrite>
    ): Promise<ApiResponse<IInstalledArticleRead>> {
        return this.resolveAsAction({
            promise: () => installedArticleService.updateInstalledArticle(installedArticleId, data),
            waitingForKey: "updateInstalledArticle",
            setWaitingForValueTo: installedArticleId,
        });
    }

    async uploadFiles(files: File | File[]): Promise<void> {
        if (this.currentId === 0) {
            throw Error("Cannot upload File, before areaId is stored");
        }

        if (!Array.isArray(files)) {
            files = [files];
        }

        for (const file of files) {
            await this.resolveAsAction({
                promise: () => attachmentService.uploadAreaAttachment(this.currentId, { imageFile: file }),
                waitingForKey: ["uploadFile"],
            });
        }
    }

    deleteFile(id: number): void {
        this.resolveAsAction({
            promise: () => attachmentService.delete(id),
            waitingForKey: ["deleteFile"],
            setWaitingForValueTo: id,
        });
    }

    updateFile(id: number, body: IBuildingAttachmentUpdate): void {
        this.resolveAsAction({
            promise: () => attachmentService.update(id, body),
            waitingForKey: ["updateFile"],
            setWaitingForValueTo: id,
        });
    }
}
