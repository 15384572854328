import {
    Button,
    Card,
    ColumnSizes,
    designTheme,
    GridColumns,
    IconEdit,
    IconProduct,
    IconTrash,
    IconUserCircle,
    ProgressCircle,
    unitize,
} from "@abs-safety/lock-book-web-ui";
import { media } from "@abs-safety/lock-book-web-ui/dist/esm/shared/breakpoints";
import { observer } from "mobx-react";
import React, { FunctionComponent, useState } from "react";
import styled from "styled-components";
import { IArticleOverviewItemDocu } from "../../../api/DocumentationApi";
import PresignedImage from "../../../components/PresignedImage";
import { IDocumentationItemRead } from "../../../entities/DocumentationItem";
import { getController } from "../../../stores/controller/ControllerFactory";
import { formatDate } from "../../../utils/formatDate";
import { gtmTrackOpenDocumentationSideModal } from "../../../utils/gtmEventCollection";
import { isDefined } from "../../../utils/isDefined";
import { DocumentationController } from "../DocumentationController";
import PopupDeleteArticle from "./PopupDeleteArticle";

interface CardArticleProps {
    article: IArticleOverviewItemDocu;
    columnSizes: ColumnSizes;
    disabled?: boolean;
    hideDropdown?: boolean;
    firstItem?: boolean;
    onDelete: () => void;
    onDocument: () => void;
}

const getLastDocuItemByDate = (items: IDocumentationItemRead[]) => {
    let lastUpdated: IDocumentationItemRead | undefined;
    let lastUpdatedAtTime = 0;

    for (const documentationItemRead of items.slice()) {
        const currentUpdatedAtTime: number = isDefined(documentationItemRead.updatedAt)
            ? new Date(documentationItemRead.updatedAt).getTime()
            : 0;

        if (isDefined(documentationItemRead.updatedAt) && currentUpdatedAtTime > lastUpdatedAtTime) {
            lastUpdatedAtTime = currentUpdatedAtTime;
            lastUpdated = documentationItemRead;
        }
    }

    return lastUpdated ?? items[items.length - 1];
};

const CardArticle: FunctionComponent<CardArticleProps> = (props: CardArticleProps) => {
    const { controller } = getController(DocumentationController);
    //amount of documentationItems of this article in this documentation
    const documentationItems = props.article.documentationItems.length;
    //amount of documented documentationItems of this article in this documentation
    const documentedDocumentationItems = props.article.documentationItems.filter(
        (item) => item.isSuccessful === true && item.questionsAnswered
    ).length;
    //amount of locked documentationItems of this article in this documentation
    const lockedDocumentationItems = props.article.documentationItems.filter(
        (item) => item.isSuccessful === false && item.questionsAnswered
    ).length;
    const [popupDeleteArticleOpen, setPopupDeleteArticleOpen] = useState(false);

    const latestDocumentationItem = getLastDocuItemByDate(props.article.documentationItems);
    const lastUpdatedBy = latestDocumentationItem.lastUpdatedBy;
    const documentationCompleted = controller.documentation?.isCompleted;

    const onDeleteInPopupClick = () => {
        props.onDelete();
        setPopupDeleteArticleOpen(false);
    };
    const onDeleteInDropDownClick = () => setPopupDeleteArticleOpen(true);

    const fullyDocumentedMixedResults =
        documentedDocumentationItems > 0 &&
        lockedDocumentationItems > 0 &&
        documentedDocumentationItems + lockedDocumentationItems === documentationItems;

    const onCardClick = () => {
        if (documentationCompleted === true) {
            gtmTrackOpenDocumentationSideModal("read_only");
            props.onDocument();
        }
    };

    const onDocumentClick = () => {
        gtmTrackOpenDocumentationSideModal("editable");
        props.onDocument();
    };

    //#region render
    return (
        <S.Component>
            <Card
                className={"uf-articleCard"}
                disabled={props.disabled}
                onClick={onCardClick}
                dropdownItems={
                    props.hideDropdown ?? false
                        ? undefined
                        : [
                              {
                                  icon: <IconEdit color={designTheme.color.black} width={18} height={18} />,
                                  text: "Bearbeiten",
                                  onClick: onDocumentClick,
                              },
                              {
                                  icon: <IconTrash color={designTheme.color.black} width={18} height={18} />,
                                  text: "Alle löschen",
                                  textColor: designTheme.color.error,
                                  preventClosing: true,
                                  onClick: onDeleteInDropDownClick,
                              },
                          ]
                }
            >
                {popupDeleteArticleOpen && (
                    <PopupDeleteArticle
                        onClose={() => setPopupDeleteArticleOpen(false)}
                        onDelete={onDeleteInPopupClick}
                    />
                )}
                <GridColumns
                    columnSizes={props.columnSizes}
                    childrensDisplay="flex"
                    style={{ alignItems: "center", height: unitize(142) }}
                >
                    {/* Column */}
                    <S.Col1>
                        <S.ImageWrapper className="d-xs-none d-sm-flex">
                            {isDefined(props.article.imageUrl) ? (
                                <PresignedImage
                                    url={props.article.imageUrl}
                                    reloadUrl={() => controller.loadArticles(true)}
                                    waitingForValue={controller.waitingFor.reloadUrlArticles}
                                    style={{ width: 122, height: 122 }}
                                    disableRefresh={props.firstItem === true}
                                />
                            ) : (
                                <IconProduct color={designTheme.color.darkgrey} />
                            )}
                        </S.ImageWrapper>
                        <S.TitleWrapper>
                            <h4>{props.article.name}</h4>
                            <p>{props.article.number}</p>
                        </S.TitleWrapper>
                    </S.Col1>

                    {/* Column */}
                    <S.CreatedAt>
                        {isDefined(latestDocumentationItem.createdAt)
                            ? formatDate(latestDocumentationItem.createdAt)
                            : "-"}
                    </S.CreatedAt>

                    {/* Column */}
                    <S.LastUpdated>
                        {isDefined(lastUpdatedBy) && isDefined(lastUpdatedBy.firstName) ? (
                            <>
                                <S.UserIcon>
                                    <IconUserCircle
                                        firstname={lastUpdatedBy.firstName}
                                        lastname={lastUpdatedBy.lastName}
                                        // picture={props.buildingArea.lastModifiedByUser.picture}
                                    />
                                </S.UserIcon>

                                <S.LastUpdatedData>
                                    <p>
                                        {isDefined(latestDocumentationItem.updatedAt)
                                            ? formatDate(latestDocumentationItem.updatedAt)
                                            : "Datum unbekannt"}
                                    </p>
                                    von {lastUpdatedBy.firstName + " " + lastUpdatedBy.lastName}
                                </S.LastUpdatedData>
                            </>
                        ) : (
                            <S.Date>{formatDate(latestDocumentationItem.updatedAt)}</S.Date>
                        )}
                    </S.LastUpdated>

                    {/* Column */}
                    <S.ColButton>
                        <ProgressCircle
                            totalCount={documentationItems}
                            documentedCount={documentedDocumentationItems}
                            lockedCount={lockedDocumentationItems}
                        />
                        {/* check if questions of documentationItems have been answered */}
                        <S.LabelWrapper>
                            {documentedDocumentationItems === documentationItems ? (
                                <S.LabelReleased>
                                    <p style={{ color: designTheme.color.primary }}>
                                        {documentedDocumentationItems} freigegeben
                                    </p>
                                </S.LabelReleased>
                            ) : // check if questions of documentationItems have been locked
                            lockedDocumentationItems > 0 && lockedDocumentationItems === documentationItems ? (
                                <S.LabelBlocked>
                                    <p>{lockedDocumentationItems} gesperrt</p>
                                </S.LabelBlocked>
                            ) : fullyDocumentedMixedResults ? (
                                <S.Label>
                                    <S.LabelReleased>
                                        <p>{documentedDocumentationItems} freigegeben</p>
                                    </S.LabelReleased>
                                    <S.LabelBlocked>
                                        <p>{lockedDocumentationItems} gesperrt</p>
                                    </S.LabelBlocked>
                                </S.Label>
                            ) : (
                                // else show documentation button
                                <Button>
                                    <button className={"uf-document"} onClick={onDocumentClick}>
                                        Dokumentieren
                                    </button>
                                </Button>
                            )}
                        </S.LabelWrapper>
                    </S.ColButton>
                </GridColumns>
            </Card>
        </S.Component>
    );

    //#endregion render
};

export default observer(CardArticle);

//#region styles
const S = {
    Component: styled.div`
        margin-bottom: ${unitize(10)};
    `,
    CardIcon: styled.div`
        position: absolute;
        left: ${unitize(10)};
        top: ${unitize(10)};
    `,
    Col1: styled.div`
        margin-left: ${unitize(20)};
        display: flex;
        align-items: center;
        column-gap: ${unitize(20)};
    `,
    Date: styled.div`
        font-size: ${designTheme.typography.size.pBig}rem;
        display: flex;
        align-items: center;
    `,
    ImageWrapper: styled.div`
        width: ${unitize(122)};
        height: ${unitize(122)};
        align-items: center;
        justify-content: center;
        img {
            max-width: 100%;
            max-height: 100%;
        }
        svg {
            width: 100%;
            height: 100%;
        }
    `,
    TitleWrapper: styled.div`
        p {
            font-size: ${unitize(14)};
        }
    `,
    LastUpdated: styled.div`
        display: flex;
        align-items: center;
        font-size: ${unitize(19)};
    `,
    CreatedAt: styled.div`
        display: flex;
        align-items: center;
        font-size: ${unitize(19)};
    `,
    UserIcon: styled.div`
        margin-right: ${unitize(10)};
    `,
    LastUpdatedData: styled.div`
        display: flex;
        flex-direction: column;
    `,
    ColButton: styled.div`
        padding-right: ${unitize(20)};
        display: flex;
        justify-content: space-between;
        column-gap: ${unitize(10)};
        row-gap: ${unitize(10)};
        align-items: center;
        flex-direction: column;
        ${media("sm")} {
            flex-direction: row;
        }
    `,
    IconCheck: styled.span`
        margin-right: ${unitize(16)};
    `,
    LabelBlocked: styled.div`
        color: ${designTheme.color.error};
        width: fit-content;
        justify-content: center;
    `,
    LabelReleased: styled.div`
        color: ${designTheme.color.primary};
        width: fit-content;
        justify-content: center;
    `,
    Label: styled.div`
        display: flex;
        flex-direction: column;
    `,
    LabelWrapper: styled.div`
        display: flex;
        justify-content: center;
    `,
};
//#endregion styles
