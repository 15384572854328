import { FunctionComponent, useEffect } from "react";
import { dataLayerService } from "../services/DataLayerService";

export type GtmInitProps = { gtmKey: string };

const GtmIntegration: FunctionComponent<GtmInitProps> = (props: GtmInitProps) => {
    useEffect(() => {
        dataLayerService.init(props.gtmKey);
        dataLayerService.trackHistory();
    }, []);
    return null;
};

export default GtmIntegration;
