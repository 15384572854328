import { getLocalStorageValue, setLocalStorageValue } from "./localStorage";

export const neverShowModalNextMaintenanceRefusalForDocu = (docuId: number): void => {
    const tempDocuIds = getLocalStorageValue("neverShowModalNextMaintenanceRefusalForDocu");
    if (Array.isArray(tempDocuIds)) {
        setLocalStorageValue("neverShowModalNextMaintenanceRefusalForDocu", [...tempDocuIds, docuId]);
    } else {
        setLocalStorageValue("neverShowModalNextMaintenanceRefusalForDocu", [docuId]);
    }
};
