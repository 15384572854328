import { ArticleType } from "../entities/Article";
import { DocumentationType } from "../entities/Documentation";
import { AddArticleActions, dataLayerService, ModalPages } from "../services/DataLayerService";

/**
 * actually just the intention to add an article (click on add article button that opens the addArticleModal)
 */
export const gtmAddArticle = (
    category: DocumentationType | undefined,
    label: string,
    payload?: unknown
): Promise<boolean> => {
    const type = category ?? "documentation";
    return dataLayerService.emitClick({
        action: "add_articles",
        category: type,
        label,
        payload,
    });
};

/**
 * actually adding article to documentation
 */
export const gtmAssignArticle = (
    articleType: ArticleType,
    name: string,
    amount: number,
    category?: ModalPages | undefined
): Promise<boolean> => {
    return dataLayerService.emitEvent("event", {
        action: `assign_article_type: ${articleType}` as AddArticleActions,
        label: name.toLowerCase().replace(/[^a-z0-9-öäüß ]/g, ""),
        category: category ?? dataLayerService.currentPage,
        payload: amount,
    });
};

export const gtmAutoSubmit = (label: string, payload?: unknown): (() => Promise<boolean>) => {
    return dataLayerService.prepareSubmit({
        label,
        payload,
    });
};

export const gtmTrackMaintenanceOfferPoll = (label: string): void => {
    dataLayerService.emitEvent("event", {
        category: dataLayerService.currentPage,
        label: label.replace(/[^0-9A-Za-z -ÄÖÜäöüß]/g, "").toLowerCase(),
        payload: label,
        action: "maintenance_offer_poll",
    });
};

export const gtmTrackPreDocuSurvey = (label: string): void => {
    dataLayerService.emitEvent("event", {
        category: dataLayerService.currentPage,
        label: label.replace(/[^0-9A-Za-z -ÄÖÜäöüß]/g, "").toLowerCase(),
        payload: label,
        action: "pre_docu_survey",
    });
};

export const gtmTrackMaintenanceOfferPollCancel = (): void => {
    dataLayerService.emitEvent("event", {
        category: dataLayerService.currentPage,
        label: "cancel",
        action: "maintenance_offer_poll",
    });
};

export const gtmTrackAttachmentDrag = (startPosition?: number | string): void => {
    dataLayerService.emitEvent("event", {
        category: dataLayerService.currentPage,
        label: `${startPosition}`,
        action: "drag_attachment_start",
    });
};

export const gtmTrackAttachmentDrag_Sort = (endPosition?: number | string): void => {
    dataLayerService.emitEvent("event", {
        category: dataLayerService.currentPage,
        label: `${endPosition}`,
        action: "drag_attachment_sort",
    });
};

export const gtmTrackAttachmentDrag_Cancel = (position?: number | string): void => {
    dataLayerService.emitEvent("event", {
        category: dataLayerService.currentPage,
        label: `${position}`,
        action: "drag_attachment_cancel",
    });
};

export const gtmTrackOpenDocumentationSideModal = (state: "read_only" | "editable"): void => {
    dataLayerService.emitEvent("event", {
        category: dataLayerService.currentPage,
        label: `${state}`,
        action: "open_documentation_side_modal",
    });
};
