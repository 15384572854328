import { RepoStore } from "../stores/RepoStore";
import { DocumentType } from "./Document";
import { IDocumentationRead } from "./Documentation";

/**
 * based on lock-book-docu-api: api/src/Entity/DocumentationSharingHash.php
 */

class DocumentationSharingHashRepo extends RepoStore<IDocumentationSharingHashRead> {}

export const documentationSharingHashRepo = new DocumentationSharingHashRepo();
export interface IDocumentationSharingHashBase {
    id: number;
    hash: string;
    createdAt: Date;
    expiresAt: Date;
    type: DocumentType;

    // relations
    documentation: IDocumentationRead;
    documentationId: number;
}

/** All Article properties from php entity file with `sharing:read` annotation. */
export type IDocumentationSharingHashRead = Omit<IDocumentationSharingHashBase, "documentation">;

/** All Article properties from php entity file with `sharing:write` annotation. */
export type IDocumentationSharingHashWrite = Pick<IDocumentationSharingHashBase, "type" | "documentationId">;
