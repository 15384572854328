import { ButtonClose, Modal, Signature, unitize } from "@abs-safety/lock-book-web-ui";
import React, { FunctionComponent, useState } from "react";
import styled from "styled-components";
import { DocumentationType } from "../../../../entities/Documentation";
import { session } from "../../../../session/Session";
import { getController } from "../../../../stores/controller/ControllerFactory";
import { DocumentationController } from "../../DocumentationController";
import Step1CompleteDocumentation from "./Step1CompleteDocumentation";
import { Step2CompleteDocumentationAssembly } from "./Step2CompleteDocumentationAssembly";
import { Step2CompleteDocumentationMaintenance } from "./Step2CompleteDocumentationMaintenance";

interface ModalCompleteDocumentationProps {
    onClose: () => void;
    onCloseInStep2Assembly: () => void;
    onMaintenanceOfferClick: () => void;
    onCalendarButtonClick: () => void;
    docuType: DocumentationType;
    pdfUrl?: string;
}

const ModalCompleteDocumentation: FunctionComponent<ModalCompleteDocumentationProps> = (
    props: ModalCompleteDocumentationProps
) => {
    const { controller } = getController(DocumentationController);
    const [step, setStep] = useState(1);

    const onCompleteDocumentationStep1Click = async (signature?: Signature) => {
        const isAbsAdmin = session.hasAbsAdminRights;

        try {
            if (!isAbsAdmin) {
                await controller.updateSignature(signature);
            }
            await controller.completeDocumentation();
            setStep(2);
        } catch (e) {
            session.addNotification({
                type: "error",
                key: "onCompleteDocumentation" + new Date().getTime(),
                title: "Unerwarteter Fehler",
            });
            console.error(e);
        }
    };

    const onClose = () => {
        if (controller.documentation?.type === "assembly" && step === 2) {
            props.onCloseInStep2Assembly();
        } else {
            props.onClose();
        }
    };

    return (
        <S.ModalWrapper className={"uf-modalWrapper"}>
            <Modal isOpen size="md" onCloseClick={onClose}>
                <S.ButtonClose>
                    <ButtonClose onClick={onClose} />
                </S.ButtonClose>
                {step === 1 && (
                    <Step1CompleteDocumentation
                        onClose={onClose}
                        openNextStep={(signature) => onCompleteDocumentationStep1Click(signature)}
                    />
                )}

                {step === 2 && props.docuType === "assembly" && (
                    <Step2CompleteDocumentationAssembly
                        onMaintenanceOfferClick={props.onMaintenanceOfferClick}
                        onCalendarButtonClick={props.onCalendarButtonClick}
                    />
                )}
                {step === 2 && props.docuType === "maintenance" && (
                    <Step2CompleteDocumentationMaintenance
                        onClose={onClose}
                        pdfUrl={props.pdfUrl}
                        docuType={props.docuType}
                    />
                )}
            </Modal>
        </S.ModalWrapper>
    );
};
export default ModalCompleteDocumentation;

const S = {
    ModalWrapper: styled.div`
        position: absolute;
        width: 100vw;
        height: 100vw;
        max-width: 100%;
        max-height: 100%;
        top: 0;
        left: 0;
    `,
    ButtonClose: styled.div`
        position: absolute;
        right: ${unitize(30)};
        top: ${unitize(30)};
    `,
};
